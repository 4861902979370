<template>
<div class="page">
  <div id="viewer-container"></div>

  <div class="viewer_right_from">
    <div class="viewer_right_caption">--{{ editData.map_line_name ? editData.map_line_name : '管线名称' }}--</div>
    <!--      <div class="viewer_right_item">
            <span class="viewer_right_tit">显示名称：</span>
            <a-checkbox v-model:checked="editData.showname"></a-checkbox>
          </div>-->
    <div class="viewer_right_item">
      <span class="viewer_right_tit">管线类型：</span>
      <a-select ref="select"  v-model:value="map_line_data.lineType" style="width: 120px"  >
        <a-select-option value="管线">管线</a-select-option>
        <a-select-option value="实线">实线</a-select-option>
        <a-select-option value="虚线">虚线</a-select-option>
        <a-select-option value="双实线">双实线</a-select-option>
        <a-select-option value="实虚线">实虚线</a-select-option>
        <a-select-option value="发光线">发光线</a-select-option>
      </a-select>
    </div>
    <div class="viewer_right_item">
      <span class="viewer_right_tit">颜色：</span>
      <colorpicker :color="map_line_data.color"  @getPickerColor="setColor" ></colorpicker>
    </div>
    <template v-if="map_line_data.lineType == '管线'">
      <div class="viewer_right_item">
        <span class="viewer_right_tit">管线半径：</span>
        <a-input type="text" v-model:value="map_line_data.radius" @input="chooseSelect"></a-input>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">显示边框：</span>
        <a-checkbox v-model:checked="map_line_data.outline" @change="chooseSelect"></a-checkbox>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">边框宽度：</span>
        <a-input type="text" v-model:value="map_line_data.outlineWidth"></a-input>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">边框颜色：</span>
        <colorpicker :color="map_line_data.outlineColor" @getPickerColor="setColor2"></colorpicker>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">管线切面：</span>
        <a-select v-model:value="map_line_data.shape" style="width: 208px" @change="chooseSelect">
          <a-select-option value="1">圆切面</a-select-option>
          <a-select-option value="119">三角形切面</a-select-option>
          <a-select-option value="90">四边形切面</a-select-option>
          <a-select-option value="60">六边形切面</a-select-option>
          <a-select-option value="40">九边形切面</a-select-option>
        </a-select>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">拐角类型：</span>
        <a-select v-model:value="map_line_data.cornerType" style="width: 208px" @change="chooseSelect">
          <a-select-option value="0">圆角</a-select-option>
          <a-select-option value="1">直角</a-select-option>
          <a-select-option value="2">斜角</a-select-option>
        </a-select>
      </div>
    </template>
    <template v-if="map_line_data.lineType != '管线'">
      <div class="viewer_right_item">
        <span class="viewer_right_tit">宽度：</span>
        <a-input type="text" v-model:value="map_line_data.width" @input="chooseSelect"></a-input>
      </div>
    </template>
    <template v-if="map_line_data.lineType == '虚线'||map_line_data.lineType == '实虚线'">
      <div class="viewer_right_item">
        <span class="viewer_right_tit">虚线间隔：</span>
        <a-input type="text" v-model:value="map_line_data.dashLength" @input="chooseSelect"></a-input>
      </div>
    </template>
    <div class="viewer_right_btnx m_t_2">
      <div class="viewer_btn_def" @click="save">保存</div>
      <div class="viewer_btn_cancel m_l_1" @click="clear">清除</div>
    </div>
  </div>
</div>
</template>

<script>
import customMaterial from "@/until/customerMaterial"
var Cesium = require("cesium/Cesium");
var viewer = null;
var handler = null;
var temporaryPolgon = null;
var resultPolgon =null;
export default {
  name: "line_pie",
  data(){
    return{
        cornerTypes: [Cesium.CornerType.ROUNDED, Cesium.CornerType.MITERED, Cesium.CornerType.BEVELED],
      editData:{
        "map_line_type_id": "",
        "map_line_name": "",
        "map_line_context": "",
        "map_line_enable": 0,
        "map_line_display_name": 0,
        "map_line_display_context": 0,
        "map_line_data": "",
        "map_line_id": "",
        "map_line_create_time": "",
        "map_line_create_user_id": "",
        "map_line_update_time": "",
        "map_line_update_user_id": "",
        "map_line_project_id": ""
      },
      map_line_data: {
        "lineType":"管线",
        color: "rgba(235, 29, 78,1)",
        radius: 2,//半径
        shape: "1",//切面形状
        cornerType: "0",//拐角样式
        extrudedHeight: 4,//
        angle: 0,
        outline: false,//是否显示边框
        outlineWidth: 2,//边框宽度
        outlineColor: 'rgba(255,235,60,1)',//边框颜色
        areapos: [],
        width:4,//宽度
        dashLength:40,//虚线间隔
        intervalRatio:0.25,//虚线间隔
      },

    }
  },
  beforeUnmount(){
    if(viewer){
      console.log("destory");
      var canvas = viewer.scene.canvas;
      var gl = canvas.getContext("webgl");
      gl.getExtension('WEBGL_lose_context').loseContext();
      gl = null;
      viewer.destroy();
    }
  },
  mounted(){
    this.initMap();
  },
  methods:{
    initMap(fun) {
      viewer = new Cesium.Viewer("viewer-container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        shouldAnimate:true,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });
      viewer.scene.moon.show = false;
      viewer.scene.sun.glowFactor = 1
      viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
        url: " http://webst01.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}",
        minimumLevel: 1,
        maximumLevel: 18
      }));
      /*viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      });*/
      /*this.clickEvent()
      this.hideGlobe()*/
      const kmlUrl = './cs.kml';
      var datasource= viewer.dataSources.add(Cesium.KmlDataSource.load(kmlUrl,
          {
            camera: viewer.scene.camera,
            canvas: viewer.scene.canvas
          })
      );
      viewer.zoomTo(datasource)
    },
    clickEvent() {
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      //点击
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
          if (resultPolgon) {
            return
          }
          if (temporaryPolgon) {
            this.map_line_data.areapos.pop();
          }
          this.map_line_data.areapos.push([lon, lat,0])
          if (temporaryPolgon) {
            this.map_line_data.areapos.push([lon, lat,0]);
          }
          if (!temporaryPolgon && this.map_line_data.areapos.length >= 2) {
            console.log("oooooooooooooooooooooo")
            this.map_line_data.areapos.push([lon, lat,0]);
            this.drawArea();
          }
          this.$forceUpdate();
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      //鼠标滑动
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.endPosition, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
          if(resultPolgon){
            return
          }

          if (temporaryPolgon) {
            this.map_line_data.areapos[this.map_line_data.areapos.length - 1] = [lon, lat,0]
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      //右键
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          if (this.active === '') {
            return
          }
          if (temporaryPolgon) {
            viewer.entities.remove(temporaryPolgon);
            temporaryPolgon = null;
            this.map_line_data.areapos.pop();
            this.drawAreaResult();
          }
        }
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
    drawArea() {
      console.log(temporaryPolgon)
      if(this.map_line_data.areapos.length<=0){
        return
      }


      temporaryPolgon = viewer.entities.add({
        id: "area1",
        description: this.editData.map_line_name,
        position: Cesium.Cartesian3.fromDegrees(this.map_line_data.areapos[0][0], this.map_line_data.areapos[0][1], this.map_line_data.areapos[0][2]),
        label: {
          text: this.editData.map_line_name,
          font: '20px sans-serif',
          pixelOffset: new Cesium.Cartesian2(-10, -10),
          fillColor: Cesium.Color.fromCssColorString('#1890ff'),
        },
        polylineVolume: this.map_line_data.lineType=="管线"?{
          positions: new Cesium.CallbackProperty(() => {
            // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
            return Cesium.Cartesian3.fromDegreesArrayHeights(this.map_line_data.areapos.flat());
          }, false),
          cornerType: this.cornerTypes[this.map_line_data.cornerType],
          material: Cesium.Color.fromCssColorString(this.map_line_data.color)/*new Cesium.ImageMaterialProperty({
            image:require("../assets/images/test.png")
          })*/,//Cesium.Color.fromCssColorString(this.color),
          shape: this.computeCircle(this.map_line_data.angle, this.map_line_data.shape, this.map_line_data.radius),
          outline: this.map_line_data.outline,
          outlineWidth: this.map_line_data.outlineWidth,
          outlineColor: Cesium.Color.fromCssColorString(this.map_line_data.outlineColor)
        }:{},
        polyline: this.map_line_data.lineType!="管线"?{
          positions: new Cesium.CallbackProperty(() => {
            // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
            return Cesium.Cartesian3.fromDegreesArrayHeights(this.map_line_data.areapos.flat());
          }, false),
          width: this.map_line_data.width,
          material:this.changeMaterial()
        }:{}
      });
      if(resultPolgon){
        resultPolgon.show = false;
      }
    },
    drawAreaResult() {
      if(resultPolgon){
        //viewer.entities.remove(resultPolgon[this.active])
        return
      }
      if(this.map_line_data.areapos.length<=0){
        return
      }

      resultPolgon = viewer.entities.add({
        id: "area" + this.active,
        description: this.editData.map_line_name,
        position: Cesium.Cartesian3.fromDegrees(this.map_line_data.areapos[0][0], this.map_line_data.areapos[0][1], this.map_line_data.areapos[0][2]),
        label: {
          text: this.editData.map_line_name,
          font: '20px sans-serif',
          pixelOffset: new Cesium.Cartesian2(-10, -10),
          fillColor: Cesium.Color.fromCssColorString('#1890ff'),
        },
        polylineVolume:this.map_line_data.lineType=="管线"?{
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(this.map_line_data.areapos.flat()),
          cornerType: this.cornerTypes[this.map_line_data.cornerType],
          material: Cesium.Color.fromCssColorString(this.map_line_data.color)/*new Cesium.ImageMaterialProperty({
            image:require("../assets/images/test.png")
          })*/,//Cesium.Color.fromCssColorString(this.color),
          shape: this.computeCircle(this.map_line_data.angle, this.map_line_data.shape, this.map_line_data.radius),
          outline: this.map_line_data.outline,
          outlineWidth: this.map_line_data.outlineWidth,
          outlineColor: Cesium.Color.fromCssColorString(this.map_line_data.outlineColor)
        }:{},
        polyline: this.map_line_data.lineType!="管线"?{
          positions: new Cesium.CallbackProperty(() => {
            // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
            return Cesium.Cartesian3.fromDegreesArrayHeights(this.map_line_data.areapos.flat());
          }, false),
          width: this.map_line_data.width,
         /* material: new Cesium.PolylineDashMaterialProperty({
            color:Cesium.Color.fromCssColorString(this.map_line_data.color),
            gapColor:Cesium.Color.TRANSPARENT,
            dashLength:20,
          }),*/
          material: this.changeMaterial(),
        }:{}
      });
      //console.log(JSON.stringify(this.editData.areapos))
    },
    computeCircle(angle, shape, radius) {
      var positions = [];
      for (var i = parseInt(angle); i < (360 + parseInt(angle)); i = i + parseInt(shape)) {
        var radian = Cesium.Math.toRadians(i);
        var x = radius * Math.cos(radian);
        var y = radius * Math.sin(radian);
        positions.push(new Cesium.Cartesian2(x, y));
      }
      return positions;
    },
    setColor(e) {
      this.map_line_data.color = e;
      this.chooseSelect();
    },
    setColor2(e) {
      this.map_line_data.outlineColor = e;
      this.chooseSelect();
    },
    chooseSelect(){
      if(temporaryPolgon){
        if(this.map_line_data.lineType=="管线"){
          temporaryPolgon.polylineVolume.material = Cesium.Color.fromCssColorString(this.map_line_data.color)
        }else{
          temporaryPolgon.polyline.material = this.changeMaterial()
        }
      }
      if(resultPolgon){
        if(this.map_line_data.lineType=="管线"){
          resultPolgon.polylineVolume.material = Cesium.Color.fromCssColorString(this.map_line_data.color)
        }else{
          temporaryPolgon.polylineVolume.material = this.changeMaterial()
        }
      }
    },
    save() {
      if(this.active!==''){
        var url = "/maps/line/up";
        var data = JSON.parse(JSON.stringify(this.editData));
        data.map_line_data = JSON.stringify(this.map_line_data);
        data.map_line_update_user_id = this.$cookie.get("user_id");
        this.$axios.put(url,data).then((res)=>{
          if(res.status ==200){
            if(temporaryPolgon){
              viewer.entities.remove(temporaryPolgon);
              temporaryPolgon = null;
            }
            this.$showSuccess("保存成功！");
            this.getData();
          }
        }).catch((res)=>{
          console.log(res);
        })

      }
    },
    clear(){
      this.map_line_data.areapos = [];
      if(temporaryPolgon){
        viewer.entities.remove(temporaryPolgon);
        temporaryPolgon = null;
      }
      if(resultPolgon){
        viewer.entities.remove(resultPolgon);
        resultPolgon = null;
      }
    },
    //实线
    solidLine(){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(this.map_line_data.color),
        gapColor:Cesium.Color.fromCssColorString(this.map_line_data.color),
        // dashLength:40,
        //dashPattern: 255
      })
    },
    //虚线
    dashedLine(){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(this.map_line_data.color),
        gapColor:Cesium.Color.TRANSPARENT,
        dashLength:this.map_line_data.dashLength,
        //dashPattern: 255
      })
    },
    //双实线
    doubleLine(){
      return customMaterial.DoubleSolidLine({
        color:Cesium.Color.fromCssColorString(this.map_line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    //实虚线
    doubleDashLine(){
      return customMaterial.DashedSolidLine({
        color:Cesium.Color.fromCssColorString(this.map_line_data.color),
        dashLength:parseFloat(this.map_line_data.dashLength),//虚线间隔长度
        dashPattern:255,
        intervalRatio:this.map_line_data.intervalRatio,//两条线之间的空隙  占宽度的百分比
        v_polylineAngle:90,
        maskLength:10,
        sideWise:false,
      })
    },
    //发光线
    glowLine(){
      return new Cesium.PolylineGlowMaterialProperty({
        color:Cesium.Color.fromCssColorString(this.map_line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    changeMaterial(){
      var material = null;
      if(this.map_line_data.lineType=="实线"){
        material = this.solidLine();
      }
      if(this.map_line_data.lineType=="虚线"){
        material = this.dashedLine();
      }
      if(this.map_line_data.lineType=="双实线"){
        material = this.doubleLine();
      }
      if(this.map_line_data.lineType=="实虚线"){
        material = this.doubleDashLine();
      }
      if(this.map_line_data.lineType=="发光线"){
        material = this.glowLine();
      }
      return material
    },
    hideGlobe(){
      viewer.imageryLayers.removeAll();
      viewer.scene.globe.baseColor =Cesium.Color.fromCssColorString("#0a143f");
      viewer.scene.globe.showGroundAtmosphere = false;
      viewer.scene.globe.translucency.enabled = true;
      viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0);
      viewer.scene.globe.undergroundColor = undefined;
      viewer.scene.skyAtmosphere.show = true; //是否隐藏大气圈
    },

  }
}
</script>

<style scoped>

</style>