<template>
<div class="valveHeader">
  <div class="valve_header">{{title}}</div>
</div>
</template>

<script>
export default {
  name: "valveHeader",
  props:{
    title:{
      type:String,
      default:"供热系统"
    }
  }
}
</script>

<style lang="less" scoped>
.valveHeader{
  width: 100%;
  height: 1.18rem;
  background: url("../assets/images/valve/top.png") center/100% 100% no-repeat;

}
.valve_header{
  text-align: center;
  width: 100%;
  line-height: 0.9rem;
  font-size: 0.34rem;
  color: #1980eb;
  font-weight: bold;
  letter-spacing: 3px;
}

</style>