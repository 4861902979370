<template>
<div class="valvePage">
  <valve-header :title="title"></valve-header>
  <div class="valveHomeBtn" @click="valveHomeBtn">首页</div>
  <div class="valveHeaderRight">
    <div class="weather">
      <iframe width="210" scrolling="no" height="25" frameborder="0" allowtransparency="true" src="https://i.tianqi.com?c=code&id=26&color=%23FFFFFF&icon=1&site=12"></iframe>
    </div>
    <div class="time">
      <div style="color: #57adfb;">{{ time1 }}</div>
    </div>
  </div>
  <div class="valveContainer">
    <div class="valveLeft">
      <div class="org_searchBox">
        <input type="text" placeholder="请输入关键字搜索" autocomplete="off" v-model="dev_name">
      </div>
      <div class="orgCont">
        <ul class="orgList">
          <template v-for="(item,index) in deviceList" :key="index">
            <li  v-if="item.device_name.includes(dev_name)">
              <p class="" @click="showChild(index)">
                <caret-down-outlined class='icon_jian'  v-if="item.show_child"   />
                <caret-right-outlined class='icon_jian'  v-else   />
                <span>{{ item.device_name }}</span>
              </p>
              <ul class="orgList orgList1" v-show="item.show_child">
                <li v-for="(val,key) in item.xqList" :key="key">
                  <p >
                    <caret-down-outlined class='icon_jian'  v-if="val.show_child"   />
                    <caret-right-outlined class='icon_jian'  v-else   />
                    <span>{{ val.community_name }}</span>
                  </p>
                  <ul class="orgList orgList2" v-show="val.show_child">
                    <li v-for="(v,k) in item.parts" :key="k">
                      <p :class='{"active":(active_part==(item.device_id+"_"+val.community_id+"_"+v.device_model_partition_id))}' @click="choosePart(index,key,k)">
                        <span>{{ v.device_model_partition_name }}</span>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
<!--      <a-tree

          :tree-data="gData"
          @expand="onExpand"
      >
        <template #title="{ title }">
        <span v-if="title.indexOf(searchValue) > -1">
          {{ title.substr(0, title.indexOf(searchValue)) }}
          <span style="color: #f50">{{ searchValue }}</span>
          {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
        </span>
          <span v-else>{{ title }}</span>
        </template>
      </a-tree>-->
    </div>
    <div class="valveRight">
      <div class="top_cont" style="height: 32px">
        <div class="formItem" v-if="!show_history" >
          <input type="checkbox" value="1" class="check-custom" v-model="show_table"  @change="checkCard">
          <span class="formTit m_l_1">表格显示</span>
        </div>
        <template v-if="show_table&&!show_history">
          <div class="formItem m_l_1" >
            <span class="formTit">设备：</span>
            <a-input v-model:value="keyword" autofocus placeholder="输入关键字"/>
          </div>
          <div class="formItem" >
            <a-button type="primary"  @click="search" class="btn_1 m_l_1">查询</a-button>
          </div>
        </template>
        <template v-if="show_history">
          <div class="formItem m_l_1" >
            <span class="formTit">设备：</span>
            <a-select
                show-search
                v-model:value="device_id"
                placeholder="选择"
                style="width: 200px"
                :options="unitValves"
                :field-names="{'label':'unitvalve_name','value':'unitvalve_id'}"
                :filter-option="filterDev"
            >
            </a-select>
          </div>
          <div class="formItem m_l_1">
            <span class="formTit">开始时间：</span>
            <a-date-picker v-model:value="start" show-time/>
            <span class="formTit m_l_1">结束时间：</span>
            <a-date-picker v-model:value="end" show-time/>
          </div>
          <div class="formItem" >
            <a-button type="primary"  @click="searchHis" class="btn_1 m_l_1">查询</a-button>
          </div>
        </template>
        <div style="position: absolute;right: 20px">
          <div class="m_l_1" style="border-radius: 2px;width: 88px;text-align: center;line-height: 32px;cursor:pointer;color:#ffffff;background-color: #37ca86;display: inline-block" @click="showBatchModal">批量处理</div>
          <div class="m_l_1" style="border-radius: 2px;width: 88px;text-align: center;line-height: 32px;cursor:pointer;color:#ffffff;background-color: #13bbc8;display: inline-block" v-if="!show_history" @click="showHistory">历史数据</div>
          <a-button type="primary"  @click="hideHistory" class="btn_1 m_l_1" v-if="show_history">返回</a-button>
        </div>
      </div>
      <div style="display: flex;line-height: 30px" v-if="!show_history">
        <div style="color: #57adfb;">查询结果：<span class="org">{{unitValves.length}}条</span></div>
        <div style="margin-left: 20px;color: #57adfb;">已选中：<span class="org">{{checked.length}}条</span></div>
      </div>
      <template v-if="show_history">
        <div class="historyData">
          <div class="tableData" style="margin-top: 0.1rem">
            <table class="table table-bordered" >
              <thead>
              <tr>
                <td class="text-center">上报时间</td>
                <td class="text-center">回水温度</td>
                <td class="text-center">电池电压</td>
                <td class="text-center">阀门开度</td>
                <td class="text-center">阀门状态</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in historyData" :key="index">
                <td>{{ item.du_time }}</td>
                <td>{{item.du_backwater}}</td>
                <td>{{ item.du_vbat }}</td>
                <td>{{ item.du_valvelevel }}</td>
                <td :style='{"color":item.du_valvefault==1?"#DF412E":"#1FBD3B"}'>{{ item.du_valvefault==1?"故障":(item.du_valvefault===0?"正常":"") }}</td>
              </tr>
              <!--          <tr>
                          <td><input type="checkbox" class="check-custom"  ></td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>-->
              </tbody>

            </table>
            <a-pagination size="small" :total="historyDataNum" :show-total="total => `共 ${total} `" @change="changePage" />
          </div>
        </div>

      </template>
      <template v-else>
        <div class="cardData" v-if="!show_table">
          <div class="cardItem" v-for="(item,index) in unitValves" :key="index" @click="showUnitValve(index)">
            <div class="card_left">
              <div>{{ item.unitvalve_name }}</div>
              <div>开度：{{ item.du_valvelevel }}%</div>
            </div>
            <div class="card_right">
              <div>状态：<span :class='{"green":!item.du_valvefault,"red":item.du_valvefault}'>{{ item.du_valvefault?"故障":(item.du_valvefault===0?"正常":"") }}</span></div>
              <div>回温：<span class="red">{{ item.du_backwater }}℃</span></div>
            </div>
          </div>
          <!--        <div class="cardItem">
                    <div class="card_left">
                      <div>29号楼</div>
                      <div>开度：35%</div>
                    </div>
                    <div class="card_right">
                      <div>状态：<span class="green">正常</span></div>
                      <div>回温：<span class="red">16.1℃</span></div>
                    </div>
                  </div>
                  <div class="cardItem">
                    <div class="card_left">
                      <div>29号楼</div>
                      <div>开度：35%</div>
                    </div>
                    <div class="card_right">
                      <div>状态：<span class="green">正常</span></div>
                      <div>回温：<span class="red">16.1℃</span></div>
                    </div>
                  </div>-->
        </div>
        <div class="tableData" v-if="show_table" style="position: relative;height: calc(100% - 76px);margin-top: 0.1rem">
          <table class="table table-bordered" style="position: absolute;top: -1px;width: 100%;left: 0;z-index: 1;margin-top: 0">
            <thead>
            <tr class="head_tr">
              <td>
                <input type="checkbox" class="check-custom" v-model="checkAll" @change="checkAllFun"   >
              </td>
              <td class="text-center">设备名称</td>
              <td class="text-center">回水温度</td>
              <td class="text-center">阀门设定</td>
              <td class="text-center">阀门开度</td>
              <td class="text-center">阀门状态</td>
              <td class="text-center">电池电压</td>
              <td class="text-center">时间间隔</td>
              <td class="text-center">时间</td>
              <td class="text-center">操作</td>
            </tr>
            </thead>
          </table>
          <div style="width: 100%;height: calc(100% - 20px );overflow:auto">
            <table class="table table-bordered" style="margin-top: 0">
              <thead>
              <tr class="border_tr">
                <td>
                  <input type="checkbox" class="check-custom" v-model="checkAll" @change="checkAllFun"  >
                </td>
                <td class="text-center">设备名称</td>
                <td class="text-center">回水温度</td>
                <td class="text-center">阀门设定</td>
                <td class="text-center">阀门开度</td>
                <td class="text-center">阀门状态</td>
                <td class="text-center">电池电压</td>
                <td class="text-center">时间间隔</td>
                <td class="text-center">时间</td>
                <td class="text-center">操作</td>
              </tr>
              </thead>
              <tbody>
              <template v-for="(item,index) in unitValves" :key="index">
                <tr  v-if="item.unitvalve_name.includes(keyword_s)">
                  <td><input type="checkbox" class="check-custom" name="group" v-model="checked" :value="item.unitvalve_id" @change="checkFun"  ></td>
                  <td>{{ item.unitvalve_name }}</td>
                  <td>{{ item.du_backwater }}</td>
                  <td>{{ item.unitvalve_setting_value }}{{ item.unitvalve_setting_value!==null&&item.unitvalve_setting_value!==""?"%":"" }}</td>
                  <td>{{ item.du_valvelevel }}{{ item.du_valvelevel!==undefined&&item.du_valvelevel!==null&&item.du_valvelevel!==""?"%":"" }}</td>
                  <td :class='{"green":(!item.du_valvefault),"red":(item.du_valvefault)}'>{{ item.du_valvefault==1?"故障":(item.du_valvefault===0?"正常":"") }}</td>
                  <td>{{ item.du_vbat }}</td>
                  <td>{{ item.unitvalve_time_interval }}</td>
                  <td>{{ item.du_time?item.du_time.replace("T"," "):"" }}</td>
                  <td>
                    <form-outlined style="color:#0aca33;cursor:pointer" @click="showUnitValve(index)"/>
                  </td>
                </tr>
              </template>
              <!--          <tr>
                          <td><input type="checkbox" class="check-custom"  ></td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                        </tr>-->
              </tbody>
            </table>
          </div>
        </div>
      </template>

    </div>
  </div>

  <div :class='{"web_modal":true, "bg":true,"show_m":(showModal)}'>
    <div class="modal_content" style="width: 310px;height: 180px">
      <div class="modal_top">
        <p class="title">{{info.unitvalve_name}}</p>
<!--        <img src="img/close.png" @click="closeModal">-->
        <close-outlined class="img" @click="closeModal" style="color: #57adfb;" />
      </div>
      <div class="modal_split_line"></div>
      <div class="modal_cont">
        <div class="person_info">
          <div class="info">
            <div style="padding: 4px 0;color: #57adfb;">
              <span>阀门开度：</span>
<!--              <input type="text" class="form_input" style="background-color: transparent; border: 1px solid #0358ba" placeholder="输入阀门开度值" v-model="level_set" @keyup="verVal" />-->
              <a-select
                  show-search
                  v-model:value="level_set"
                  placeholder="选择阀门开度"
                  style="width: 200px">
                <a-select-option :value="item" v-for="(item,index) in levels" :key="index">{{ item }}%</a-select-option>
              </a-select>
            </div>
            <div style="padding: 4px 0;color: #57adfb;">
              <span>上报间隔：</span>
              <input type="text" class="form_input" style="background-color: transparent; border: 1px solid #0358ba;height:30px;width:200px" placeholder="输入上报间隔次数（每次一小时）" v-model="device_set_time" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal_bottom">
        <input type="button" value="提交" class="btn-def " style="margin-top: 20px" @click="sendCommand">
      </div>
    </div>
  </div>

<!-- 批量处理  -->
  <div :class='{"web_modal":true, "bg":true,"show_m":(showBatch)}'>
    <div class="modal_content" style="width: 310px;height: 180px">
      <div class="modal_top">
        <p class="title">批量处理</p>
        <close-outlined class="img" @click="closeModal" style="color: #57adfb;" />
      </div>
      <div class="modal_split_line"></div>
      <div class="modal_cont">
        <div class="person_info">
          <div class="info">
            <div style="padding: 4px 0;color: #57adfb;">
              <span>阀门开度：</span>
<!--              <input type="text" placeholder="输入阀门开度值" v-model="level_set" @keyup="verVal" style="background-color: transparent; border: 1px solid #0358ba" />-->
              <a-select
                  show-search
                  v-model:value="level_set"
                  placeholder="选择阀门开度"
                  style="width: 200px">
                <a-select-option :value="item" v-for="(item,index) in levels" :key="index">{{ item }}%</a-select-option>
              </a-select>
            </div>
            <div style="padding: 4px 0;color: #57adfb;">
              <span>上报间隔：</span>
              <input type="text" class="form_input" style="background-color: transparent; border: 1px solid #0358ba;height:30px;width:200px" placeholder="输入上报间隔次数（每次一小时）" v-model="device_set_time" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal_bottom">
        <input type="button" value="提交" class="btn-def " style="margin-top: 18px" @click="sendCommandBatch">
      </div>
    </div>
  </div>

</div>
</template>

<script>
import $ from "jquery";
import Dayjs from 'dayjs';
export default {
  name: "index",
  data(){
    return {
      title:"单元阀管理系统",
      searchValue:"",
      expandedKeys:[],
      autoExpandParent:true,
      gData:[
        {
          title: 'parent 1',
          key: '0-0',
          children: [
            {
              title: 'parent 1-0',
              key: '0-0-0',
              children: [
                { title: 'leaf', key: '0-0-0-0' },
                { title: 'leaf', key: '0-0-0-1' },
              ],
            },
            {
              title: 'parent 1-1',
              key: '0-0-1',
              children: [{ key: '0-0-1-0', title: 'sss' }],
            },
          ],
        },
      ],
      show_table:false,
      keyword:"",
      keyword_s:"",
      dpList:[],
      deviceList:[],
      xqList:[],
      active_part:"",
      unitValves:[],
      time1:"",
      time2:"",
      dev_name:"",
      show_history:false,
      historyData:[],
      historyDataNum:0,
      device_id:"",
      device_list:[],
      start:"",
      end:"",
      showModal:false,
      info:{

      },
      level_set:"",
      showBatch:false,
      show_hw:false,
      realData:{},
      checkAll:false,
      checked:[],
      pageIndex:1,
      pageSize:10,
      timer:null,
      levels:[],
      jiange:"",
      device_set_time:"",
      send_index:0,
      send_success:0
    }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.user_level = this.$cookie.get("user_level");
    this.start = Dayjs(this.$getTime((new Date()).getTime() - 1000 * 60 * 60 * 24).t1)
    this.end = Dayjs()
    var time = this.$getTime(new Date());
    this.time1 = time.t1;
    setInterval(()=>{
      time = this.$getTime(new Date());
      this.time1 = time.t1;
    },1000)
    this.getXQList();
    this.getUnitValves();
    for(var i = 0;i<=100;i+=5){
      this.levels.push(i);
    }
  },
  methods:{
    onExpand(){

    },
    search(){
      this.getUnitValves();
    },
    //获取型号列表
    getDPList(){
      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.getWlwModel(res.data);
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取物联网设备列表
    getWlwModel(data){
      var url = "/heating/device-model/easy-info/part";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.dpList = [];
          this.deviceList = [];
          res.data.forEach((item)=>{
            if(dp_ids.includes(item.device_model_id)){
              this.dpList.push(item);
            }
          })
          this.dpList.forEach((item)=>{
            data.some((val)=>{
              if(item.device_model_id == val.dp_id){
                item = Object.assign(item,val);
                return true
              }
            })
            this.getDeviceList(item);
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取设备列表
    getDeviceList(obj){
      var url = "/heating/zeiot/device/protocol/list?dp_id="+obj.dp_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          var data = res.data;
          data.forEach((item)=>{
            item = Object.assign(item,obj);
            item.show_child = true;
            var xqList = [];
            this.xqList.forEach((val)=>{
              if(val.cbd_device_id == item.device_id){
                val.show_child=true;
                xqList.push(val);
              }
            })
            item.xqList = xqList;
          })
          this.deviceList = this.deviceList.concat(data);
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取已绑定换热站的小区列表
    getXQList(){
      var url = "/heating/community/bind/device/info";
      this.$axios.post(url,{
        zone_id:"",
        community_id:""
      }).then((res)=>{
        if(res.status == 200){
          this.xqList = res.data;
          this.getDPList();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //child 展开 收起
    showChild(index){
      this.deviceList[index].show_child = !this.deviceList[index].show_child;
    },
    //选择分区
    choosePart(dev_i,xq_i,part_i){
      this.active_part = this.deviceList[dev_i].device_id+"_"+this.deviceList[dev_i].xqList[xq_i].community_id+'_'+this.deviceList[dev_i].parts[part_i].device_model_partition_id;
      this.getUnitValves();
      this.checkCard();
      this.checked = [];
      this.checkAll = false;
    },
    //获取单元阀列表
    getUnitValves(){
      var community_id = "";
      var part_id = "";
      if(this.active_part){
        community_id = this.active_part.split("_")[1];
        part_id = this.active_part.split("_")[2];
      }
      var unitValves = [];
      var url = "/heating/device-unitvalve/info";
      this.$axios.post(url,{
        zone_id:"",
        community_id:community_id,
        keyword:this.keyword
      }).then((res)=>{
        if(res.status == 200){
          var unit_imei = [];
          res.data.forEach((item)=>{
            if(item.unitvalve_partition_id.includes(part_id)){
              unitValves.push(item);
              unit_imei.push({
                imei:item.unitvalve_imei
              })
            }
          })
          this.unitValves = unitValves;
          console.log(unitValves);
          this.unitValve_l = unitValves.length;
          clearInterval(this.timer);
          this.getUnitValveData(unit_imei)
          this.timer = setInterval(()=>{
            this.getUnitValveData(unit_imei)
          },30000)
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    valveHomeBtn(){
      this.$router.back()

    },
    //获取单元阀实时数据
    getUnitValveData(unit_ids){
      var url = "/heating/device-unitvalve/read-time";
      var data = unit_ids;
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.realData = res.data;
          this.unitValves.forEach((item)=>{
            if(this.realData[item.unitvalve_imei]){
              item = Object.assign(item,this.realData[item.unitvalve_imei])
            }
          })
          this.checkCard();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //历史数据
    showHistory(){
      this.show_history = true;
      this.device_id = [];
      this.pageIndex = 1;
      this.historyDataNum = 0;
      this.historyData = [];
    },
    hideHistory(){
      this.show_history = false;
    },
    filterDev(e,option){
      return option.unitvalve_name.includes(e);
    },
    //显示表格
    checkCard(){
     setTimeout(()=>{
       if(this.show_table){
         var head_tr_tds = $(".head_tr td");
         var border_tr_tds = $(".border_tr td");
         head_tr_tds.each(function(k){
           $(this).width($(border_tr_tds[k]).width());
         })
       }
     },100)
    },
    //关闭弹窗
    closeModal(){
      this.showModal = false;
      this.showBatch = false;
    },
    //输入下发值
    verVal(){

    },
    //单个下发
    sendCommand(){
     /* if(!/^(?:[1-9]?\d|100)$/.test(this.level_set)){
        return this.$showError("请输入0-100的整数！");
      }*/

      if(this.level_set===""||this.device_set_time ===""){
        return this.$showError("开度和间隔为必填！");
      }
      var url = "/heating/device-unitvalve/distribute";
      var data = {
        "unitvalve_id": this.info.unitvalve_id,
        "device_set_valvelevel": this.level_set,
        "device_set_time": this.device_set_time
      }
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.showModal = false;
          this.$showSuccess("下发成功！");
          this.getUnitValves();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //显示下发弹窗
    showUnitValve(index){
      if(this.user_level>1){
        return this.$showWarmTip("无操作权限！");
      }
      this.showModal = true;
      this.info = Object.assign({},this.unitValves[index]);
    },
    //显示批量下发弹窗
    showBatchModal(){
      if(this.checked.length<=0){
        return this.$showError("请选中一条数据进行下发！");
      }
      if(this.user_level>1){
        return this.$showWarmTip("无操作权限！");
      }
      this.showBatch = true;
    },
    //批量下发
    sendCommandBatch(){
      if(this.checked.length<=0){
        return false
      }
      /*if(!/^(?:[1-9]?\d|100)$/.test(this.level_set)){
        return this.$showError("请输入0-100的整数！");
      }*/
      this.send_index = 0;
      this.send_success = 0;
      if(this.level_set===""||this.device_set_time ===""){
        return this.$showError("开度和间隔为必填！");
      }
      this.checked.forEach((item)=>{
        this.sendFun(item)
      })
    },
    sendFun(unitvalve_id,fun){
      var url = "/heating/device-unitvalve/distribute";
      var data = {
        "unitvalve_id":unitvalve_id,
        "device_set_valvelevel": this.level_set,
        "device_set_time": this.device_set_time
      }
      this.$axios.post(url,data).then((res)=>{
        this.send_index = this.send_index+1;
        if(res.status == 200){
          this.send_success = this.send_success+1;
          if(fun){
            fun()
          }
        }
        if(this.send_index == this.checked.length){
          this.$showSuccess("下发成功"+this.send_success+"条！");
          this.showBatch = false;
        }
      }).catch((res)=>{
        this.send_index = this.send_index+1;
        if(this.send_index == this.checked.length){
          this.$showSuccess("下发成功"+this.send_success+"条！");
        }
      })
    },
    checkAllFun(){
      console.log(this.checkAll);
      this.checked = [];
      if(this.checkAll){
        this.unitValves.forEach((item)=>{
          this.checked.push(item.unitvalve_id)
        })
      }
    },
    checkFun(){
      if(this.checked.length>=this.unitValves.length){
        this.checkAll = true
      }else{
        this.checkAll = false;
      }

    },
    //历史数据查询
    searchHis(){
      if(!this.device_id){
        this.$showWarmTip("查询项不能为空！");
        return false
      }
      this.pageIndex = 1;
      this.getHistoryData();
    },
    getHistoryData(){
      this.historyData = [];
      var url = "/heating/device-unitvalve/his-page";
      var data = {
        "keyword": "",
        "pageSize": this.pageSize,
        "pageNo": this.pageIndex,
        "device_id": this.device_id,
        "start": this.$getTime(this.start.$d).t4,
        "end": this.$getTime(this.end.$d).t4
      }
      this.$axios.post(url,data).then((res)=>{
        this.historyData = res.data.data;
        this.historyDataNum = res.data.totalItems;
        this.historyData.forEach((item)=>{
          item.du_time = item.du_time?this.$getTime(item.du_time).t1:"";
        })
      }).catch((res)=>{
        console.log(res);
      })
    },
    changePage(pageIndex,pageSize){
      this.pageIndex = pageIndex;
      this.getHistoryData();
    }
  }
}
</script>
<style>

</style>

<style scoped lang="less">
@import "../../assets/css/valve.less";

</style>