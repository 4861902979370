<template>
<div class="loginPage">
  <div class="login_top">
    <img style="width: 120px;height: 40px" :src='login_info.system_login_template_logo_image?(ip2+"/upload/登录模板配置/"+login_info.system_login_template_logo_image):"../assets/images/login/logo.png"'>
  </div>
  <div class="loginCont">
    <div class="login_pt" >
      <img style="width: 100%;height: 100%" :src='login_info.system_login_template_image?(ip2+"/upload/登录模板配置/"+login_info.system_login_template_image):"../images/login/pt.png"' >
    </div>
    <div class="loginFromCont">
      <div class="login_from">
        <div class="from_tit">系统登录</div>
<!--        <div class="from_item">
          <i class="iconfont icon-zhanghao-"></i>
          <input type="text" placeholder="请输入别名" v-model="project_alias">
        </div>-->
        <div class="from_item">
          <i class="iconfont icon-zhanghao-"></i>
          <input type="text" placeholder="请输入账号" v-model="account" >
        </div>
        <div class="from_item">
          <i class="iconfont icon-mima"></i>
          <input type="password" placeholder="请输入密码" v-model="password" >
        </div>
<!--        <div class="from_item">
          <input type="password" placeholder="请输入验证码" v-model="code">
          <img :src="img" @click="getCode" style="cursor:pointer;">
        </div>-->
        <div class="login_btn" @click="login" >登录</div>
      </div>
      <div class="beian" ><a href="javascript:;" v-html="login_info.system_login_template_config" style="color: #ffffff"></a></div>
      <div class="login_info" >
        <div class="login_info_item">
          <div class="login_info_tit">联系我们</div>
          <div class="login_info_val" v-html="login_info.system_login_template_contact"></div>
        </div>
        <div class="login_info_item" v-for="(item,index) in login_info.system_login_template_qrcode" :key="index" >
          <div class="login_info_tit">{{ item.title }}</div>
          <div class="login_info_val">
            <img :src='ip2+("/upload/登录模板配置/")+item.qr' style="width: 80px;height: 80px">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: "login",
  data(){
    return {
      project_alias:"",
      account:"",
      password:"",
      code:"",
      code_key:"",
      img:"",
      project_id:"",
      login_info:{},
      ip2:""

    }
  },
  unmounted(){
    window.removeEventListener('keydown', this.keyDown, false)
  },
  mounted(){
    this.project_alias = project_alias;
    this.ip2 = this.$axios_ip.defaults.baseURL;
    this.getLoginInfo();

    window.addEventListener('keydown', this.keyDown)
    //this.getCode();
    //this.getProject();

  },
  methods:{
    keyDown(e){
      if (e.keyCode == 13) {
        this.login()
      }
    },
    login(){
      var url = "/heating/login";
      var data = {
        project_alias:this.project_alias,
        account:this.account,
        password: btoa(this.password)
      }
      if(!this.account||!this.password){
        return this.$showError("用户名密码不能为空！")
      }
      this.$axios.post(url,data).then((res)=>{
        var result = res.data.user;
        if(res.status == 200){
          this.$cookie.set("user_id",result.user_id,{path:"/"});
          this.$cookie.set("token",res.data.token,{path:"/"});
          this.$cookie.set("project_id",result.project_id,{path:"/"});
          this.$cookie.set("user_account",result.user_account,{path:"/"});
          this.$cookie.set("user_org_id",result.user_org_id,{path:"/"});
          this.$cookie.set("user_salt",result.user_salt,{path:"/"});
          this.$cookie.set("user_system_id",result.user_system_id,{path:"/"});
          this.$cookie.set("user_level",result.user_level,{path:"/"});
          this.$cookie.set("user_type",result.user_type,{path:"/"});
          this.$cookie.set("zeiot_token",result.token,{path:"/"});
          this.toHome();
        }
      }).catch((res)=>{
      })

    },
    toHome(){
      this.$router.push({
        name:"main"
      })
    },
    //获取验证码
    getCode(){
      var url = "/api/verification";
      this.$axios.get(url).then((res)=>{
        console.log(res);
        this.code_key = res.data.key;
        this.img = res.data.value;
      })
    },
    //获取项目信息
    getProject(){
      var url = '/api/projects/alias/3D轨迹';
      this.$axios.get(url).then((res)=>{
        console.log(res);
        this.project_id = res.data.project_id;
      })
    },
    //获取项目登录信息
    getLoginInfo(){
      var url = "/heating/zeiot/system-config";
      this.$axios.post(url).then((res)=>{
        if(res.status == 200){
          this.login_info = res.data.length>0?res.data[0]:{};
          this.login_info.system_login_template_qrcode = this.login_info.system_login_template_qrcode?JSON.parse(this.login_info.system_login_template_qrcode):[]
        }
      }).catch((res)=>{
        console.log(res);
      })
    /*  this.$getZeiotApi(url,"get","",(res)=>{
        this.login_info = res.data&&res.data.length>0?res.data[0]:{};
        this.login_info.system_login_template_qrcode = this.login_info.system_login_template_qrcode?JSON.parse(this.login_info.system_login_template_qrcode):[]
      })*/
    }
  }
}
</script>

<style scoped>
@import "../assets/css/login.less";

</style>