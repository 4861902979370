<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>

import zhCN from "ant-design-vue/es/locale/zh_CN";//ant-design-vue自带，只需要引入
export default {
  name: 'App',
  setup(){
    return {
      zhCN
    }
  },
}
</script>

<style lang="less">
.cesium-viewer-bottom{
  display: none !important;
}
@import "assets/css/default.less";
@import "assets/css/main.less";
</style>
