var Cesium = require("cesium/Cesium");
//双实线
function DoubleSolidLine(options){
    function DoubleSolidLineMaterialProperty(options) {
        this._definitionChanged = new Cesium.Event();
        this._color = undefined;
        this._colorSubscription = undefined;
        this.color = options.color;
        this.taperPower = options.taperPower;
        this.glowPower = options.glowPower;
    }
    Object.defineProperties(DoubleSolidLineMaterialProperty.prototype, {
        isConstant: {
            get: function () {
                return false;
            }
        },
        definitionChanged: {
            get: function () {
                return this._definitionChanged;
            }
        },
        color: Cesium.createPropertyDescriptor('color')
    });
    DoubleSolidLineMaterialProperty.prototype.getType = function (time) {
        return 'DoubleSolidLine';
    }
    DoubleSolidLineMaterialProperty.prototype.getValue = function (time, result) {
        if (!Cesium.defined(result)) {
            result = {};
        }
        result.color = Cesium.Property.getValueOrClonedDefault(this._color, Cesium.Color.WHITE, result.color);
        return result;
    }
    DoubleSolidLineMaterialProperty.prototype.equals = function (other) {
        return this === other ||
            (other instanceof DoubleSolidLineMaterialProperty &&
                Property.equals(this._color, other._color))
    }
    Cesium.DoubleSolidLineMaterialProperty = DoubleSolidLineMaterialProperty;
    Cesium.Material.DoubleSolidLineType = 'DoubleSolidLine';
    Cesium.Material.DoubleSolidLineSource =`czm_material czm_getMaterial(czm_materialInput materialInput)
{
    czm_material material = czm_getDefaultMaterial(materialInput);

    vec2 st = materialInput.st;
    float glow = glowPower / abs(st.t - 0.5) - (glowPower / 0.5);

    if (taperPower <= 0.99999) {
        glow *= min(1.0, taperPower / (0.5 - st.s * 0.5) - (taperPower / 0.5));
    }

    vec4 fragColor;
    fragColor.rgb = max(vec3(glow - 1.0 + color.rgb), color.rgb);
    if(glow - 1.0 > 0.0) {
        fragColor.a = 0.0;
    } else {
        fragColor.a = color.a;
    }
    fragColor = czm_gammaCorrect(fragColor);

    material.emission = fragColor.rgb;
    material.alpha = fragColor.a;

    return material;
}`;
    Cesium.Material._materialCache.addMaterial(Cesium.Material.DoubleSolidLineType, {
        fabric: {
            type: Cesium.Material.DoubleSolidLineType,
            uniforms: {
                color:Cesium.Color.fromCssColorString("#ffffff"),
                glowPower:0.25,
                taperPower:1,
            },
            source: Cesium.Material.DoubleSolidLineSource
        },
        translucent: function (material) {
            return true;
        }
    });
    return new DoubleSolidLineMaterialProperty(options)
}
//实虚线
function DashedSolidLine(options){
    function DashedSolidLineMaterialProperty(options) {
        this._definitionChanged = new Cesium.Event();
        this._color = undefined;
        this._colorSubscription = undefined;
        this.color = options.color;
        this.dashLength = options.dashLength;
        this.dashPattern = options.dashPattern;
        this.intervalRatio = options.intervalRatio;
        this.v_polylineAngle = options.v_polylineAngle;
        this.maskLength = options.maskLength;
        this.sideWise = options.sideWise;
    }
    Object.defineProperties(DashedSolidLineMaterialProperty.prototype, {
        isConstant: {
            get: function () {
                return false;
            }
        },
        definitionChanged: {
            get: function () {
                return this._definitionChanged;
            }
        },
        color: Cesium.createPropertyDescriptor('color')
    });
    DashedSolidLineMaterialProperty.prototype.getType = function (time) {
        return 'DashedSolidLine';
    }
    DashedSolidLineMaterialProperty.prototype.getValue = function (time, result) {
        if (!Cesium.defined(result)) {
            result = {};
        }
        result.color = Cesium.Property.getValueOrClonedDefault(this._color, Cesium.Color.WHITE, result.color);
        return result;
    }
    DashedSolidLineMaterialProperty.prototype.equals = function (other) {
        return this === other ||
            (other instanceof DashedSolidLineMaterialProperty &&
                Property.equals(this._color, other._color))
    }
    Cesium.DashedSolidLineMaterialProperty = DashedSolidLineMaterialProperty;
    Cesium.Material.DashedSolidLineType = 'DashedSolidLine';
    Cesium.Material.DashedSolidLineSource =`
    mat2 rotate(float rad) {
    float c = cos(rad);
    float s = sin(rad);
    return mat2(c, s, -s, c);
}

czm_material czm_getMaterial(czm_materialInput materialInput) {
    czm_material material = czm_getDefaultMaterial(materialInput);
    float minIntervalRatio = min(intervalRatio, 0.9);
    vec2 st = materialInput.st;
    vec2 pos = rotate(v_polylineAngle) * gl_FragCoord.xy;
    float dashPosition = fract(pos.x / (dashLength * czm_pixelRatio));
    float maskIndex = floor(dashPosition * maskLength);
    float maskTest = floor(dashPattern / pow(2.0, maskIndex));
    vec4 fragColor = (mod(maskTest, 2.0) < 1.0) ? vec4(0.0) : color;
    if(sideWise) {
        if(st.t < 0.5 && fragColor.a < 0.005) {
            discard;
        }
    } else {
        if(st.t > 0.5 && fragColor.a < 0.005) {
            discard;
        }
    }

    fragColor = color;
    if(st.t < 0.5 + minIntervalRatio * 0.5 && st.t > 0.5 - minIntervalRatio * 0.5) {
        fragColor.a = 0.0;
    } else {
        fragColor.a = color.a;
    }

    fragColor = czm_gammaCorrect(fragColor);

    material.emission = fragColor.rgb;
    material.alpha = fragColor.a;

    return material;
}`;
    Cesium.Material._materialCache.addMaterial(Cesium.Material.DashedSolidLineType, {
        fabric: {
            type: Cesium.Material.DashedSolidLineType,
            uniforms: {
                color:Cesium.Color.fromCssColorString("#ffffff"),
                dashLength:options.dashLength,
                dashPattern:options.dashPattern,
                intervalRatio:options.intervalRatio,
                v_polylineAngle:options.v_polylineAngle,
                maskLength:options.maskLength,
                sideWise:options.sideWise,
            },
            source: Cesium.Material.DashedSolidLineSource
        },
        translucent: function (material) {
            return true;
        }
    });
    return new DashedSolidLineMaterialProperty(options)
}
export default {
    DoubleSolidLine,
    DashedSolidLine
}