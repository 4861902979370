<template>
  <div class="page ">
    <!--  <breadcrumb :crumbs="crumbs"></breadcrumb>-->
    <div class="viewer_full" id="viewer-container" style="width: 100%;height:100%"></div>
    <div class="viewer_left_list">
      <div class="viewer_left_caption">{{ name }}</div>
      <div class="left_search">
        <i class="iconfont icon-sousuo"></i>
        <input type="text" placeholder="输入关键字搜索" autocomplete="off" v-model="keyword">
      </div>
      <div class="viewer_left_over">
        <div :class='{"viewer_left_item":true,"active":active==item.mark_id}' v-for="(item,index) in deviceList"
             :key="index" @click="chooseDev(index)" v-show="item.device_name.includes(keyword)">
          <span :title="item.device_name">{{ item.device_name }}</span>
          <i class="iconfont icon-dingweixiao" v-if="item.mark_lat&&item.mark_lon"></i>
        </div>
      </div>
    </div>
    <div class="viewer_right_from">
      <div class="viewer_right_caption">--{{ editData.device_name ? editData.device_name : '设备名称' }}--</div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">经度：</span>
        <a-input v-model:value="editData.mark_lon" type="text" placeholder="经度" autocomplete="off" @input="setValue"></a-input>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">纬度：</span>
        <a-input v-model:value="editData.mark_lat" type="text" placeholder="纬度" autocomplete="off" @input="setValue"></a-input>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">高度：</span>
        <a-input v-model:value="editData.mark_height" type="text" placeholder="高度" autocomplete="off" @input="setValue"></a-input>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">供暖面积：</span>
        <a-input-number id="inputNumber" v-model:value="extData.device_attribute_data.gnmj" placeholder="0"  :min="0" />
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">小区面积：</span>
        <a-input-number id="inputNumber" v-model:value="extData.device_attribute_data.xqmj" placeholder="0"  :min="0" />
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">采暖方式：</span>
        <a-input v-model:value="extData.device_attribute_data.cnfs" type="text" placeholder="采暖方式" autocomplete="off" ></a-input>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">理论单耗：</span>
        <a-input-number id="inputNumber" v-model:value="extData.device_attribute_data.lldh" placeholder="0"  :min="0" />
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">指标能耗：</span>
        <a-input-number id="inputNumber" v-model:value="extData.device_attribute_data.zbnh" placeholder="0"  :min="0" />
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">泵类型：</span>
        <a-select ref="select"  style="width: 200px" v-model:value="extData.device_attribute_data.beng_type"   >
          <a-select-option value="2#泵">2#泵</a-select-option>
          <a-select-option value="3#泵">3#泵</a-select-option>
        </a-select>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">视频数量：</span>
        <a-input v-model:value="extData.device_attribute_data.video_num" type="text" placeholder="小区面积" autocomplete="off" ></a-input>
        <a-select ref="select"  style="width: 200px" v-model:value="extData.device_attribute_data.video_num"   >
          <a-select-option value="1">1</a-select-option>
          <a-select-option value="2">2</a-select-option>
          <a-select-option value="3">3</a-select-option>
          <a-select-option value="4">4</a-select-option>
        </a-select>
      </div>
      <span class="viewer_right_tit" style="color: white">视频地址：</span>
      <div class="viewer_right_item" v-for="count in parseInt(extData.device_attribute_data.video_num)" :key="count">
        <span class="viewer_right_tit">{{ count }}：</span>
        <a-input v-model:value="extData.device_attribute_data.videos[count-1]" type="text" placeholder="url" autocomplete="off" ></a-input>
      </div>
      <div class="viewer_right_btnx m_t_2">
        <div class="viewer_btn_def" @click="save">保存</div>
        <div class="viewer_btn_cancel m_l_1" @click="clear">取消标注</div>
      </div>
    </div>
  </div>
</template>

<script>
var Cesium = require("cesium/Cesium");
var viewer = null;
var BillboardCollection = null;
var LabelCollection = null;
var BillboardCollectionObj = {};
var LabelCollectionObj = {};
var handler = null;
var tempBill = null;
var model = null;
var tiles = null;

export default {
  name: "ioTDevicesPoint",
  data() {
    return {
      active: '',
      deviceList: [],
      editData: {
        "mark_id": "",
        "mark_source_id": "",
        "mark_type": "0",
        "mark_lon": "",
        "mark_lat": "",
        "mark_height": 0
      },
      keyword: '',
      iconList: [],
      ip:"",
      id:"",
      name:"",
      deviceAll:[],
      screenList:[],
      screen_id:"",
      map_setting_model_file_src:"",
      modelList:[],
      dp:null,
      extData:{
        "device_attribute_id": "",
        "device_dp_id": "",
        "device_attribute_data": {
          gnmj:0,
          cnfs:'',
          xqmj:0,
          lldh:"",
          zbnh:"",
          beng_type:"",
          video_num:1,
          videos:["","","",""]
        }
      },
      extInfo:{}
    }
  },
  beforeUnmount(){
    if(viewer){
      var canvas = viewer.scene.canvas;
      var gl = canvas.getContext("webgl");
      gl.getExtension('WEBGL_lose_context').loseContext();
      gl = null;
      viewer.destroy();
    }
  },
  mounted() {
    this.ip = this.$axios.defaults.baseURL;
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    var query = this.$route.query;
    this.id = query.id;
    this.name = query.name;
    this.getDPList();
    this.initMap();
    this.getExtInfo();
  },
  methods: {
    initMap() {
      viewer = new Cesium.Viewer("viewer-container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });
      this.$loadGaoDe(viewer);
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      });
      this.drawPoint();
      this.$drawImageEntity(viewer);
      this.getDeviceList();
      this.clickEvent();
    },
    clickEvent() {
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      //点击
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
          if (this.active == '') {
            return
          }
          this.editData.mark_lon = lon;
          this.editData.mark_lat = lat;
          this.drawTemp();
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    drawPoint() {
      BillboardCollection = new Cesium.BillboardCollection();
      viewer.scene.primitives.add(BillboardCollection);
      LabelCollection = new Cesium.LabelCollection();
      viewer.scene.primitives.add(LabelCollection);
    },
    chooseDev(index) {
      this.active = this.deviceList[index].mark_id;
      this.extData =  this.extInfo[this.active]?this.extInfo[this.active]:{
        "device_attribute_id": this.active,
        "device_dp_id": this.id,
        "device_attribute_data": ""
      }
      this.extData.device_attribute_data = this.extData.device_attribute_data?JSON.parse(this.extData.device_attribute_data):{
        gnmj:0,
        cnfs:'',
        xqmj:0,
        lldh:"",
        beng_type:"",
        video_num:1,
        videos:["","","",""]
      }
      this.editData = Object.assign({}, this.deviceList[index]);
      this.drawTemp(()=>{
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(this.editData.mark_lon, this.editData.mark_lat, this.editData.mark_height ? this.editData.mark_height+500 : 500)
        })
      });
    },
    //临时的点
    drawTemp(fun) {
      for (var key in LabelCollectionObj) {
        LabelCollectionObj[key].show = true;
        BillboardCollectionObj[key].show = true;
      }
      if (tempBill) {
        viewer.entities.remove(tempBill);
        tempBill = null;
      }
      if (this.editData.mark_lon && this.editData.mark_lat) {
        this.editData.mark_lon = this.editData.mark_lon?parseFloat(this.editData.mark_lon):this.editData.mark_lon;
        this.editData.mark_lat = this.editData.mark_lat?parseFloat(this.editData.mark_lat):this.editData.mark_lat;
        this.editData.mark_height = this.editData.mark_height?parseFloat(this.editData.mark_height):this.editData.mark_height;
        tempBill = new Cesium.Entity({
          id: "entity_" + this.editData.mark_id,
          position: Cesium.Cartesian3.fromDegrees(this.editData.mark_lon, this.editData.mark_lat, this.editData.mark_height ? this.editData.mark_height : 0),
          billboard: {
            width: 11,
            height: 11,
            image: this.dp?this.dp.device_model_data ? this.dp.device_model_data : require("../../assets/images/point.png"):require("../../assets/images/point.png"),
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            color: Cesium.Color.fromCssColorString("rgba(82,255,255,1)")
          },
          label: {
            text: this.editData.device_name,
            font: '14px sans-serif',
            color: Cesium.Color.fromCssColorString("rgba(82,255,255,1)"),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            pixelOffset: new Cesium.Cartesian2(0, -60)
          }
        });
        viewer.entities.add(tempBill);
       if(fun){
         fun()
       }
        if (LabelCollectionObj[this.editData.mark_id]) {
          LabelCollectionObj[this.editData.mark_id].show = false;
          BillboardCollectionObj[this.editData.mark_id].show = false;
        }
      }
    },
    save() {
      if (!this.active) {
        return
      }
      LabelCollection.remove(LabelCollectionObj[this.editData.mark_id]);
      BillboardCollection.remove(BillboardCollectionObj[this.editData.mark_id]);
      var data = JSON.parse(JSON.stringify(this.editData));
      data.mark_lat = data.mark_lat?data.mark_lat.toString():"";
      data.mark_lon = data.mark_lon?data.mark_lon.toString():"";
      data.mark_height = data.mark_height?data.mark_height.toString():"";

      var url = "/heating/custom/mark";
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("保存成功！");
          if (this.editData.mark_lon && this.editData.mark_lat) {
            var obj = this.setBillLabel(this.editData,"hide")
            LabelCollectionObj[this.editData.mark_id] = LabelCollection.add(obj.label)
            BillboardCollectionObj[this.editData.mark_id] = BillboardCollection.add(obj.bill)
          } else {
            delete BillboardCollectionObj[this.editData.mark_id]
            delete LabelCollectionObj[this.editData.mark_id]
          }
          this.getPointList(true);
        }
      }).catch((res)=>{
        console.log(res);
      });
      this.saveAttr();
    },
    saveAttr(){
      var url = "/heating/device-attribute";
      var data = JSON.parse(JSON.stringify(this.extData));
      data.device_attribute_id = this.active;
      data.device_dp_id = this.id;
      data.device_attribute_data = JSON.stringify(data.device_attribute_data);
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.getExtInfo()
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    setBillLabel(obj,status) {
      var bill = null;
      var label = null;
      bill = {
        show: status=='hide'?false:true,
        id: "bill_" + obj.mark_id,
        width: 11,
        height: 11,
        position: Cesium.Cartesian3.fromDegrees(obj.mark_lon, obj.mark_lat, obj.mark_height ? obj.mark_height : 0),
        image: this.dp?this.dp.device_model_data ? this.dp.device_model_data : require("../../assets/images/point.png"):require("../../assets/images/point.png"),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        //color: Cesium.Color.fromCssColorString("rgba(82,255,255,0.3)")
      }
      if (obj.icon) {
        delete bill.color
      }
      label = {
        show: status=='hide'?false:true,
        id: "label_" + obj.mark_id,
        text: obj.device_name,
        font: '14px sans-serif',
        position: Cesium.Cartesian3.fromDegrees(obj.mark_lon, obj.mark_lat, obj.mark_height ? obj.mark_height : 0),
        color: Cesium.Color.fromCssColorString("#ffffff"),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        pixelOffset: new Cesium.Cartesian2(0, -60)
      }
      return {bill, label}
    },
    clear() {
      this.editData.mark_lon = '';
      this.editData.mark_lat = '';
      this.editData.mark_height = 0;
      if (tempBill) {
        viewer.entities.remove(tempBill);
        tempBill = null;
      }
    },
    //输入经纬度高度
    setValue() {
      if (!this.active) {
        return
      }
      if(!this.$judgeNumber(this.editData.mark_lon)||!this.$judgeNumber(this.editData.mark_lat)||!this.$judgeNumber(this.editData.mark_height)){
        return false
      }
      this.drawTemp()
      this.$setView(viewer,this.editData.mark_lon,this.editData.mark_lon,this.editData.mark_height)
    },
    //获取设备列表
    getDeviceList(){
      var url = "/heating/zeiot/device/protocol/list?dp_id="+this.id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.deviceList = res.data;
          this.getPointList();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取标注信息
    getPointList(reset){
      var url = "/heating/custom/mark/info/all?mark_type=0&mark_source_id="+this.id;
      this.$axios.post(url).then((res)=>{
        this.pointList = res.data;
        this.deviceList.forEach((item)=>{
          var hasPoint = this.pointList.some((val)=>{
            if(item.device_id == val.mark_id){
              val.mark_lon = val.mark_lon?parseFloat(val.mark_lon):"";
              val.mark_lat = val.mark_lat?parseFloat(val.mark_lat):"";
              val.mark_height = val.mark_height?parseFloat(val.mark_height):0;
              item = Object.assign(item,val);
              return true
            }
          });
          if(!hasPoint){
            item = Object.assign(item,{
              "mark_id": item.device_id,
              "mark_source_id": this.id,
              "mark_type": "0",
              "mark_lon": "",
              "mark_lat": "",
              "mark_height": 0
            });
          }
          if (item.mark_lon && item.mark_lat) {
            if(!reset){
              var obj = this.setBillLabel(item);
              BillboardCollectionObj[item.mark_id] = BillboardCollection.add(obj.bill);
              LabelCollectionObj[item.mark_id] = LabelCollection.add(obj.label)
            }
          }
        });
      }).catch((res)=>{

      })
    },
    //获取物联网信息列表
    getDPList(){
      var url = "/heating/device-model/easy-info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.dp = null;
          res.data.some((item)=>{
            if(item.device_model_id == this.id){
              this.dp = item;
              return true
            }
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取设备扩展属性
    getExtInfo(){
      var url = "/heating/device-attribute/info?dp_id="+this.id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.extInfo = {}
          res.data.forEach((item)=>{
            this.extInfo[item.device_attribute_id] = item;
          })

        }
      }).catch((res)=>{
        console.log(res);
      })
    }

  }
}
</script>
<style>


</style>
<style scoped lang="less">
@import "../../assets/css/point.less";

.page {
  margin: 0 !important;
  width: 100%;
  height: 100%;
}


</style>