<template>
  <div class="screenPage">
    <div class="screenHeader">
      <div class="screen_tit">智慧供热系统</div>
      <div class="screen_header_btns">
<!--        <div class="screen_header_btn">
          <img src="../../assets/images/screen/tuichu.png">
          <span>退出</span>
        </div>-->
        <div class="screen_header_btn" @click="save">
          <img src="../../assets/images/screen/baocun.png">
          <span>保存</span>
        </div>
        <div class="screen_header_btn" @click="previewFun">
          <img src="../../assets/images/screen/yulan.png">
          <span>预览</span>
        </div>
      </div>

    </div>
    <div class="screenCont">
      <div :class='{"left_btn":true,"hideLeft":(hideLeft)}' @click="hideLeftFun">
        <i class="iconfont icon-youshuangjiantou" v-if="hideLeft"></i>
        <i class="iconfont icon-zuoshuangjiantou" v-else></i>
      </div>
      <div :class='{"right_btn":true,"hideLeft":(hideRight)}' @click="hideRightFun">
        <i class="iconfont icon-zuoshuangjiantou" v-if="hideRight"></i>
        <i class="iconfont icon-youshuangjiantou" v-else></i>
      </div>
      <div :class='{"screenLeft":true,"hideLeft":(hideLeft)}'>
        <div class="left_tit">大屏元件</div>
        <div class="left_cont">
          <div class="left_cont_item">
            <img src="../../assets/images/screen/zutai_.png">
            <div class="left_cont_item_tit droppable-element" @drag="drag" @dragend="dragend" draggable="true"
                 unselectable="on" data-type="组态">组态
            </div>
          </div>
          <div class="left_cont_item">
            <img src="../../assets/images/screen/shebei_.png">
            <div class="left_cont_item_tit droppable-element" @drag="drag" @dragend="dragend" draggable="true"
                 unselectable="on" data-type="天气">天气
            </div>
          </div>
          <div class="left_cont_item">
            <img src="../../assets/images/screen/shipin_.png">
            <div class="left_cont_item_tit droppable-element" @drag="drag" @dragend="dragend" draggable="true"
                 unselectable="on" data-type="视频">视频
            </div>
          </div>
          <div class="left_cont_item">
            <img src="../../assets/images/screen/baojing_.png">
            <div class="left_cont_item_tit droppable-element" @drag="drag" @dragend="dragend" draggable="true"
                 unselectable="on" data-type="报警">报警
            </div>
          </div>
        </div>
      </div>
      <div class="screenCenter" id="screenCenter" style="position: relative;height: 100%">
        <main-header :title="'智慧供热监控大屏'"></main-header>

        <div class="header_time" style="right: 3rem">
          <i class="iconfont icon-rili"></i>
          <span>{{time}}</span>
        </div>
        <div class="home_btns">
          <div class="def_btn_2" ><setting-outlined /> 系统管理</div>
          <div class="def_btn_2">返回</div>
        </div>

        <div class="left_btns">
          <div class="def_btn_1" ><left-outlined /><right-outlined />收起两侧</div>
          <div class="def_btn_1">
            <a-dropdown class="dark_">
              <div class="def_btn_1">
                <down-outlined />全部
              </div>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a href="javascript:;">全部</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
        <div class="viewer_full_cont" id="viewer-container" style="width: 100%;height: 100%"></div>
        <grid-layout
            ref="gridlayout"
            :layout.sync="layout"
            :col-num="col_num"
            :row-height="1"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[0, 0]"
            :use-css-transforms="false"
            :responsive="false"
            :preventCollision="false"
            class="grid-layout-cont">
<!--      ,"left":(item.align=="left"?(item.x+"px"):"unset"),"right":(item.align=="right"?((item.x)+"px"):"unset")    -->
<!--        ,"left":item.x+"px","right":item.x2+"px","top":item.y+"px","bottom":item.y2+"px"  -->
          <grid-item :style='{"pointer-events":"auto","z-index":1,"left":(item.align=="left"||item.align=="bottom"||item.align==""?(item.x+"px"):"unset"),"right":(item.align=="right"?((item.x2)+"px"):"unset"),"top":(item.align!="bottom"?(item.y+"px"):"unset"),"bottom":(item.align == "bottom"?(item.y2+"px"):"unset")}' :ref='"child_grid_item"+index'
                     v-for="(item,index) in layout"
                     :id="'child_grid_item'+index"
                     :x="item.x"
                     :y="item.y"
                     :w="item.w"
                     :h="item.h"
                     :i="item.i"
                     :key="item.i" class="item_list contCard" @click="clickFun2(index)" @move="(i,x,y)=>{return moveItem(i,x,y,index)}" >

            <span class="remove" @click.stop="removeItem(item.i)">x</span>
            <div class="contCard_tit"
                 :style='{"color":item.color,"font-size":item.size+"px","font-style":item.style,"font-family":item.family}'>
              {{ item.tit }}
            </div>
            <!-- 组态-->
            <div class="contCard_cont" v-if="item.type=='组态'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
              <iframe :src="ip2+item.itemData.monitor_src" style="width: 100%;height: 100%" frameborder="no"
                      scrolling="no"></iframe>
            </div>
            <!-- 天气-->
            <div class="contCard_cont" v-if="item.type=='天气'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
              <div class="tianqiCard">
                <div>2<small>℃</small></div>
                <div>
                  <i class="iconfont icon-shidu"></i><span>湿度</span><span>2%</span>
                </div>
              </div>
            </div>
            <!--  视频         -->
            <div class="contCard_cont" v-if="item.type=='视频'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
              <div class="video_card video_card1" v-if="item.itemData.video_type==1">
                <div class="video_c" style="width: 100%;height: 100%;">1</div>
              </div>
              <div class="video_card video_card2" v-if="item.itemData.video_type==2">
                <div class="video_c">1</div>
                <div class="video_c">2</div>
              </div>
              <div class="video_card video_card3" v-if="item.itemData.video_type==3">
                <div class="video_c">1</div>
                <div class="video_c2">
                  <div class="video_c">2</div>
                  <div class="video_c">3</div>
                </div>
              </div>
              <div class="video_card video_card4" v-if="item.itemData.video_type==4">
                <div class="video_c">1</div>
                <div class="video_c">2</div>
                <div class="video_c">3</div>
                <div class="video_c">4</div>
              </div>
              <div class="video_card video_card5" v-if="item.itemData.video_type==5">
                <div class="video_c2">
                  <div class="video_c">1</div>
                  <div class="video_c4">
                    <div class="video_c">2</div>
                    <div class="video_c">3</div>
                  </div>
                </div>
                <div class="video_c3">
                  <div class="video_c">4</div>
                  <div class="video_c">5</div>
                </div>
              </div>
              <div class="video_card video_card6" v-if="item.itemData.video_type==6">
                <div class="video_c2">
                  <div class="video_c">1</div>
                  <div class="video_c4">
                    <div class="video_c">2</div>
                    <div class="video_c">3</div>
                  </div>
                </div>
                <div class="video_c3">
                  <div class="video_c">4</div>
                  <div class="video_c">5</div>
                  <div class="video_c">5</div>
                </div>
              </div>
            </div>
            <!--  报警   -->
            <div class="contCard_cont" v-if="item.type=='报警'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
              <div class="alarm_card" >
                <div class="alarm_table_head">
                  <div>采集值</div>
                  <div>发生时间</div>
                </div>
                <div class="alarm_table_body">
                  <div class="alarm_table_tr">
                    <div>****</div>
                    <div>**</div>
                  </div>
                </div>
              </div>
            </div>
          </grid-item>
        </grid-layout>
      </div>
      <div :class='{"screenRight":true,"hideRight":(hideRight)}'>
        <div class="right_tit">{{ editData.type }}</div>
        <div class="right_cont">
          <div class="form_item">
            <span class="form_item_tit">对齐方式</span>
            <a-select ref="select" v-model:value="editData.align" style="flex: 1" @change="chooseAlign">
              <a-select-option value="left">左对齐</a-select-option>
              <a-select-option value="right">右对齐</a-select-option>
              <a-select-option value="bottom">下对齐</a-select-option>
              <a-select-option value="">自定义</a-select-option>
            </a-select>
          </div>
          <div class="form_item" v-if="editData.align=='left'">
            <span class="form_item_tit" style="margin-left: 24px" >距左</span>
            <a-input-number id="inputNumber" v-model:value="editData.x" placeholder="0" style="width: 64px" :min="0" @input="setInput('x')"/>
            <span class="form_item_tit " style="margin-left: 30px" >Y</span>
            <a-input-number id="inputNumber" v-model:value="editData.y" placeholder="0" style="width: 64px" :min="0" @input="setInput('y')"/>
          </div>
          <div class="form_item" v-if="editData.align=='right'">
            <span class="form_item_tit" style="margin-left: 24px" >距右</span>
            <a-input-number id="inputNumber" v-model:value="editData.x2" placeholder="0" style="width: 64px" :min="0" @input="setInput('x2')" @change="setInput('x2')"/>
            <span class="form_item_tit " style="margin-left: 30px"  >Y</span>
            <a-input-number id="inputNumber" v-model:value="editData.y" placeholder="0" style="width: 64px" :min="0" @input="setInput('y')" @change="setInput('y')"/>
          </div>
          <div class="form_item" v-if="editData.align=='bottom'">
            <span class="form_item_tit" style="margin-left: 44px" >X</span>
            <a-input-number id="inputNumber" v-model:value="editData.x" placeholder="0" style="width: 64px" :min="0" @input="setInput('x')"/>
            <span class="form_item_tit m_l_1" >距底</span>
            <a-input-number id="inputNumber" v-model:value="editData.y2" placeholder="0" style="width: 64px" :min="0" @input="setInput('y2')"/>
          </div>
          <div class="form_item" v-if="editData.align==''">
            <span class="form_item_tit" style="margin-left: 44px" >X</span>
            <a-input-number id="inputNumber" v-model:value="editData.x" placeholder="0" style="width: 64px" :min="0" @input="setInput('x')"/>
            <span class="form_item_tit " style="margin-left: 30px"  >Y</span>
            <a-input-number id="inputNumber" v-model:value="editData.y" placeholder="0" style="width: 64px" :min="0" @input="setInput('y')"/>
          </div>
          <div class="form_item">
            <span class="form_item_tit" style="margin-left: 38px">宽</span>
            <a-input-number id="inputNumber" v-model:value="editData.w" placeholder="0" style="width: 64px" :min="0"/>
            <span class="form_item_tit " style="margin-left: 24px">高</span>
            <a-input-number id="inputNumber" v-model:value="editData.h" placeholder="0" style="width: 64px" :min="0"/>
          </div>
          <div class="form_item">
            <span class="form_item_tit">标题内容</span>
            <a-input v-model:value="editData.tit" placeholder="标题内容" style="flex: 1"/>
          </div>
          <div class="form_item">
            <span class="form_item_tit">标题字体</span>
            <a-select ref="select" v-model:value="editData.family" style="flex: 1">
              <a-select-option value="黑体" style="font-family: '黑体'">黑体</a-select-option>
              <a-select-option value="仿宋" style="font-family: '仿宋'">仿宋</a-select-option>
              <a-select-option value="楷体" style="font-family: '楷体'">楷体</a-select-option>
              <a-select-option value="标楷体" style="font-family: '标楷体'">标楷体</a-select-option>
              <a-select-option value="华文仿宋" style="font-family: '华文仿宋'">华文仿宋</a-select-option>
              <a-select-option value="华文楷体" style="font-family: '华文楷体'">华文楷体</a-select-option>
              <a-select-option value="宋体" style="font-family: '宋体'">宋体</a-select-option>
              <a-select-option value="微软雅黑" style="font-family: '微软雅黑'">微软雅黑</a-select-option>
              <a-select-option value="Arial" style="font-family: 'Arial'">Arial</a-select-option>
              <a-select-option value="Tahoma" style="font-family: 'Tahoma'">Tahoma</a-select-option>
              <a-select-option value="Verdana" style="font-family: 'Verdana'">Verdana</a-select-option>
              <a-select-option value="Times New Roman" style="font-family: 'Times New Roman'">Times New Roman</a-select-option>
              <a-select-option value="Courier New" style="font-family: 'Courier New'">Courier New</a-select-option>
            </a-select>
          </div>
          <div class="form_item">
            <colorpicker :color="editData.color" @getPickerColor="setColor" style="margin-left: 36px"></colorpicker>
            <a-select ref="select" v-model:value="editData.size" style="width: 64px" class="m_l_1">
              <a-select-option value="12">12</a-select-option>
              <a-select-option value="13">13</a-select-option>
              <a-select-option value="14">14</a-select-option>
              <a-select-option value="16">16</a-select-option>
              <a-select-option value="18">18</a-select-option>
              <a-select-option value="20">20</a-select-option>
              <a-select-option value="22">22</a-select-option>
            </a-select>
            <a-select ref="select" v-model:value="editData.style" style="width: 98px" class="m_l_1">
              <a-select-option value="normal">正常</a-select-option>
<!--              <a-select-option value="lucy">加粗</a-select-option>-->
              <a-select-option value="italic">斜体</a-select-option>
            </a-select>
          </div>
          <div class="form_item">
            <span class="form_item_tit">背景颜色</span>
            <colorpicker :color="editData.bgcolor" @getPickerColor="setColor2"></colorpicker>
          </div>
          <div class="formItem" v-if="editData.type=='组态'">
            <div class="form_item">
              <span class="form_item_tit">组态列表</span>
              <a-select style="flex: 1;overflow: hidden" v-model:value="editData.itemData.monitor_id" show-search placeholder="选择"
                        :options="monitorList" :filter-option="filterMonitor"
                        :fieldNames="{'label':'monitor_name','value':'monitor_id'}" @change="chooseMonitor"></a-select>
            </div>
          </div>
          <div class="formItem" v-if="editData.type=='天气'">
            <div class="form_item">
              <span class="form_item_tit">型号列表</span>
              <a-select
                  v-model:value="editData.itemData.dp_id"
                  show-search
                  placeholder="选择"
                  style="flex: 1;overflow: hidden"
                  :options="dpList"
                  :field-names="{'label':'dp_name','value':'dp_id'}"
                  :filter-option="filterOption1"
                  @change="chooseDP"
              ></a-select>
            </div>
            <div class="form_item">
              <span class="form_item_tit">设备列表</span>
              <a-select
                  v-model:value="editData.itemData.dev_dev"
                  show-search
                  placeholder="选择"
                  style="flex: 1;overflow: hidden"
                  :options="devList"
                  :field-names="{'label':'device_name','value':'device_id'}"
                  :filter-option="filterOption2"
              ></a-select>
            </div>
          </div>
          <div class="formItem" v-if="editData.type=='视频'">
            <div class="form_item">
              <span class="form_item_tit">展示数量</span>
              <a-select ref="select" v-model:value="editData.itemData.video_type" style="flex: 1" >
                <a-select-option value="1">1</a-select-option>
                <a-select-option value="2">2</a-select-option>
                <a-select-option value="3">3</a-select-option>
                <a-select-option value="4">4</a-select-option>
              </a-select>
            </div>
            <div class="form_item">
              <span class="form_item_tit">视频列表</span>
              <div style="flex: 1">
                <div class="form_item" v-for="count in parseFloat(editData.itemData.video_type)" :key="count">
                  <span class="form_item_tit">{{ count }}</span>
                  <a-input v-model:value="editData.itemData.videoList[count-1]" placeholder="视频地址" style="flex: 1"/>
                </div>
              </div>
            </div>
          </div>
          <div class="formItem" v-if="editData.type=='报警'">
            <div class="form_item">
              <span class="form_item_tit">报警类型</span>
              <a-select ref="select" v-model:value="editData.itemData.alarm_id" style="flex: 1;overflow: hidden" show-search :options="alarmType" :fieldNames='{"label":"alarm_name","value":"alarm_id"}' :filter-option="filterAlarm" ></a-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {ref, unref, getCurrentInstance} from 'vue'
import $ from "jquery"
import SkyBoxOnGround from "@/until/SkyBoxOnGround";
import MainHeader from "@/components/mainHeader";

var Cesium = require("cesium/Cesium");
var viewer = null;
var handler = null;
let mouseXY = {"x": null, "y": null};
let DragPos = {"x": null, "y": null, "w": 1, "h": 1, "i": null};
var imgEntity = null;
var area_pos = [];

export default {
  name: "index_",
  components: {MainHeader},
  data() {
    return {
      layout: [],
      now_index: "",
      editData: {
        type: "组态",
        x: "",
        x2: "",
        y: "",
        y2: "",
        w: "",
        h: "",
        tit: "",
        align:"left",
        family: "微软雅黑",
        color: "#ffffff",
        size: "12",
        style: "normal",
        bgcolor: "rgba(0,0,0,0)",
        bg_img:true,
        opacity: 100,
        itemData: {
          monitor_id: "",
          monitor_src: "",
          dev_type: "card",
          dev_dev:"",
          dp_id:"",
          video_type:1,
          videoList:["","","","","",""],
          article_src:"",
          article_type:"0",
          article_id:"",
          entity_id:"",
          entity_cols:[],
          entity_cols_d:[],
          tjList:[],
          alarm_id:""
        }
      },
      col_num: 1920,
      col_y:1080,
      myData: [],
      type: "",
      ip: "",
      id: "",
      monitorList: [],//监控点列表
      loading: false,
      cols:[],
      tjList:[],
      hideLeft:false,
      hideRight:false,
      alarmType:[],//报警类型列表
      screen_data:{},
      screen_id:"",
      devList:[],//天气设备列表
      dpList:[],//型号列表
      time:"",
      ip2:""

    }
  },
  beforeUnmount(){
    if(viewer){
      this.$clearWebgl(viewer);
    }
  },
  mounted() {
    this.time = this.$getTime(new Date()).t3;
    setTimeout(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.ip = this.$axios.defaults.baseURL;
    this.ip2 = this.$axios_ip.defaults.baseURL;
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.col_num = $("#screenCenter").width();
    this.col_y = $("#screenCenter").height();
    this.id = this.$route.query.key;
    document.addEventListener("dragover", function (e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);
    this.getMonitor()
    this.getDPList()
    this.initMap();
    this.loadConfig();
    this.getAlarmType();
    /*this.getMonitor();
    this.getAlarmType();*/
    var _this = this;
    window.onresize=function(){
      _this.col_num = $("#screenCenter").width();
      _this.col_y = $("#screenCenter").height();
    }

  },
  methods: {
    initMap(fun) {
      viewer = new Cesium.Viewer("viewer-container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        shouldAnimate: true,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });
      viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
        url: " http://webst01.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}",
        minimumLevel: 1,
        maximumLevel: 18
      }));
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      });
      this.$loadGaoDe(viewer);
    },
    drag: function (e) {
      this.type = $(e.target).data("type")
      let parentRect = document.getElementById('screenCenter').getBoundingClientRect();
      let mouseInGrid = false;
      //console.log(mouseXY.x,parentRect.left,parentRect.right)
      //console.log(mouseXY.y,parentRect.top,parentRect.bottom)
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      //console.log("oooooooooo",mouseInGrid)
      if (mouseInGrid === true && (this.layout.findIndex(item => item.i === 'drop')) === -1) {
        this.layout.push({
          x: (this.layout.length * 2) % (this.col_num || 12),
          x2: this.col_num - (this.layout.length * 2) % (this.col_num || 12) - 300,
          y: this.layout.length + (this.col_num || 12), // puts it at the bottom
          y2: this.col_y - this.layout.length + (this.col_num || 12) - 200, // puts it at the bottom
          w: 300,
          h: 200,
          i: 'drop',
          tit: "",
          align:"left",
          family: "微软雅黑",
          color: "#11EEE1",
          size: "12",
          style: "normal",
          bgcolor: "rgba(31,48,53,0.8)",
          opacity: 100,
          type: this.type,
          itemData: {
            monitor_id: "",
            monitor_src: "",
            dev_type: "card",
            dev_dev:"",
            dp_id:"",
            video_type:1,
            videoList:["","","","","",""],
            article_src:"",
            article_type:"0",
            article_id:"",
            entity_id:"",
            entity_cols:[],
            entity_cols_d:[],
            tjList:[],
            alarm_id:""
          }
        });
      }
      let index = this.layout.findIndex(item => item.i === 'drop');
      if (index !== -1) {
        try {

          this.$refs["child_grid_item" + index].style.display = "none";
        } catch {
        }
        let el = this.$refs["child_grid_item" + index];
        if (el && el.length) {
          el["0"].dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
          let new_pos = el[0].calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

          if (mouseInGrid === true) {
            this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 200, 300);
            DragPos.i = String(index);
            DragPos.x = this.layout[index].x;
            DragPos.y = this.layout[index].y;
          }
          if (mouseInGrid === false) {
            this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 200, 300);
            this.layout = this.layout.filter(obj => obj.i !== 'drop');
          }
        }

      }
    },
    dragend: function (e) {
      let parentRect = document.getElementById('screenCenter').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        //alert(`Dropped element props:\n${JSON.stringify(DragPos, ['x', 'y', 'w', 'h'], 2)}`);
        this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 200, 300);
        this.layout = this.layout.filter(obj => obj.i !== 'drop');
        this.layout.push({
          x: DragPos.x,
          x2: this.col_num - DragPos.x - 300,
          y: DragPos.y,
          y2: this.col_y - DragPos.y - 200,
          w: 300,
          h: 200,
          i: DragPos.i,
          tit: "",
          align:"left",
          family: "微软雅黑",
          color: "#ffffff",
          size: "12",
          style: "normal",
          bgcolor: "rgba(0,0,0,0)",
          bg_img: true,
          opacity: 100,
          type: this.type,
          itemData: {
            monitor_id: "",
            monitor_src: "",
            dev_type: "card",
            dev_dev:"",
            dp_id:"",
            video_type:1,
            videoList:["","","","","",""],
            article_src:"",
            article_type:"0",
            article_id:"",
            entity_id:"",
            entity_cols:[],
            entity_cols_d:[],
            tjList:[],
            alarm_id:""
          }
        });
        this.now_index = this.layout.length - 1;
        this.editData = this.layout[this.now_index];
      }
    },
    clickFun2(index) {
      var old_index = this.now_index;

      if(old_index === index){
        return false
      }
      this.now_index = index;
      this.editData = this.layout[index];
      if(this.editData.type=="天气"){
        if(this.editData.itemData.dp_id){
          this.getDeviceList();
        }
      }
    },
    //删除
    removeItem: function (val) {
      const index = this.layout.map(item => item.i).indexOf(val);
      this.layout.splice(index, 1);
    },
    //字体颜色
    setColor(e) {
      this.editData.color = e;
    },
    //背景颜色
    setColor2(e) {
      this.editData.bgcolor = e;
    },
    //隐藏/显示左侧
    hideLeftFun(){
      this.hideLeft = !this.hideLeft;
      setTimeout(()=>{
        this.col_num = $("#screenCenter").width();
        this.col_y = $("#screenCenter").height();
        this.arrangeL_R()
      },220)
    },
    //隐藏/显示右侧
    hideRightFun(){
      this.hideRight = !this.hideRight;
      setTimeout(()=>{
        this.col_num = $("#screenCenter").width();
        this.col_y = $("#screenCenter").height();
        this.arrangeL_R()
      },220)
    },
    /*
    * ===================== 大屏信息 =========================
    * */
    //基础配置
    loadConfig(){
      var url = "/heating/screen-sttting/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          if(JSON.stringify(res.data) == "[]"){
            this.screen_data = res.data.screen_data?JSON.parse(res.data.screen_data):{
              img_src:"",
              area_pos:[],
              baseColor:"rgba(0,0,0,1)",
              angle:"",
              layout:[]
            };
          }else{
            this.screen_id = res.data.screen_id?res.data.screen_id:""
            this.screen_data = res.data.screen_data?JSON.parse(res.data.screen_data):{
              img_src:"",
              area_pos:[],
              baseColor:"rgba(0,0,0,1)",
              angle:"",
              layout:[]
            };
          }
          this.layout = this.screen_data.layout?this.screen_data.layout:[];
          this.layout.forEach((item,index)=>{
            item.i = index
          })
          if(this.now_index!==""){
            this.editData = this.layout[this.now_index];
          }
          area_pos = this.screen_data.area_pos
          this.drawImgEntity();
        }
      }).catch((res)=>{
        console.log(res)
      })
    },
    //图片地图
    drawImgEntity(){
      if(area_pos.length>2){
        //var center = this.$getCenter(area_pos);
        imgEntity = new Cesium.Primitive({
          geometryInstances:new Cesium.GeometryInstance({
            geometry:new Cesium.PolygonGeometry({
              polygonHierarchy:new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(area_pos.flat()))
            })
          }),
          appearance:new Cesium.MaterialAppearance({
            material:this.screen_data.img_src?new Cesium.Material.fromType("Image",{
              "image":this.screen_data.img_src
            }):new Cesium.Material.fromType("Color",{
              "color":Cesium.Color.fromCssColorString("rgba(0,255,0,0.6)")
            })
          })
        });
        viewer.scene.primitives.add(imgEntity)
        this.toCenter()
        this.hideGlobe();
      }
    },
    toCenter(){
      for(var key in this.screen_data.angle){
        this.screen_data.angle[key] = parseFloat(this.screen_data.angle[key])
      }
      viewer.camera.setView({
        destination:Cesium.Cartesian3.fromDegrees(this.screen_data.angle.lon,this.screen_data.angle.lat,this.screen_data.angle.h),
        orientation: {
          heading: this.screen_data.angle.head===""?0:this.screen_data.angle.head,
          pitch: this.screen_data.angle.pitch===""?0:this.screen_data.angle.pitch,
          roll: this.screen_data.angle.roll===""?0:this.screen_data.angle.roll
        }
      })
    },
    //保存
    save(){
      var screen_data = JSON.parse(JSON.stringify(this.screen_data));
      screen_data.layout = this.layout;
      var data = {
        screen_id:this.screen_id,
        screen_data: JSON.stringify(screen_data)
      }
      var url = "/heating/screen-sttting";
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("保存成功！");
          this.loadConfig();
        }

      }).catch((res)=>{
        console.log(res);
      })
    },
    //整理layout数据
    arrangeLayout(){
     /* var
      this.layout.forEach((item)=>{
        item.dev_dev.forEach((val)=>{

        })
      })*/
    },
    hideGlobe(){
      viewer.imageryLayers.removeAll();
      viewer.scene.globe.baseColor =Cesium.Color.fromCssColorString(this.screen_data.baseColor);
      viewer.scene.globe.showGroundAtmosphere = false;
      viewer.scene.globe.translucency.enabled = true;
      viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0);
      viewer.scene.globe.undergroundColor = undefined;
      viewer.scene.skyAtmosphere.show = true; //是否隐藏大气圈
    },
    /*
    * ===================  组态=========================
    * */
    //获取组态列表
    getMonitor() {
      var url = "/heating/zeiot/monitor-options";
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.monitorList = res.data;
        }
      }).catch((res) => {
        console.log(res)
      })
    },
    filterMonitor(e, option) {
      return option.monitor_name.includes(e)
    },
    //选择监控点
    chooseMonitor() {
      this.editData.itemData.monitor_src =  '/template/project/monitoring-list/simulated_scene.html?monitor_id=' + this.editData.itemData.monitor_id + '&salt=' + this.$cookie.get("user_salt")
    },
    /*
    * ============================== 报警================================
    * */
    //获取报警类型列表
    getAlarmType(){
      var url = "/api/user/alarms-options/cascade";
      this.$getZeiotApi(url,"get","",(res)=>{
        this.alarmType = res.data;
      })
    },
    //
    filterAlarm(e,option){
      return option.alarm_name.includes(e);
    },
    /*
    * =====================  天气 =====================
    * */
    getDPList(){
      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.dpList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterOption1(e,option){
      return option.dp_name.includes(e)
    },
    //选择型号
    chooseDP(){
      this.editData.itemData.dev_dev = "";
      this.getDeviceList();
    },
    getDeviceList(){
      var url = "/heating/zeiot/device/protocol/list?dp_id="+this.editData.itemData.dp_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.devList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterOption2(e,option){
      return option.device_name.includes(e)
    },
    /*
    * =======================   移动 item ====================
    * */
    moveItem(i,newX,newY,INDEX){
      //console.log("i",i,newX,newY,this.editData.x,this.editData.y,INDEX,this.now_index);
      //console.log("i2",this.col_y,this.editData.h);
      if(this.now_index!==INDEX){
        this.clickFun2(INDEX)
      }
      this.col_num = $("#screenCenter").width();
      this.col_y = $("#screenCenter").height();
      this.editData.x2 = this.col_num - newX - this.editData.w;
      this.editData.y2 = this.col_y - newY - this.editData.h;
    },
    setInput(key){
      if(key=="x"){
       var x2 = this.col_num - this.editData.x - this.editData.w;
       if(x2<=0){
         this.editData.x2 = 0;
         this.editData.x = this.col_num - this.editData.w;
       }else{
         this.editData.x2 =x2;
       }
      }
      if(key=="x2"){
       var x = this.col_num - this.editData.x2 - this.editData.w;
       if(x<=0){
         this.editData.x = 0;
         this.editData.x2 = this.col_num - this.editData.w;
       }else{
         this.editData.x =x;
       }
      }
      if(key=="y"){
        var y2 = this.col_y - this.editData.y - this.editData.h;
        if(y2<=0){
          this.editData.y2 = 0;
          this.editData.y = this.col_y - this.editData.h;
        }else{
          this.editData.y2 =y2;
        }
      }
      if(key=="y2"){
        var y = this.col_y - this.editData.y2 - this.editData.h;
        if(y<=0){
          this.editData.y = 0;
          this.editData.y2 = this.col_y - this.editData.h;
        }else{
          this.editData.y =y;
        }
      }
    },
    //预览
    previewFun(){
      var url = this.$router.resolve({
        name:"preview",
        query:{
          key:this.id
        }
      });
      window.open(url.href,"_blank")
    },
    chooseAlign(){
      //this.arrangeL_R()
    },
    arrangeL_R(){
      this.layout.forEach((item)=>{
        if(item.align == "left"||item.align == "bottom"||item.align == ""){
          var x2 = this.col_num - item.w - item.x;
          if(x2<=0){
            item.x2 = 0;
            item.x = this.col_num - item.w
          }else{
            item.x2 = x2;
          }
        }
        if(item.align == "right"){
          var x = this.col_num - item.w - item.x2;
          if(x<=0){
            item.x = 0;
            item.x2 = this.col_num -item.w;
          }else{
            item.x = x;
          }
        }
        if(item.align == "left"||item.align == "right"||item.align == ""){
          var y = this.col_y - item.h - item.y2;
          if(y<=0){
            item.y = 0;
            item.y2 = this.col_y - item.h;
          }else{
            item.y = y;
          }
        }
        if(item.align == "bottom"){
          var y2 = this.col_y - item.h - item.y;
          if(y2<=0){
            item.y2 = 0;
            item.y = this.col_y - item.h;
          }else{
            item.y2 = y2;
          }
        }
      })
    },
  }
}

</script>
<style>
.screenRight .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(41, 42, 46, 1) !important;
  color: #ffffff !important;
  border-color: rgba(34, 34, 35, 1) !important;
  height: 26px !important;
}

.screenRight .ant-select-arrow {
  color: #ffffff !important;
}

.screenRight .ant-select-single .ant-select-selector .ant-select-selection-item, .screenRight .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 24px !important;
}

.screenRight .ant-input,.screenRight .ant-input-number,.screenRight .ant-input-number-input {
  background-color: rgba(41, 42, 46, 1) !important;
  color: #ffffff !important;
  border-color: rgba(34, 34, 35, 1) !important;
  height: 26px !important;
}

.screenRight .ant-upload.ant-upload-select-picture-card, .screenRight .ant-upload-picture-card-wrapper {
  width: 32px;
  height: 32px;
}

.screenRight .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
}
.screenRight .ant-select-multiple .ant-select-selection-item{
  background-color: rgba(0,0,0,0.4);
  border-color: transparent;
}
.screenRight .ant-select-multiple .ant-select-selection-item-remove{
 color: #ffffff;
}
</style>

<style scoped lang="less">
@import "../../assets/css/default.less";
@import "../../assets/css/screen.less";

</style>