<template>
  <div class="valvePage housePage">
    <valve-header :title="title"></valve-header>
    <div class="valveHomeBtn" @click="valveHomeBtn">返回</div>
    <div class="valveHeaderRight">
      <div class="weather">
        <iframe width="210" scrolling="no" height="25" frameborder="0" allowtransparency="true" src="https://i.tianqi.com?c=code&id=26&color=%23FFFFFF&icon=1&site=12"></iframe>
      </div>
      <div class="time" style="color: #57adfb;">
        <div>{{ time1 }}</div>
        <div>{{ time2 }}</div>
      </div>
    </div>
    <div class="valveContainer">
      <div class="valveLeft">
        <div style="font-size: 0.2rem;padding: 0.1rem 0.1rem;color: #57adfb;">{{ xiaoqu_name }}-{{ ld_name }}</div>
        <div>
          <a-select ref="select"   style="width: calc(100% - 0.2rem);margin-left: 0.1rem" v-model:value="unit" @change="chooseUnit"  >
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="item.unit_id" v-for="(item,index) in unitList" :key="index">{{ item.unit_name }}</a-select-option>
          </a-select>
        </div>
        <div style="padding: 0.2rem 0.2rem;font-size: 0.2rem">
          <div style="font-size: 0.28rem;color: #57adfb;">共<span class="org">{{ hf_total }}</span>户</div>

          <div style="color: #57adfb;padding:0.06rem 0">
            <div style="font-size:0.2rem"><span>平均室温</span><span class="org">{{sw_avg}}℃</span></div>
            <div style="font-size:0.2rem"><span>平均回温</span><span class="org">{{hw_avg}}℃</span></div>
          </div>
          <div style="color: #57adfb;">低于18℃<span class="green">{{ hf_18 }}户</span> 占比：<span class="green">{{ hf_18==0||hf_total==0?0:(hf_18*100/hf_total).toFixed(1) }}%</span></div>
          <div style="color: #57adfb;">18~24℃<span class="org">{{ hf_24 }}户</span> 占比：<span class="org">{{ hf_24==0||hf_total==0?0:(hf_24*100/hf_total).toFixed(1) }}%</span></div>
          <div style="color: #57adfb;">高于24℃<span class="red">{{ hf_25 }}户</span> 占比：<span class="red">{{ hf_25==0||hf_total==0?0:(hf_25*100/hf_total).toFixed(1) }}%</span></div>
        </div>
      </div>
      <div class="valveRight" style="display: flex">
        <div class="unitList" v-for="(item,index) in unitList" v-show="item.unit_id.includes(unit)" :key="index">
          <div class="unit_tit" style="color: #57adfb;">{{ item.unit_name }}</div>
          <div class="houseList" v-for="(val,key) in item.lcList" :key="key">
            <div class="houseItem" v-for="(v,k) in val.houseList" :key="k" @click="showModalFun(index,key,k)">
              <template v-if="v.realData" >
                <div class="house_t">
                  <span>{{ v.houses_name }}</span>
                  <span :style='{"color":(v.realData.indoor.indoor<18?"#14e4f9":(v.realData.indoor.indoor>24?"#e84328":"#0aca33"))}'>{{ v.realData.indoor.indoor }}℃</span>
                </div>
                <div class="house_b">
                  <span >{{ v.realData.valve.backwater }}℃</span>
                  <span>{{ v.realData.valve.opening_degree }}%</span>
                  <div class="per_l" :style='{"width":(v.realData.valve.opening_degree?v.realData.valve.opening_degree:0)+"%"}'></div>
                </div>
              </template>
              <template v-else>
                <div class="house_t">
                  <span>{{ v.houses_name }}</span>
                  <span>--℃</span>
                </div>
                <div class="house_b">
                  <span>--℃</span>
                  <span>0%</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class='{"web_modal":true, "bg":true,"show_m":(showModal)}'>
      <div class="modal_content">
        <div class="modal_top">
          <p class="title">{{info.unit_name}}{{info.houses_name!='--'?'-'+info.houses_name:''}}</p>
          <close-outlined class="img" @click="closeModal" style="color: #57adfb;" />
        </div>
        <div class="modal_split_line"></div>
        <div class="modal_cont">
          <div class="person_info" v-if="info.realData">
            <div class="info">
              <div style="padding: 4px 0;color: #57adfb;">
                <span>室温卡号：{{info.realData.indoor.code}}</span>
                <span style="padding-left: 30px;float: right" >{{info.realData.indoor.time?(info.realData.indoor.time.replace("T"," ")).split(".")[0]:''}}</span>
              </div>
              <div style="padding: 4px 0;color: #57adfb;">
                <span>户阀卡号：{{info.realData.valve.code}}</span>
              </div>
              <div style="padding: 4px 0;color: #57adfb;">
                <span>回水温度：{{info.realData.valve.backwater}}℃</span>
                <span style="padding-left: 30px;float: right">{{info.realData.valve.time?(info.realData.valve.time.replace("T"," ")).split(".")[0]:''}}</span>
              </div>
              <div style="padding: 4px 0;color: #57adfb;">
                <span>阀门开度：</span>
              </div>
              <div>
                            <textarea
                                style=" width: 100%; height: 40px; background-color: transparent; border: 1px solid #0358ba;color: #57adfb;"
                                placeholder="输入阀门开度值" v-model="level_set" @keyup="verVal">

                            </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal_bottom">
          <input type="button" value="提交" class="btn-def " @click="sendCommand">
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: "house",
  data(){
   return {
     title:"户阀管理系统",
     unit:"",
     xiaoqu:"",
     xiaoqu_name:"",
     ld:"",
     ld_name:"",
     unitList:[],
     hfList:[],
     time1:"",
     time2:"",
     realData:{},
     hf_total:0,
     hf_18:0,
     hf_24:0,
     hf_25:0,
     showModal:false,
     info:{

     },
     level_set:"",
     timer:null,
     user_level:"",
     hw_avg:0,//回温平均值
     sw_avg:0,//室温平均值
   }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.user_level = this.$cookie.get("user_level");
    this.xiaoqu_name = this.$route.query.community_name;
    this.ld = this.$route.query.building_id;
    this.ld_name = this.$route.query.building_name;
    var time = this.$getTime(new Date());
    this.time1 = time.d2;
    this.time2 = time.week+" "+time.t2;
    setInterval(()=>{
      time = this.$getTime(new Date());
      this.time1 = time.d2;
      this.time2 = time.week+" "+time.t2;
    },1000)
    this.getHFList()
  },
  methods:{
    //获取单元列表
    getUnitList(){
      var url = "/heating/unit/info/page";
      this.$axios.post(url,{
        "keyword": "",
        "pageSize": 99,
        "pageNo": 1,
        "unit_building_id": this.ld
      }).then((res)=>{
        if(res.status == 200){
          this.unitList = res.data.data;
          this.unitList.forEach((item,index)=>{
            item.lcList = [];
            this.getLCList(item.unit_id,index)
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取户阀列表
    getHFList(){
      var url = "/heating/device-housesvalve/info";
      this.$axios.post(url,{
        "keyword": "",
        "zone_id": "",
        "community_id": this.$route.query.community_id,
        "building_id": this.ld,
        "unit_id": this.unit
      }).then((res)=>{
        if(res.status == 200){
          this.hfList = res.data;
          var hf_ids = [];
          this.hfList.forEach((item)=>{
            hf_ids.push(item.housesvalve_id)
          })
          this.getRealData(hf_ids);
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取楼层列表
    getLCList(unit_id,INDEX){
      var url ="/heating/storey/info?storey_unit_id="+unit_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.unitList[INDEX].lcList = res.data;
          this.unitList[INDEX].lcList.reverse()
          this.unitList[INDEX].lcList.forEach((item,index)=>{
            item.houseList = [];
            this.getHouseList(item.storey_id,INDEX,index);
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取房屋列表
    getHouseList(lc_id,INDEX,KEY){
      var url ="/heating/houses/info?houses_storey_id="+lc_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          var data = res.data;
          data.forEach((item,index)=>{
            this.hfList.some((val)=>{
              if(val.housesvalve_houses_id == item.houses_id){
                item = Object.assign(item,val);
                return true
              }
            })
          })
          this.unitList[INDEX].lcList[KEY].houseList = data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    valveHomeBtn(){
      this.$router.back()

    },
    //获取户阀实时数据
    getRealData(house_ids){
      var url = "/heating/device-housesvalve/read-time";
      var data = house_ids;
      this.$axios.post(url,house_ids).then((res)=>{
        if(res.status == 200){
          this.realData = res.data;
          this.hf_total = this.hfList.length;
          this.hf_18 = 0;
          this.hf_24 = 0;
          this.hf_25 = 0;
          var sw = 0;
          var hw = 0;
          this.hfList.forEach((item)=>{
            if(this.realData[item.housesvalve_id]){
              item = Object.assign(item,{realData:this.realData[item.housesvalve_id]})
              sw = sw+this.realData[item.housesvalve_id].indoor.indoor;
              hw = hw+this.realData[item.housesvalve_id].valve.backwater;
              if(this.realData[item.housesvalve_id].indoor.indoor<18){
                this.hf_18 = this.hf_18+1;
              }
              if(this.realData[item.housesvalve_id].indoor.indoor>=18&&this.realData[item.housesvalve_id].indoor.indoor<=24){
                this.hf_24 = this.hf_24+1;
              }
              if(this.realData[item.housesvalve_id].indoor.indoor>24){
                this.hf_25 = this.hf_25+1;
              }
            }
          })
          this.sw_avg = sw&&this.hf_total?(sw/this.hf_total).toFixed(1):0;
          this.hw_avg = sw&&this.hf_total?(hw/this.hf_total).toFixed(1):0;
        }
        this.getUnitList();
        clearInterval(this.timer);
        this.timer = setInterval(()=>{
          this.getRealFun(house_ids)
        },30000)
      }).catch((res)=>{
        console.log(res);
        this.getUnitList();
      })
    },
    //选择单元
    chooseUnit(){
      this.hf_total = 0;
      this.hf_18 = 0;
      this.hf_24 = 0;
      this.hf_25 = 0;
      var sw = 0;
      var hw = 0;
      this.unitList.forEach((item)=>{
        if(item.unit_id.includes(this.unit)){
          item.lcList.forEach((val)=>{
            val.houseList.forEach((v)=>{
              if(v.housesvalve_id){
                this.hf_total = this.hf_total+1;
              }
              if(v.realData){
                sw = sw+v.realData.indoor.indoor;
                hw = hw+v.realData.valve.backwater;
               if(v.realData.indoor.indoor>18){
                 this.hf_18 = this.hf_18+1;
               }
               if(v.realData.indoor.indoor>=18&&v.realData.indoor.indoor<=24){
                 this.hf_24 = this.hf_24+1;
               }
                if(v.realData.indoor.indoor>24){
                  this.hf_25 = this.hf_25+1;
                }
              }
            })
          })
        }
      })
      this.sw_avg = sw&&this.hf_total?(sw/this.hf_total).toFixed(1):0;
      this.hw_avg = sw&&this.hf_total?(hw/this.hf_total).toFixed(1):0;
    },
    //显示下发弹窗
    showModalFun(INDEX,KEY,K){
      if(this.user_level>1){
        return this.$showWarmTip("无操作权限！");
      }
      this.info = this.unitList[INDEX].lcList[KEY].houseList[K];
      if(!this.info.realData){
        return false
      }
      this.showModal = true;
      if(this.info.realData){
        this.info.realData.indoor.time =this.info.realData.indoor.time?this.$getTime(this.info.realData.indoor.time).t1:"";
        this.info.realData.valve.time =this.info.realData.valve.time?this.$getTime(this.info.realData.valve.time).t1:"";
      }
    },
    closeModal(){
      this.showModal = false;
    },
    verVal(){

    },
    sendCommand(){
      if(!/^(?:[1-9]?\d|100)$/.test(this.level_set)){
        return this.$showError("请输入0-100的整数！");
      }
      this.sendFun()
    },
    sendFun(){
      var url = "/heating/device-housesvalve/send";
      var data ={
        "id": this.info.housesvalve_id,
        "opening_degree":this.level_set
      }
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
            this.$showSuccess("已下发！");
          this.showModal = false;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    getRealFun(house_ids){
      var url = "/heating/device-housesvalve/read-time";
      var data = house_ids;
      this.$axios.post(url,house_ids).then((res)=>{
        if(res.status == 200){
          this.realData = res.data;
          this.hf_total = this.hfList.length;
          this.hf_18 = 0;
          this.hf_24 = 0;
          this.hf_25 = 0;
          this.hfList.forEach((item)=>{
            if(this.realData[item.housesvalve_id]){
              item = Object.assign(item,{realData:this.realData[item.housesvalve_id]})
              if(this.realData[item.housesvalve_id].indoor.indoor<18){
                this.hf_18 = this.hf_18+1;
              }
              if(this.realData[item.housesvalve_id].indoor.indoor>=18&&this.realData[item.housesvalve_id].indoor.indoor<=24){
                this.hf_24 = this.hf_24+1;
              }
              if(this.realData[item.housesvalve_id].indoor.indoor>24){
                this.hf_25 = this.hf_25+1;
              }
            }
          })
          this.unitList.forEach((item)=>{
            item.lcList.forEach((val)=>{
              val.houseList.forEach((v)=>{
                this.hfList.some((vv)=>{
                  if(vv.housesvalve_houses_id == v.houses_id){
                    v = Object.assign(v,vv);
                    return true
                  }
                })
              })
            })
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },

  }
}
</script>
<style >
.valvePage.housePage .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: #050a20;
  border-color: #113f7e;
}
.valvePage.housePage .ant-select-arrow{
  color: #ffffff;
}
</style>
<style scoped lang="less">
@import "../../assets/css/valve.less";

</style>