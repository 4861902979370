<template>
  <div class="page">
    <breadcrumb :crumbs="crumbs"></breadcrumb>
    <div class="pageCont">
      <div class="configLeft">
        <a-form :model="editData" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off"   >
          <a-form-item label="图片" name="img" >
            <a-upload name="avatar" list-type="picture-card" class="avatar-uploader"  :show-upload-list="false" :customRequest="uploadIcon" >
              <img class="echo_image" v-if="screen_data.img_src" :src="ip+screen_data.img_src" alt="avatar" style="width: 100px"/>
              <div v-else>
                <plus-outlined></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="覆盖区域" name="map_setting_icon_data" >
            <div style="color: red">右侧地图上绘制图片放置范围，鼠标右击结束绘制</div>
            <a-button type="primary" ghost @click="drawStart_area">绘制开启</a-button>
            <a-button danger @click="clear" class="m_l_1">清除</a-button>
          </a-form-item>
          <a-form-item label="地面颜色" name="baseColor" >
            <colorpicker :color="screen_data.baseColor"  @getPickerColor="setColor" ></colorpicker>
          </a-form-item>
          <a-form-item label="热源" name="reyuan" >
            <a-select style="flex: 1;overflow: hidden" v-model:value="screen_data.reyuan" show-search placeholder="选择"
                      :options="monitorList" :filter-option="filterMonitor"
                      :fieldNames="{'label':'monitor_name','value':'monitor_id'}" ></a-select>
          </a-form-item>
        </a-form>
        <a-button  style="float: right" @click="resetFun">重置</a-button>
        <a-button type="primary" @click="save" style="float: right;margin-right: 20px">保存</a-button>

      </div>
      <div class="configRight">
        <div class="viewer_full" id="viewer-container" style="width: 100%;height:calc(100% - 40px)" ></div>
      </div>
    </div>
  </div>

</template>

<script>
var Cesium = require("cesium/Cesium");
var viewer = null;
var handler = null;
var tempArea = null;
var resultArea = null;
var area_pos = [];
var imgEntity = null;
export default {
  name: "basicConfig",
  data(){
    return {
      crumbs:["基础配置"],
      editData:{
        "screen_id": "",
        "screen_data": ""
      },
      screen_data:{
        img_src:"",
        area_pos:[],
        baseColor:"rgba(0,0,0,1)",
        reyuan:""
      },
      ip:"",
      draw_area:false,
      monitorList: [],//监控点列表

    }
  },
  beforeUnmount(){
    if(viewer){
      var canvas = viewer.scene.canvas;
      var gl = canvas.getContext("webgl");
      gl.getExtension('WEBGL_lose_context').loseContext();
      gl = null;
      viewer.destroy();
    }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.initMap()
    this.getMonitor()

  },
  methods:{
    initMap(fun) {
      viewer = new Cesium.Viewer("viewer-container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        shouldAnimate:true,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });
      viewer.scene.moon.show = false;
      viewer.scene.sun.glowFactor = 1
      viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
        url: " http://webst01.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}",
        minimumLevel: 1,
        maximumLevel: 18
      }));
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      });
      this.getData();
      this.cameraMove();
      this.clickEvent()
    },
    clickEvent() {
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      //点击
      handler.setInputAction((event)=>{
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if(cartesian){
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值

          //画区域
          if(this.draw_area){
            if(resultArea){
              return false
            }
            area_pos.push([lon,lat]);

            if(area_pos.length>2&&!tempArea){
              this.drawTemp();
            }
          }
          //标注模型
          if(this.draw_model){
            this.map_setting_model_data.lon=lon;
            this.map_setting_model_data.lat=lat;
            this.setValue();
          }
        }
      },Cesium.ScreenSpaceEventType.LEFT_CLICK);
      //鼠标滑动
      handler.setInputAction((event)=>{
        var cartesian = viewer.camera.pickEllipsoid(event.endPosition, viewer.scene.globe.ellipsoid);
        if(cartesian){
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
          //画区域
          if(this.draw_area){
            if(resultArea){
              return false
            }
            if(area_pos.length>=1){
              area_pos.pop();
            }
            area_pos.push([lon,lat])
            if(area_pos.length>2&&!tempArea){
              this.drawTemp();
            }
          }
        }
      },Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      //右键
      handler.setInputAction((event)=>{
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if(cartesian){
          //画区域
          if(this.draw_area){
            if(resultArea){
              return false
            }
            area_pos.pop();
            if(tempArea){
              viewer.entities.remove(tempArea);
              tempArea = null;
              this.drawResult()
            }
          }
        }
      },Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
    //开启区域绘制
    drawStart_area(){
      this.draw_area = true;
    },
    //画临时区域
    drawTemp(){
      if(area_pos.length<=2){
        return false
      }
      tempArea = new Cesium.Entity({
        id:"area_img_temp",
        polygon:{
          hierarchy:new Cesium.CallbackProperty(()=>{
            return new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(area_pos.flat()))
          },false),
          material:Cesium.Color.fromCssColorString("rgba(255,0,0,0.5)")
        }
      })
      viewer.entities.add(tempArea);
    },
    drawResult(){
      if(area_pos.length<=2){
        return false
      }
      resultArea = new Cesium.Entity({
        id:"area_img_res",
        polygon:{
          hierarchy:new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(area_pos.flat())),
          material:Cesium.Color.fromCssColorString("rgba(0,128,0,0.5)")
        }
      })
      viewer.entities.add(resultArea);
      if(this.screen_data.img_src){
        this.drawImgEntity();
      }
    },
    //清除绘制
    clear(){
      area_pos = [];
      if(resultArea){
        viewer.entities.remove(resultArea);
        resultArea = null;
      }
      if(imgEntity){
        viewer.scene.primitives.remove(imgEntity);
        imgEntity = null;
      }
      this.$loadGaoDe(viewer)
    },
    //图片地图
    drawImgEntity(){
      if(area_pos.length>2){
        //var center = this.$getCenter(area_pos);
        imgEntity = new Cesium.Primitive({
          geometryInstances:new Cesium.GeometryInstance({
            geometry:new Cesium.PolygonGeometry({
              polygonHierarchy:new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(area_pos.flat()))
            })
          }),
          appearance:new Cesium.MaterialAppearance({
            material:this.screen_data.img_src?new Cesium.Material.fromType("Image",{
              "image":this.screen_data.img_src
            }):new Cesium.Material.fromType("Color",{
              "color":Cesium.Color.fromCssColorString("rgba(0,255,0,0.6)")
            })
          })
        });
        viewer.scene.primitives.add(imgEntity)
        this.toCenter()
        this.hideGlobe();
      }
    },
    //上传图片
    uploadIcon(e){
      this.$uploadPng(e.file,(src)=>{
        this.screen_data.img_src = src;
      },true)
    },
    resetFun(){

    },
    save(){
      var data = JSON.parse(JSON.stringify(this.editData));
      this.screen_data.area_pos = area_pos
      data.screen_data = JSON.stringify(this.screen_data);
      var url = "/heating/screen-sttting";
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("保存成功！");
          this.getData();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //设置地球的颜色
    setColor(e){
      this.screen_data.baseColor = e;
      viewer.scene.globe.baseColor =Cesium.Color.fromCssColorString(e);
    },
    hideGlobe(){
      viewer.imageryLayers.removeAll();
      viewer.scene.globe.baseColor =Cesium.Color.fromCssColorString(this.screen_data.baseColor);
      viewer.scene.globe.showGroundAtmosphere = false;
      viewer.scene.globe.translucency.enabled = true;
      viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0);
      viewer.scene.globe.undergroundColor = undefined;
      viewer.scene.skyAtmosphere.show = true; //是否隐藏大气圈
    },
    getData(){
      var url = "/heating/screen-sttting/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          if(JSON.stringify(res.data) == "[]"){
              this.editData = {
                "screen_id": "",
                "screen_data": ""
              }
              this.screen_data = {
                img_src:"",
                area_pos:[],
                baseColor:"rgba(0,0,0,1)",
                angle:"",
                reyuan:""
              }
          }else{
            this.editData = res.data;
            this.editData.screen_id = this.editData.screen_id?this.editData.screen_id:""
            this.screen_data = res.data.screen_data?JSON.parse(res.data.screen_data):{
              img_src:"",
              area_pos:[],
              baseColor:"rgba(0,0,0,1)",
              angle:"",
              reyuan:""
            }
          }
          area_pos = this.screen_data.area_pos
          this.drawImgEntity();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    cameraMove() {
      viewer.scene.camera.moveEnd.addEventListener(() => {
        var info = this.getcameraPosInfo();
        this.screen_data.angle = info;
      })
    },
    //获取相机视角
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading.toFixed(6);
      var pitch = viewer.scene.camera.pitch.toFixed(6);
      var roll = viewer.scene.camera.roll.toFixed(6);
      var info = {head: head, pitch: pitch, roll: roll};
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic; //经纬度单位为弧度，高程单位为米.
      //以下方式也可以获取相机位置只是返回的坐标系不一样
      // var position = this.viewer.scene.camera.position //cartesian3 空间直角坐标系
      // var ellipsoid = scene.globe.ellipsoid;
      // var position =ellipsoid.cartesianToCartographic(this.viewer.scene.camera.position)//
      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6);
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6);
      var height = position.height;
      return {lon: longitude, lat: latitude, h: height, head: head, pitch: pitch, roll: roll};
    },
    toCenter(){
      for(var key in this.screen_data.angle){
        this.screen_data.angle[key] = parseFloat(this.screen_data.angle[key])
      }
      viewer.camera.setView({
          destination:Cesium.Cartesian3.fromDegrees(this.screen_data.angle.lon,this.screen_data.angle.lat,this.screen_data.angle.h),
        orientation: {
          heading: this.screen_data.angle.head===""?0:this.screen_data.angle.head,
          pitch: this.screen_data.angle.pitch===""?0:this.screen_data.angle.pitch,
          roll: this.screen_data.angle.roll===""?0:this.screen_data.angle.roll
        }
        })
    },
    //获取组态列表
    getMonitor() {
      var url = "/heating/zeiot/monitor-options";
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.monitorList = res.data;
        }
      }).catch((res) => {
        console.log(res)
      })
    },
    filterMonitor(e, option) {
      return option.monitor_name.includes(e)
    },

  }
}
</script>

<style scoped>

</style>