import { message,notification,Modal } from 'ant-design-vue';
import axios from 'axios';
import cookie from 'js-cookie';
import * as turf from "@turf/turf";
import common from "@/until/until";

const axios1 = axios.create({
     baseURL:""
    //baseURL:""
})

// 添加请求拦截器
axios1.interceptors.response.use( function (response) {


        // 在发送请求之前进行操作
        return response;
    },
    function (err) {
        console.log("error",err);
        if(err.response.status==400){
            common.showError(err.response.data.toString());
        }
        if(err.response.status==401){
            router.push("/login")
        }
        // 对请求错误进行操作
        return Promise.reject(err);
    }
);

var upload_key = "";
var upload_url = "";
var showAlarm = function (title){
    message.warning(title);
}
//转base64
var fileToBase64 = function(file, callback) {
    // 创建FileReader对象（不兼容IE）
    let reader = new FileReader();
    // 将file转为base64 （异步操作）
    reader.readAsDataURL(file);
    // 转换成功
    reader.onload = () => {
        const response = {
            status: true,
            data: reader.result
        }
        callback(response);
    };
    // 转换失败
    reader.onerror = function () {
        const response = {
            status: false,
            data: reader.error
        }
        callback(response);
    };
}
//错误信息
var showError = (title)=>{
    message.error(title);
}
//成功信息
var showSuccess = (title)=>{
    message.success(title);
}
//警告提示
var showWarmTip = (title)=>{
    message.warning(title);
}
//错误提示
var showErrTip = (des)=>{
    notification.open({
        message: '',
        description:des,
        style: {
            width: '400px',
            marginLeft: `${335 - 600}px`,
        },
        class: 'notification-custom-class',
    });
}
//alert
var showAlert = (title)=>{
    /*var modalRef = Modal.info({
        title: title,
        onOk(){
            console.log("pppppppppp",modalRef)
            modalRef.destroy();
        }
    });*/
    Modal.confirm({
        title: 'Do you Want to delete these items?',
        content: "",
        onOk() {
            console.log('OK');
        },
        onCancel() {
            console.log('Cancel');
        },
        class: 'test',
    });
}



//日期格式转换
function getTime(stamp){
    var time = new Date(stamp);
    var yyyy = time.getFullYear();
    var mm = (time.getMonth()+1)>=10?(time.getMonth()+1):'0'+(time.getMonth()+1);
    var dd = time.getDate()>=10?time.getDate():'0'+time.getDate();
    var hh = time.getHours()>=10?time.getHours():'0'+time.getHours();
    var min = time.getMinutes()>=10?time.getMinutes():'0'+time.getMinutes();
    var ss = time.getSeconds()>=10?time.getSeconds():'0'+time.getSeconds();
    var day = time.getDay();
    var week = '';
    switch(day){
        case 0:
            week = "星期日";
            break;
        case 1:
            week = "星期一";
            break;
        case 2:
            week = "星期二";
            break;
        case 3:
            week = "星期三";
            break;
        case 4:
            week = "星期四";
            break;
        case 5:
            week = "星期五";
            break;
        case 6:
            week = "星期六";
            break;
    }
    return {
        yyyy,
        mm,
        dd,
        hh,
        min,
        ss,
        d:yyyy+'-'+mm+'-'+dd,
        t1:yyyy+'-'+mm+'-'+dd+' '+hh+":"+min+":"+ss,
        t3:yyyy+'.'+mm+'.'+dd+'  '+hh+":"+min+":"+ss,
        t4:yyyy+'-'+mm+'-'+dd+'T'+hh+":"+min+":"+ss,
        week:week,
        t2:hh+':'+min+':'+ss,
        d2:yyyy+'年'+mm+'月'+dd+"日"
    }
}


function GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
//上传文件 切片上传
function uploadFile(file,fun){
    axios1.defaults.headers.common["Authorization"] = "bearer "+cookie.get("token");
    var url = "/maps/upload/key/url?file_type=模型";
    axios1.post(url).then((res)=>{
        if(res.status == 200){
            upload_key = res.data.upload_key;
            upload_url = res.data.upload_url;
            slice_upload(0,file,fun);
        }
    }).catch((err)=>{
        console.log(err);

    })
}
function slice_upload(i,file,fun){
    var slice_i = i;
    var size = file.size;
    var count = Math.ceil(size/(1 * 1024 * 1024));
    var name = file.name;
    if(slice_i>=count){
        return false
    }
    var start = slice_i*size;
    var end = Math.min(size,start+(1 * 1024 * 1024));
    var form = new FormData();
    form.append("data",file.slice(start,end));
    form.append("lastModified", file.lastModified);
    form.append("fileName", name);
    form.append("total", count); //总片数
    form.append("index", slice_i + 1); //当前是第几片
    form.append("key", upload_key);
    form.append("path", upload_url);

    var url = "/maps/file/upload";
    axios1.post(url,form).then((res)=>{
        slice_i = slice_i+1;
        if(slice_i>=count){
            if(res.data.mergeOk){
                if(fun){
                    fun(res.data.src.split("wwwroot")[1])
                }
            }
            return
        }
        slice_upload(slice_i,file,fun);
    }).catch((res)=>{
        console.log(res);
    })
}

//上传图片
function uploadPng(e,fun,no_size){
    if(!no_size&&(e.size/1024)>500){
        showWarmTip("图片大小限制在500k内！")
        return false
    }
    axios1.defaults.headers.common["Authorization"] = "bearer "+cookie.get("token");
    axios1.defaults.headers.common["blockcode"] = window.location.hash.split("&")[0].split("key=")[1];
    var url = "/maps/file/upload/base?type=图片";
    var form = new FormData();
    form.append("file",e);
    axios1.post(url,form,{
        headers:{
            "Content-Type":"multipart/form-data"
        }
    }).then((res)=>{
        if(fun){
            fun(res.data.split("wwwroot")[1]);
        }
    }).catch((res)=>{
        console.log(res);
    })
}

//imageEntity
function drawImageEntity(viewer,fun,unViewer){
    axios1.defaults.headers.common["Authorization"] = "bearer "+cookie.get("token");
    axios1.defaults.headers.common["blockcode"] = window.location.hash.split("&")[0].split("key=")[1];
    var area_pos = [];
    var screen_data = {};
    var url = "/heating/screen-sttting/info";
    axios1.get(url).then((res)=>{
        if(res.status == 200){
            if(JSON.stringify(res.data) == "[]"){
                screen_data = {
                    img_src:"",
                    area_pos:[],
                    baseColor:"rgba(0,0,0,1)",
                    layout:[]
                }
            }else{
                screen_data = res.data.screen_data?JSON.parse(res.data.screen_data):{
                    img_src:"",
                    area_pos:[],
                    baseColor:"rgba(0,0,0,1)",
                    layout:[]
                }
            }
            area_pos = screen_data.area_pos;
            if(fun){
                fun(screen_data)
            }
            if(unViewer){
                return false
            }
            if(area_pos.length>2){
                var imgEntity = new Cesium.Primitive({
                    geometryInstances:new Cesium.GeometryInstance({
                        geometry:new Cesium.PolygonGeometry({
                            polygonHierarchy:new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(area_pos.flat()))
                        })
                    }),
                    appearance:new Cesium.MaterialAppearance({
                        material:screen_data.img_src?new Cesium.Material.fromType("Image",{
                            "image":screen_data.img_src
                        }):new Cesium.Material.fromType("Color",{
                            "color":Cesium.Color.fromCssColorString("rgba(0,255,0,0.6)")
                        })
                    })
                });
                viewer.scene.primitives.add(imgEntity)
                for(var key in screen_data.angle){
                    screen_data.angle[key] = parseFloat(screen_data.angle[key])
                }
                viewer.camera.setView({
                    destination:Cesium.Cartesian3.fromDegrees(screen_data.angle.lon,screen_data.angle.lat,screen_data.angle.h),
                    orientation: {
                        heading: screen_data.angle.head===""?0:screen_data.angle.head,
                        pitch: screen_data.angle.pitch===""?0:screen_data.angle.pitch,
                        roll: screen_data.angle.roll===""?0:screen_data.angle.roll
                    }
                })
                hideGlobe(viewer,screen_data);
            }
        }
    }).catch((res)=>{
        console.log(res);
    })
}
function hideGlobe(viewer,screen_data){
    viewer.imageryLayers.removeAll();
    viewer.scene.globe.baseColor =Cesium.Color.fromCssColorString(screen_data.baseColor);
    viewer.scene.globe.showGroundAtmosphere = false;
    viewer.scene.globe.translucency.enabled = true;
    viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0);
    viewer.scene.globe.undergroundColor = undefined;
    viewer.scene.skyAtmosphere.show = true; //是否隐藏大气圈
}

//获取中心点
function getCenter(pos){
    var aaa = [];
    pos.forEach((item)=>{
        aaa.push(turf.point( [item[0], item[1]]))
    })
    var features = turf.featureCollection(aaa);
    var center = turf.center(features);
    return {
        lon:center.geometry.coordinates[0],
        lat:center.geometry.coordinates[1]
    }
}
//判断点是否在多边形内
function is_in(point,pos){
    var pt = turf.point(point);
    var poly = turf.polygon([pos]);

    return turf.booleanPointInPolygon(pt, poly);
}
//计算长度
function get_len(pos){
    var line = turf.lineString(pos);
    var length = turf.length(line, {units: 'meters'});
    return length
}

//获取链接类型  大屏
function getMenuTypes(fun){
    axios1.defaults.headers.common["Authorization"] = "bearer "+cookie.get("token");
    var url = "/maps/def/url/linking/method";
    axios1.get(url).then(res=>{
        if(res.status ==200){
            if(fun){
                fun(res.data)
            }
        }
    }).catch((res)=>{
        console.log(res);
    })
}
//获取窗口展示类型
function getModalOpenType(fun){
    axios1.defaults.headers.common["Authorization"] = "bearer "+cookie.get("token");
    var url = "/maps/def/url/link/display/type";
    axios1.get(url).then(res=>{
        if(res.status ==200){
            if(fun){
                console.log(res.data);
                fun(res.data)
            }
        }
    }).catch((res)=>{
        console.log(res);
    })
}
//获取视频设备类型
function getVideoType(fun){
    axios1.defaults.headers.common["Authorization"] = "bearer "+cookie.get("token");
    var url = "/maps/video/device/type";
    axios1.post(url).then(res=>{
        if(res.status ==200){
            if(fun){
                fun(res.data);
            }
        }
    }).catch((res)=>{
        console.log(res);
    })
}


import { AmapImageryProvider, BaiduImageryProvider, TencentImageryProvider, TdtImageryProvider} from '@dvgis/cesium-map';
import router from "@/router";
var Cesium = require("cesium/Cesium");
//高德地图
function loadGaoDe(viewer) {
    viewer.imageryLayers.removeAll();
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new AmapImageryProvider({
        style: 'img', // style: img、elec、cva     影像、电子、注记
        crs: 'WGS84' // 使用84坐标系，默认为：GCJ02
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new AmapImageryProvider({
        style: 'cva', // style: img、elec、cva
        crs: 'WGS84' // 使用84坐标系，默认为：GCJ02
    })))
    /* viewer.imageryLayers.addImageryProvider(new AmapImageryProvider({
       url: "https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}&size=1&scale=1",
       layer: 'tdtAnnoLayer',
       style: 'default',
       format: 'image/jpeg',
       minimumLevel: 1,
       maximumLevel: 18,
       crs: 'WGS84'
     }));
     viewer.imageryLayers.addImageryProvider(new AmapImageryProvider({
       url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
       layer: 'tdtAnnoLayer',
       style: 'default',
       format: 'image/jpeg',
       minimumLevel: 1,
       maximumLevel: 18,
       //subdomains: this.subdomains,
       //tileMatrixSetID: 'GoogleMapsCompatible',
       crs: 'WGS84'
     }));*/
}
//百度地图
function loadBaiDu(viewer) {
    viewer.imageryLayers.removeAll();
    /*viewer.imageryLayers.addImageryProvider(new BaiduImageryProvider({
      url: "http://api0.map.bdimg.com/customimage/tile?&x={x}&y={y}&z={z}&scale=1&customid=darkgreen"
    }))*/
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new BaiduImageryProvider({
        style: 'img', // style: img、vec、normal、dark、traffic 影像、注记、电子、路况
        crs: 'WGS84' // 使用84坐标系，默认为：BD09
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new BaiduImageryProvider({
        style: 'vec', // style: img、vec、normal、dark
        crs: 'WGS84' // 使用84坐标系，默认为：BD09
    })))
}
//腾讯地图
function loadTengXun(viewer) {
    viewer.imageryLayers.removeAll();
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TencentImageryProvider({
        style: "img" //style: img、2：影像、1：经典、4：墨渊、8：白浅
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TencentImageryProvider({
        style: 2 //style: img、2：影像、1：经典、4：墨渊、8：白浅
    })))
}
//天地图
function loadTdt(viewer,key) {
    viewer.imageryLayers.removeAll();
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TdtImageryProvider({
        style: 'img', //style: vec、cva、img、cia、ter 电子、注记 、影像、、地形
        key: key,//this.editData.map_setting_map_key, // 需去相关地图厂商申请
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TdtImageryProvider({
        style: 'cva', //style: vec、cva、img、cia、ter 电子、注记 、影像、、地形
        key: key,//this.editData.map_setting_map_key, // 需去相关地图厂商申请
    })))
}
//高德地图 电子地图
function loadGaoDe_d(viewer) {
    viewer.imageryLayers.removeAll();
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new AMapImageryProvider({
        style: 'elec', // style: img、elec、cva     影像、电子、注记
        crs: 'WGS84' // 使用84坐标系，默认为：GCJ02
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new AMapImageryProvider({
        style: 'cva', // style: img、elec、cva     影像、电子、注记
        crs: 'WGS84' // 使用84坐标系，默认为：GCJ02
    })))
}
//百度地图 电子地图
function loadBaiDu_d(viewer) {
    viewer.imageryLayers.removeAll();
    /*viewer.imageryLayers.addImageryProvider(new BaiduImageryProvider({
      url: "http://api0.map.bdimg.com/customimage/tile?&x={x}&y={y}&z={z}&scale=1&customid=darkgreen"
    }))*/
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new BaiduImageryProvider({
        style: 'normal', // style: img、vec、normal、dark、traffic 影像、注记、电子、路况
        crs: 'WGS84' // 使用84坐标系，默认为：BD09
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new BaiduImageryProvider({
        style: 'vec', // style: img、vec、normal、dark
        crs: 'WGS84' // 使用84坐标系，默认为：BD09
    })))
}
//腾讯地图 电子地图
function loadTengXun_d(viewer) {
    console.log("0000000000000000000")
    viewer.imageryLayers.removeAll();
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TencentImageryProvider({
        style: "img" //style: img、2：影像、1：经典、4：墨渊、8：白浅
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TencentImageryProvider({
        style: 1 //style: img、2：影像、1：经典、4：墨渊、8：白浅
    })))
}
//天地图 电子地图
function loadTdt_d(viewer,key) {
    viewer.imageryLayers.removeAll();
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TdtImageryProvider({
        style: 'vec', //style: vec、cva、img、cia、ter 电子、注记 、影像、、地形
        key: key,//this.editData.map_setting_map_key, // 需去相关地图厂商申请
    })))
    viewer.imageryLayers.add(new Cesium.ImageryLayer(new TdtImageryProvider({
        style: 'cva', //style: vec、cva、img、cia、ter 电子、注记 、影像、、地形
        key: key,//this.editData.map_setting_map_key, // 需去相关地图厂商申请
    })))
}

//2.0api
function getZeiotApi(url,method,body,callback,callback2){
    axios1.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    axios1.defaults.headers.common["blockcode"] = window.location.hash.split("&")[0].split("key=")[1];
    var data = {
        "url": url,
        "contentType": "application/json",
        "method": method,
        "body": body
    }
    axios1.post("/heating/zeiot/api",data).then((res)=>{
        if(callback){
            callback(res);
        }
    }).catch((res)=>{
        if(callback2){
            callback2(res)
        }
    })
}

//判断数字
function judgeNumber(str){
    var debug = false;
    var re = /^[+-]?\d*\.?\d*$/;
    str = str.toString();
    if(str===""||str===null) {
        debug = false;
    }else if(!re.test(str)){
        debug = false
    }else if(str.includes(".")&&str.split(".")[1].length>0){
        debug = true
    }else if(!str.includes(".")){
        debug = true
    }
    return debug
}

//相机视角跳转
function setView(viewer,lon,lat,h){
    viewer.camera.setView({
        destination:Cesium.Cartesian3.fromDegrees(lon,lat, h ? h+500 : 500)
    })
}

//加载地形与倾斜摄影
function loadDX_QXSY(viewer,screenInfo,tiles){
    changeTerrain()
    load3DTiles()
}
//启用地形
function changeTerrain(viewer,screenInfo){
    var accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYWJmMTNjMy0xYzFlLTQwYWItOTVkNS0xY2YyMDBmMjRhMDIiLCJpZCI6MTU1MDIyLCJpYXQiOjE2ODk3NTczMTd9.NrDiIA6T-MEE_lX80OOiugBYJ1N6XXfFuZyQgakgK7c'
    Cesium.Ion.defaultAccessToken = accessToken;
    //this.editData.map_setting_terrain_route = "";
    if(screenInfo.map_setting_terrain_enable){//启用地形
        if(!screenInfo.map_setting_terrain_route){//没有地形文件
            viewer.terrainProvider = new Cesium.createWorldTerrain();
        }else{//有地形文件
            viewer.terrainProvider = new Cesium.CesiumTerrainProvider({
                url: "/terrain/"+screenInfo.map_setting_terrain_route,//  /heishan/   // /3dMap/sjzTerrain/  //http://localhost:8080/tianditu_sanlie/  //http://localhost:8080/sjzTerrain/
            })
        }
    }else{//不启用地形
        viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider({});
    }
}
//倾斜摄影
function load3DTiles(viewer,screenInfo){
    var tiles = null;
    if(screenInfo.map_oblique_photography.url){
        tiles = new Cesium.Cesium3DTileset({
            url: "/3dtiles/"+screenInfo.map_oblique_photography.url,
            maximumScreenSpaceError: maximumScreenSpaceError,// 数值加大，能让最终成像变模糊
            dynamicScreenSpaceErrorDensity:1,// 数值加大，能让周边加载变快
            //cullRequestsWhileMovingMultiplier:10,// 值越小能够更快的剔除
            //loadSiblings:true,// 如果为true 则不会在已加载完概况房屋后，自动从中心开始超清化房屋
        });
        //viewer.scene.globe.depthTestAgainstTerrain = true;
        tiles.readyPromise.then((tileset)=>{
            // 定位到3D Tiles模型
            const cartographic = Cesium.Cartographic.fromCartesian(
                tileset.boundingSphere.center
            );
            const surface = Cesium.Cartesian3.fromRadians(
                cartographic.longitude,
                cartographic.latitude,
                0.0
            );
            const offset = Cesium.Cartesian3.fromRadians(
                cartographic.longitude,
                cartographic.latitude,
                (screenInfo.map_oblique_photography.h!==""&&screenInfo.map_oblique_photography.h!==undefined?parseFloat(screenInfo.map_oblique_photography.h):0)
            );
            console.log(Cesium.Math.toDegrees(cartographic.longitude),Cesium.Math.toDegrees(cartographic.latitude))
            const translation = Cesium.Cartesian3.subtract(
                offset,
                surface,
                new Cesium.Cartesian3()
            );
            tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);

        });
        viewer.scene.primitives.add(tiles);
    }
    return tiles
}
//模型
function loadModel(viewer,map_setting_model_data,map_setting_model_file_src,fun){
    var model = null;

    var modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(
        Cesium.Cartesian3.fromDegrees(map_setting_model_data.lon, map_setting_model_data.lat,map_setting_model_data.h),
        new Cesium.HeadingPitchRoll( Cesium.Math.toRadians(map_setting_model_data.head),
            Cesium.Math.toRadians(map_setting_model_data.pitch),
            Cesium.Math.toRadians(map_setting_model_data.roll))
    );
    model = Cesium.Model.fromGltf({
        url:map_setting_model_file_src,//"./model/HengDaYuanQu.glb"
        modelMatrix:modelMatrix,
        scale:map_setting_model_data.scale,
        instanceToInstanceColor: true,
        // 开启模型分层
        incrementallyLoadTextures: true,
    });
    var errrooo = viewer.scene.primitives.add(model);
    model.readyPromise.then((res)=>{
        console.log("oooooooooooo")
        model.activeAnimations.addAll({
            loop: Cesium.ModelAnimationLoop.REPEAT,
            removeOnStop: true,
            multiplier:5
        })
        if(fun){
            fun(errrooo)
        }
    }).catch((res)=>{
        console.log("失败，8888888888888");
    })
    viewer.camera.setView({
        destination:Cesium.Cartesian3.fromDegrees(map_setting_model_data.lon, map_setting_model_data.lat,1000),
        orientation:{head: Cesium.Math.toRadians(map_setting_model_data.head), pitch: -1.5707963267948966, roll: Cesium.Math.toRadians(map_setting_model_data.roll)}
    })
    return model
}


//清除webgl实例
function clearWebgl(viewer){
    var canvas = viewer.scene.canvas;
    var gl = canvas.getContext("webgl");
    gl.getExtension('WEBGL_lose_context').loseContext();
    gl = null;
    viewer.destroy();
}
//字号
function fontSize(res){
    const clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
    if (!clientWidth) return;
    let fontSize = clientWidth / 1920;
    return res*fontSize;
}

//上传excel
function uploadExcel(file){
    return new Promise((resolve)=>{
        //创建Workbook实例
        const workbook = new ExcelJS.Workbook();
        // 使用FileReader对象来读取文件内容
        const fileReader = new FileReader()
        // 二进制字符串的形式加载文件
        fileReader.readAsArrayBuffer(file)
        fileReader.onload = ev => {
            //console.log(ev)
            // 从 buffer中加载数据解析
            workbook.xlsx.load(ev.target.result).then(workbook => {
                // 获取第一个worksheet内容
                const worksheet = workbook.getWorksheet(1);
                // 获取第一行的标题
                const headers = [];
                worksheet.getRow(1).eachCell((cell) => {
                    headers.push(cell.value);
                });
                //console.log("headers", headers)
                // 创建一个空的JavaScript对象数组，用于存储解析后的数据
                const data = [];
                // 遍历工作表的每一行（从第二行开始，因为第一行通常是标题行）
                for (let rowNumber = 2; rowNumber <= worksheet.rowCount; rowNumber++) {
                    const rowData = {};
                    const row = worksheet.getRow(rowNumber);
                    // 遍历当前行的每个单元格
                    row.eachCell((cell, colNumber) => {
                        // 获取标题对应的键，并将当前单元格的值存储到相应的属性名中
                        rowData[headers[colNumber - 1]] = cell.value;
                    });
                    // 将当前行的数据对象添加到数组中
                    data.push(rowData);
                }
                resolve({headers,data})
                //console.log("data", data)
            })
        }
    })
}
//导出excel
function exportExcel(newData,headers,file_name){
    var cols_date = [];
    //创建一个新的工作簿表
    const workbook  = new ExcelJS.Workbook()
    //创建一个新的工作表
    const worksheet = workbook.addWorksheet('My Sheet')
    //可选  单元格的默认宽度
    worksheet.properties.defaultColWidth = 20
//可选  单独设置第五列的宽度
    worksheet.getColumn(5).width = 10
//添加表头到工作表
    worksheet.addRow(Object.keys(headers));
    worksheet.addRows(fromatterData(headers,newData));
//可选  给每一行设置行高
    worksheet.eachRow(row => {
        row.height = 20;
    });
//可选 单独设置某一行的行高
    worksheet.getRow(1).height = 25;
// 将工作簿保存到本地文件
    workbook.xlsx.writeBuffer().then(buffer=>{
//这行代码创建了一个Blob对象，它基于给定的二进制数据buffer，
//这个二进制数据是一个Excel文件的内容，
//Blob的MIME类型被设置为
//"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"，
//表示这是一种Excel的文件格式。
//一个Blob对象是一个不可变的二进制数据对象，它表示了一段数据，可以通过URL.createObjectURL()方法创建一个URL来访问它。
//在这种情况下，这个Blob对象可以被用来下载Excel文件，或者上传到服务器上进行处理。
        const blob = new Blob([buffer],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name+'.xlsx');
        document.body.appendChild(link);
        link.click();
    })
    function fromatterData(headers, rwos) {
        return rwos.map(item => {
            return Object.keys(headers).map(key => {
                //判断是否是时间字段
                if (cols_date.includes(headers[key])) {
                    if (item[headers[key]] == null) {
                        return item[headers[key]]
                    } else {
                        return formatDate('yyyy-MM-dd hh:mm:ss',item[headers[key]])
                    }

                }
                return item[headers[key]]
            })
        })
    }
    function formatDate (format, date, settings) {
        if (!date) {
            return "";
        }

        var iFormat,
            dayNamesShort = (settings ? settings.dayNamesShort : null) || this._defaults.dayNamesShort,
            dayNames = (settings ? settings.dayNames : null) || this._defaults.dayNames,
            monthNamesShort = (settings ? settings.monthNamesShort : null) || this._defaults.monthNamesShort,
            monthNames = (settings ? settings.monthNames : null) || this._defaults.monthNames,
            // Check whether a format character is doubled
            lookAhead = function(match) {
                var matches = (iFormat + 1 < format.length && format.charAt(iFormat + 1) === match);
                if (matches) {
                    iFormat++;
                }
                return matches;
            },
            // Format a number, with leading zero if necessary
            formatNumber = function(match, value, len) {
                var num = "" + value;
                if (lookAhead(match)) {
                    while (num.length < len) {
                        num = "0" + num;
                    }
                }
                return num;
            },
            // Format a name, short or long as requested
            formatName = function(match, value, shortNames, longNames) {
                return (lookAhead(match) ? longNames[value] : shortNames[value]);
            },
            output = "",
            literal = false;

        if (date) {
            for (iFormat = 0; iFormat < format.length; iFormat++) {
                if (literal) {
                    if (format.charAt(iFormat) === "'" && !lookAhead("'")) {
                        literal = false;
                    } else {
                        output += format.charAt(iFormat);
                    }
                } else {
                    switch (format.charAt(iFormat)) {
                        case "d":
                            output += formatNumber("d", date.getDate(), 2);
                            break;
                        case "D":
                            output += formatName("D", date.getDay(), dayNamesShort, dayNames);
                            break;
                        case "o":
                            output += formatNumber("o",
                                Math.round((new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 86400000), 3);
                            break;
                        case "m":
                            output += formatNumber("m", date.getMonth() + 1, 2);
                            break;
                        case "M":
                            output += formatName("M", date.getMonth(), monthNamesShort, monthNames);
                            break;
                        case "y":
                            output += (lookAhead("y") ? date.getFullYear() :
                                (date.getYear() % 100 < 10 ? "0" : "") + date.getYear() % 100);
                            break;
                        case "@":
                            output += date.getTime();
                            break;
                        case "!":
                            output += date.getTime() * 10000 + this._ticksTo1970;
                            break;
                        case "'":
                            if (lookAhead("'")) {
                                output += "'";
                            } else {
                                literal = true;
                            }
                            break;
                        default:
                            output += format.charAt(iFormat);
                    }
                }
            }
        }
        return output;
    }
}




export default {
    showAlarm,
    fileToBase64,
    showError,
    getTime,
    GetQueryString,
    showSuccess,
    uploadFile,
    uploadPng,
    getCenter,
    loadGaoDe,
    loadBaiDu,
    loadTengXun,
    loadTdt,
    getZeiotApi,
    showErrTip,
    judgeNumber,
    is_in,
    getMenuTypes,
    getModalOpenType,
    getVideoType,
    setView,
    showAlert,
    loadGaoDe_d,
    loadBaiDu_d,
    loadTengXun_d,
    loadTdt_d,
    showWarmTip,
    changeTerrain,
    load3DTiles,
    loadModel,
    get_len,
    drawImageEntity,
    clearWebgl,
    fontSize,
    uploadExcel,
    exportExcel
}
