<template>
  <div class="beng_cont">
    <div class="img_cont">
      <span class="params params_const1">一次网参数</span>
      <span class="params params_const2">二次网参数</span>
      <span class="params params_const3">泄水阀</span>
      <span class="params params_const4">补水泵：</span>
      <span class="params params_const5">液位：</span>
      <span class="params params_const6">补水阀</span>
      <span class="params params_input params_1">{{ paramsData["瞬时流量1_1"] ? paramsData["瞬时流量1_1"].value : "" }} {{ paramsData["瞬时流量1_1"] ? paramsData["瞬时流量1_1"].pp_unit : "" }}</span>
      <span class="params params_input params_2">{{ paramsData["瞬时热量1_1"] ? paramsData["瞬时热量1_1"].value : "" }} {{ paramsData["瞬时热量1_1"] ? paramsData["瞬时热量1_1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_3">{{ paramsData["一次供水温度"] ? paramsData["一次供水温度"].value : "" }} {{ paramsData["一次供水温度"] ? paramsData["一次供水温度"].pp_unit : "" }}</span>
      <span
          class="params params_input params_4">{{ paramsData["一次回水温度"] ? paramsData["一次回水温度"].value : "" }} {{ paramsData["一次回水温度"] ? paramsData["一次回水温度"].pp_unit : "" }}</span>
      <span
          class="params params_input params_5">{{ paramsData["一次供水压力1"] ? paramsData["一次供水压力1"].value : "" }} {{ paramsData["一次供水压力1"] ? paramsData["一次供水压力1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_6">{{ paramsData["一次回水压力1"] ? paramsData["一次回水压力1"].value : "" }} {{ paramsData["一次回水压力1"] ? paramsData["一次回水压力1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_7">{{ paramsData["1#调节阀反馈"] ? paramsData["1#调节阀反馈"].value : "" }} {{ paramsData["1#调节阀反馈"] ? paramsData["1#调节阀反馈"].pp_unit : "" }}</span>
      <span
          class="params params_input params_8">{{ paramsData["二次供水温度1"] ? paramsData["二次供水温度1"].value : "" }} {{ paramsData["二次供水温度1"] ? paramsData["二次供水温度1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_9">{{ paramsData["二次回水温度1"] ? paramsData["二次回水温度1"].value : "" }} {{ paramsData["二次回水温度1"] ? paramsData["二次回水温度1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_10">{{ paramsData["二次供水压力1"] ? paramsData["二次供水压力1"].value : "" }} {{ paramsData["二次供水压力1"] ? paramsData["二次供水压力1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_11">{{ paramsData["二次回水压力1"] ? paramsData["二次回水压力1"].value : "" }} {{ paramsData["二次回水压力1"] ? paramsData["二次回水压力1"].pp_unit : "" }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态1')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态1"].value==1), "params_12":true,"params_num_red":true}'>{{ paramsData["循环泵运行状态1"] ? paramsData["循环泵运行状态1"].value == 1 ? "启动" : "停止" : "" }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态2')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态2"].value==1), "params_13":true,"params_num_red":true}'>{{ paramsData["循环泵运行状态2"] ? paramsData["循环泵运行状态2"].value == 1 ? "启动" : "停止" : "" }}</span>
      <span v-if="paramsData.hasOwnProperty('补水泵运行状态1')"
            :class='{"params":true, "params_num":(paramsData["补水泵运行状态1"].value==1), "params_15":true,"params_num_red":true}'>{{ paramsData["补水泵运行状态1"] ? paramsData["补水泵运行状态1"].value == 1 ? "启动" : "停止" : "" }}</span>
      <span v-if="paramsData.hasOwnProperty('补水泵运行状态2')"
            :class='{"params":true, "params_num":(paramsData["补水泵运行状态2"].value==1), "params_16":true,"params_num_red":true}'>{{ paramsData["补水泵运行状态2"] ? paramsData["补水泵运行状态2"].value == 1 ? "启动" : "停止" : "" }}</span>
      <span class="params params_input params_17">{{ paramsData["1#水箱液位"] ? paramsData["1#水箱液位"].value : "" }} {{ paramsData["1#水箱液位"] ? paramsData["1#水箱液位"].pp_unit : "" }}</span>
      <span
          class="params params_input params_18">{{ paramsData["补水累计流量1"] ? paramsData["补水累计流量1"].value : "" }} {{ paramsData["补水累计流量1"] ? paramsData["补水累计流量1"].pp_unit : "" }}</span>
    </div>
  </div>
</template>

<script>
//2个泵
export default {
  name: "beng2",
  data() {
    return {
      paramsList: [],
      paramsData: {},
      device_id:""
    }
  },
  mounted() {
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.device_id = this.$route.query.id;
    this.getParamsData();
    setInterval(()=>{
      this.getParamsData();
    },10000)

  },
  methods: {
    //获取参数的实时数据
    getParamsData: function () {
      var url = '/heating/zeiot/device/read-time?id='+this.device_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.paramsData = {};
          res.data.forEach((item)=>{
            this.paramsData[item.pp_name] = {
              value:item.pp_value,
              unit:item.pp_unit
            }
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    }

  }
}
</script>

<style scoped>
.beng_cont {
  width: 100%;
  height: 100%;
  padding: 0 0.1rem 0.1rem;
  box-sizing: border-box;
}
.img_cont {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/beng/bg2.png") center/100% 100% no-repeat;
  position: relative;
}
.params {
  position: absolute;
  font-size: 12px;
}
.params_input_tit {
  color: #ffffff;
  display: flex;
  align-items: center;
}
.params_input {
  display: inline-block;
  width: 60px;
  height: 24px;
  line-height: 24px;
  /* box-shadow: 0.01rem 0.01rem 1px 1px #CCCCCC ;*/
  background-color: transparent;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  overflow: hidden;
}
.params_num_red {
  color: red;
}
.params_num {
  color: #00fc00;
}
.params_1 {
  top: 1.1rem;
  left: 0.8rem;
}
.params_2 {
  top: 1.35rem;
  left: 0.8rem;
}
.params_3 {
  top: 1.95rem;
  left: 2.56rem;
}
.params_4 {
  top: 3rem;
  left: 2.5rem;
}
.params_5 {
  top: 1.95rem;
  left: 1.58rem;
}
.params_6 {
  top: 3rem;
  left: 0.85rem;
}
.params_7 {
  top: 1.95rem;
  left: 3.62rem;
}
.params_8 {
  top: 1.5rem;
  right: 0.9rem;
}
.params_9 {
  top: 3rem;
  right: 1.15rem;
}
.params_10 {
  top: 1.5rem;
  right: 2.5rem;
}
.params_11 {
  top: 3rem;
  right: 2.2rem;
}
.params_12 {
  top: 2.4rem;
  right: 3.5rem;
}
.params_13 {
  top: 3.28rem;
  right: 3.5rem;
}
.params_15 {
  bottom: 2.6rem;
  right: 2.2rem;
}
.params_16 {
  bottom: 1.85rem;
  right: 2.2rem;
}
.params_17 {
  bottom: 2.55rem;
  left: 4rem;
}
.params_18 {
  bottom: 1.7rem;
  left: 1.05rem;
}
.params_21 {
  top: 1.4rem;
  left: 7.7rem;
}
.params_22 {
  top: 1.4rem;
  left: 10.2rem;
}
.params_23 {
  top: 1.4rem;
  left: 12.7rem;
}
.params_24 {
  top: 2.2rem;
  left: 7.7rem;
}
.params_25 {
  top: 2.2rem;
  left: 10.2rem;
}
.params_26 {
  top: 2.2rem;
  left: 12.7rem;
}
.params_const1 {
  top: 2rem;
  left: 0.6rem;
  color: #00fc00;
}
.params_const2 {
  top: 2rem;
  right: 0.6rem;
  color: #00fc00;
}
.params_const3 {
  top: 3.45rem;
  right: 0.5rem;
  color: #ffffff;
}
.params_const4 {
  bottom: 1.8rem;
  right: 2.65rem;
  color: #ffffff;
}
.params_const5 {
  bottom: 2.58rem;
  left: 3.8rem;
  color: #ffffff;
}
.params_const6 {
  bottom: 1.7rem;
  left: 2.82rem;
  color: #ffffff;
}
</style>