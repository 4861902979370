<template>
  <div class="def_btn_1 ">
    <a-dropdown class="dark_">
      <div class="def_btn_1">
        <down-outlined />{{page_name}}
      </div>
      <template #overlay>
        <a-menu class="homeMenu">
          <a-menu-item>
            <a href="javascript:;" @click="toPage('')">全部</a>
          </a-menu-item>
          <a-menu-item>
            <a href="javascript:;" @click="toPage(1)">热源</a>
          </a-menu-item>
          <a-menu-item>
            <a href="javascript:;" @click="toPage(2)">管网</a>
          </a-menu-item>
          <a-sub-menu key="sub1" title="换热站" class="homeMenuSub">
            <a-menu-item @click="toPage(3,item)" v-for="(item,index) in reportList" :key="index">{{ item.station_name }}</a-menu-item>
          </a-sub-menu>
          <a-menu-item>
            <a href="javascript:;" @click="toPage(4)">单元阀</a>
          </a-menu-item>
          <a-menu-item>
            <a href="javascript:;" @click="toPage(5)">户阀</a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
export default {
  name: "homeMenu",
  data(){
    return {
      reportList:[],
      screen_data:{},
      ip2:"",
      page_name:"全部"

    }
  },
  mounted(){
    if(this.$route.name == "heatingMonitoring"){
      if(this.$route.query.heat_source){
        this.page_name = "热源";
      }
    }
    if(this.$route.name == "pipeNetwork"){
      this.page_name = "管网";
    }
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.ip2 = ip_monitor;
    this.getReportList()
  },
  methods:{
      //获取换热站列表
    getReportList(){
      var url = "/heating/station-report/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.reportList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    toPage(index,obj){
      switch(index){
        case "":
          var url = this.$router.resolve({
            name:"heatingMonitoring",
            query:{
              key:this.$route.query.key,
            }
          })
         window.location.href = url.href;
          location.reload();

          //this.$router.go(0)
            //this.$emit("reloadPage",false)
          break;
        case 1:

          var url2 = this.$router.resolve({
            name:"heatingMonitoring",
            query:{
              key:this.$route.query.key,
              heat_source:true
            }
          })
          window.location.href = url2.href;
          location.reload();
         // this.$router.go(0)
          //this.$emit("reloadPage",true)
          break;
        case 2:
          this.$router.push({
            name:"pipeNetwork",
            query:{
              key:this.$route.query.key
            }
          })
          break;
        case 3:
          this.$router.push({
            name:"heatingReport",
            query:{
              key:this.$route.query.key,
              name:obj.station_name,
              id:obj.station_id,
            }
          })
          break;
        case 4:
          this.$router.push({
            name:"unitValve",
            query:{
              key:this.$route.query.key
            }
          })
          break;
        case 5:
          this.$router.push({
            name:"houseIndex",
            query:{
              key:this.$route.query.key
            }
          })
          break;
      }
    },
    getData(){
      var url = "/heating/screen-sttting/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          if(JSON.stringify(res.data) == "[]"){
            this.screen_data = {
              img_src:"",
              area_pos:[],
              baseColor:"rgba(0,0,0,1)",
              layout:[],
              reyuan:""
            }
          }else{
            this.screen_data = res.data.screen_data?JSON.parse(res.data.screen_data):{
              img_src:"",
              area_pos:[],
              baseColor:"rgba(0,0,0,1)",
              layout:[],
              reyuan:""
            }
          }
        }
      }).catch((res)=>{
        console.log(res);
      })
    }
  }
}
</script>

<style>

.ant-dropdown .homeMenu.ant-dropdown-menu{
  background-color:#1865b6 ;
}
.ant-dropdown .homeMenu .ant-dropdown-menu-title-content{
  color: #ffffff;
}
.ant-dropdown .homeMenu .ant-dropdown-menu-title-content:hover{
  color: #333333;
}


.ant-dropdown .ant-dropdown-menu-submenu,.ant-dropdown .ant-dropdown-menu-submenu .ant-dropdown-menu{
  background-color:#1865b6 ;
}
.ant-dropdown .ant-dropdown-menu-submenu .ant-dropdown-menu-title-content{
  color: #ffffff;
}
.ant-dropdown .ant-dropdown-menu-submenu .ant-dropdown-menu-title-content:hover{
  color: #333333;
}
</style>

<style scoped>

:deep(.ant-dropdown-menu-item),:deep(.ant-dropdown-menu-submenu-title){
  color: #ffffff;
}
:deep(.ant-dropdown-menu-item:hover), :deep(.ant-dropdown-menu-submenu-title:hover){
  color: #333333
}

</style>