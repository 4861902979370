<template>
  <div class="page">
    <breadcrumb :crumbs="crumbs"></breadcrumb>
    <div class="pageCont">
      <div class="searchCont">
        <div class="searchItem">
          <span class="searchTit"></span>
          <a-input v-model:value="keyword" autofocus placeholder="输入关键字"/>
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="search">查询</a-button>
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="showAdd">添加</a-button>
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="showClass">管线分类</a-button>
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="drawLine">管线绘制</a-button>
        </div>
      </div>
      <a-table class="m_t_2" :columns="columns" :data-source="myData" :pagination="false" >
        <template #bodyCell="{ column, record,index }">
          <template v-if="column.dataIndex === 'caozuo'">
            <a-button type="link" @click="showEdit(index)">编辑</a-button>
            <a-popconfirm :title="record.is_line?'该管线已被绘制，是否确认删除？':'是否确定删除此项?'" ok-text="是"
                          cancel-text="否" @confirm="delFun(record)">
              <a-button type="link" danger>删除</a-button>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <a-pagination class="pagination pages_box" v-model:current="pageIndex" :pageSize="pageSize" :total="myDataNum" show-size-changer size="small" @change="onShowSizeChange"/>
    </div>

    <a-modal v-model:visible="editModal" :title="tantitle" :width="400" :maskClosable="false" :footer="null">
      <div class="modalForm">
        <a-form ref="formM" :model="editData" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <a-form-item label="排序" name="line_sort" >
            <a-input v-model:value="editData.line_sort"/>
          </a-form-item>
          <a-form-item label="名称" name="line_name" :rules="[{ required: true, message: '请输入名称!' }]">
            <a-input v-model:value="editData.line_name"/>
          </a-form-item>
          <a-form-item label="所属分类" name="line_group_id" :rules="[{ required: true, message: '请选择!' }]">
            <a-select v-model:value="editData.line_group_id" show-search :options="classList"
                      :filter-option="filterClass"
                      :fieldNames="{ label:'line_group_name', value:'line_group_id' }"></a-select>
          </a-form-item>
        </a-form>
      </div>
      <div class="modelFooter">
        <div class="modal_btns">
          <a-button type="primary" class="modal_btn" size="middle" @click="confirm_save">保存</a-button>
        </div>
      </div>
    </a-modal>

    <!--  管线分类  -->
    <a-modal v-model:visible="class_modal" title="分类列表" :width="400" :maskClosable="false" :footer="null">
      <div class="modalForm_top">
        <span>分类名称：</span>
        <a-input v-model:value="classData.line_group_name" placeholder="输入分类名称" style="width: 160px"/>
        <a-button type="primary" class="searchBtn" @click="saveClass" style="margin-left:10px">保存</a-button>
        <a-popconfirm title="是否确定删除?" ok-text="是" cancel-text="否" @confirm="delClass">
          <a-button type="primary" class="searchBtn btn_danger" style="margin-left:10px">删除</a-button>
        </a-popconfirm>
      </div>
      <div class="modalForm2" style="min-height: 300px">
        <div>
          <a-input v-model:value="class_key" placeholder="输入关键字搜索">
            <template #prefix>
              <search-outlined/>
            </template>
          </a-input>
        </div>
        <div class="itemBox">
          <a-checkbox-group v-model:value="set_class" @change="checkFun">
            <template v-for="(item,index) in classList" :key="index">
              <div class="itemBox_l" v-if="item.line_group_name.includes(class_key)">
                <a-checkbox :value="item.line_group_id" name="itemBox_l">{{
                    item.line_group_name
                  }}
                </a-checkbox>
                <span></span>
              </div>
            </template>
          </a-checkbox-group>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "drawPipelineIndex",
  data() {
    return {
      crumbs: ['地图管线绘制'],
      columns: [
        {title: '排序', dataIndex: "line_sort", key: "line_sort", align: "center"},
        {title: '名称', dataIndex: "line_name", key: "line_name", align: "center"},
        {title: '分类', dataIndex: "line_group_name", key: "line_group_name", align: "center"},
        {title: '长度', dataIndex: "line_len", key: "line_len", align: "center"},
        {title: '操作', dataIndex: "caozuo", key: "caozuo", align: "center", width: 240},
      ],
      myData: [],
      myDataNum: 0,
      pageIndex: 1,
      pageSize: 10,
      keyword: '',
      ip: "",
      editModal: false,
      tantitle: "添加",
      editData: {
        "line_name": "",
        "line_sort": 0,
        "line_data": "",
        "line_len": "",
        "device_ids": [],
        "line_group_id":""
      },
      max_height: 600,
      class_modal:false,
      classData:{
        "line_group_name": "",
        "line_group_sort": "0"
      },
      class_key:"",
      set_class:[],
      classList:[],
      classListAll:[]
    }
  },
  mounted() {
    this.max_height = $(".pageCont").height() - 150;
    this.ip = this.$axios.defaults.baseURL;
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.getData();
    this.getClass();
  },
  methods: {
    //
    getData() {
      var url = "/heating/line/info/page?pageNo=" + this.pageIndex + '&pageSize=' + this.pageSize + '&keyword=' + this.keyword;
      this.$axios.get(url).then((res) => {
        this.myData = res.data.data;
        this.myData.forEach((item)=>{
          var lineData = item.line_data?JSON.parse(item.line_data):"";
          item.is_line = false;
          if(lineData&&lineData.areapos.length>0){
            item.is_line = true;
          }
        })
        this.myDataNum = res.data.totalItems;
      }).catch((res) => {
        console.log(res);
      })
    },
    //查询
    search() {
      this.pageIndex = 1;
      this.myData = [];
      this.getData();
    },
    //分页
    onShowSizeChange(page,pageSize) {
      this.pageIndex = page;
      this.pageSize = pageSize;
      this.myData = [];
      this.myDataNum = 0;
      this.getData();
    },
    showAdd() {
      this.tantitle = "添加";
      this.editModal = true;
      this.editData={
        "line_name": "",
        "line_sort": 0,
        "line_data": "",
        "line_len": "",
        "device_ids": []
      }
    },
    showEdit(index) {
      this.tantitle = "修改";
      this.editModal = true;
      this.editData = Object.assign({}, this.myData[index]);
    },
    confirm_save() {
      this.$refs.formM.validateFields().then(() => {
        this.save();
      }, (err) => {
        console.log(err)
      })
    },
    //保存
    save() {
      var url = "";
      var editData = JSON.parse(JSON.stringify(this.editData));
      var data = editData;
      if (this.tantitle == "添加") {
        url = "/heating/line/increase";
        this.$axios.post(url, data).then((res) => {
          if (res.status == 200) {
            this.$showSuccess("保存成功！");
            this.editModal = false;
            this.getData();
          }
        }).catch((res) => {
          console.log(res);
        })
      } else {
        url = "/heating/line/modify";
        data.line_update_user_id = this.$cookie.get("user_id");
        this.classList.some((item)=>{
          if(data.line_group_id == item.line_group_id){
            data.line_group_name = item.line_group_name
            return true
          }
        })
        this.$axios.put(url, data).then((res) => {
          if (res.status == 200) {
            this.editModal = false;
            this.$showSuccess("保存成功！");
            this.getData();
          }
        }).catch((res) => {
          console.log(res);
        })
      }
    },
    delFun(record) {
      var url = "/heating/line/erasure?id=" + record.line_id;
      this.$axios.delete(url).then((res) => {
        if (res.status == 200) {
          this.$showSuccess("删除成功！");
          this.pageIndex = 1;
          this.getData();
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //画管线
    drawLine(){
      var url = this.$router.resolve({
        name:"drawLine",
        query:{
          key:this.$route.query.key
        }
      })
      window.open(url.href,"_blank");
    },
    /*
    * ====================  分组 ====================
    * */
    //显示管线分类
    showClass() {
      this.class_modal = true;
    },
    //获取管线分类
    getClass() {
      var url = '/heating/line-group/info';
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.classListAll = [].concat(res.data);
          this.classListAll.unshift({
            line_group_name: "全部",
            line_group_id: "",
          })
          this.classList = res.data;
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    checkFun() {
      console.log(this.set_class)

    },
    //保存分类
    saveClass() {
      if (!this.classData.line_group_name) {
        return this.$showError("分类名称必填！");
      }
      var url = "/heating/line-group/increase";
      var data = {
        line_group_name: this.classData.line_group_name,
        line_group_sort:"0"
      }
      this.$axios.post(url, data).then((res) => {
        if (res.status == 200) {
          this.$showSuccess("添加成功！");
          this.getClass();
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //删除分类
    delClass() {
      this.set_class.forEach((item) => {
        var url = "/heating/line-group/erasure?id=" + item;
        this.$axios.delete(url).then((res) => {
          if (res.status == 200) {
            this.getClass();
          }
        }).catch((res) => {
          console.log(res);
        })
      })
    },
    //分类搜索项
    filterClass(e, option) {
      return option.line_group_name.includes(e)
    },

  }
}
</script>

<style scoped>

</style>