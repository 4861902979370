<template>
  <a-breadcrumb separator=">">
    <a-breadcrumb-item v-for="(item,index) in crumbs" :key="index" @click="backFun(index)" :style='{"cursor":(crumbs.length>2&&index==1?"pointer":"unset")}'>{{ item }}</a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: "breadcrumb",
  props:{
    crumbs:{
      type:Array
    }
  },
  methods:{
    backFun(index){
      if(this.crumbs.length>2&&index==1){
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped>
.ant-breadcrumb{
  width: calc(100% - 40px);
  line-height: 40px;
  margin: 0 20px;
  border-bottom: 1px solid #f2f2f2;
}

</style>