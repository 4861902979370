<template>
  <div class="page" >
    <div class="viewer_full" id="viewer-container" style="width: 100%;height:100%"></div>
    <div class="viewer_left_list">
      <div class="viewer_left_caption">管线列表 </div>
      <div class="viewer_left_over">
        <div :class='{"viewer_left_item":true,"active":active===item.line_id}' v-for="(item,index) in myData"
             :key="index" @click="editFun(index)" >
          <span>{{ item.line_name}}<i class="iconfont icon-duigouxiao" v-if="item.line_data.areapos.length"></i></span>
          <i class="iconfont icon-bianji" v-if="active === item.line_id"></i>
        </div>
      </div>
    </div>
    <div class="viewer_right_from">
      <div class="viewer_right_caption">--{{ editData.line_name ? editData.line_name : '管线名称' }}--</div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">管线类型：</span>
        <a-select ref="select"  v-model:value="line_data.lineType" style="width: 120px"  >
          <a-select-option value="管线">管线</a-select-option>
          <a-select-option value="实线">实线</a-select-option>
          <a-select-option value="虚线">虚线</a-select-option>
          <a-select-option value="双实线">双实线</a-select-option>
          <a-select-option value="实虚线">实虚线</a-select-option>
          <a-select-option value="发光线">发光线</a-select-option>
        </a-select>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">颜色：</span>
        <colorpicker :color="line_data.color"  @getPickerColor="setColor" ></colorpicker>
      </div>
      <template v-if="line_data.lineType == '管线'">
        <div class="viewer_right_item">
          <span class="viewer_right_tit">管线半径：</span>
          <a-input type="text" v-model:value="line_data.radius" @input="chooseSelect"></a-input>
        </div>
        <div class="viewer_right_item">
          <span class="viewer_right_tit">显示边框：</span>
          <a-checkbox v-model:checked="line_data.outline" @change="chooseSelect"></a-checkbox>
        </div>
        <div class="viewer_right_item">
          <span class="viewer_right_tit">边框宽度：</span>
          <a-input type="text" v-model:value="line_data.outlineWidth"></a-input>
        </div>
        <div class="viewer_right_item">
          <span class="viewer_right_tit">边框颜色：</span>
          <colorpicker :color="line_data.outlineColor" @getPickerColor="setColor2"></colorpicker>
        </div>
        <div class="viewer_right_item">
          <span class="viewer_right_tit">管线切面：</span>
          <a-select v-model:value="line_data.shape" style="width: 208px" @change="chooseSelect">
            <a-select-option value="1">圆切面</a-select-option>
            <a-select-option value="119">三角形切面</a-select-option>
            <a-select-option value="90">四边形切面</a-select-option>
            <a-select-option value="60">六边形切面</a-select-option>
            <a-select-option value="40">九边形切面</a-select-option>
          </a-select>
        </div>
        <div class="viewer_right_item">
          <span class="viewer_right_tit">拐角类型：</span>
          <a-select v-model:value="line_data.cornerType" style="width: 208px" @change="chooseSelect">
            <a-select-option value="0">圆角</a-select-option>
            <a-select-option value="1">直角</a-select-option>
            <a-select-option value="2">斜角</a-select-option>
          </a-select>
        </div>
      </template>
      <template v-if="line_data.lineType != '管线'">
        <div class="viewer_right_item">
          <span class="viewer_right_tit">宽度：</span>
          <a-input type="text" v-model:value="line_data.width" @input="chooseSelect"></a-input>
        </div>
      </template>
      <template v-if="line_data.lineType == '虚线'||line_data.lineType == '实虚线'">
        <div class="viewer_right_item">
          <span class="viewer_right_tit">虚线间隔：</span>
          <a-input type="text" v-model:value="line_data.dashLength" @input="chooseSelect"></a-input>
        </div>
      </template>
      <div style="color: #52FFFF">绑定设备</div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">设备型号：</span>
        <a-select
          v-model:value="line_data.dp_id"
          show-search
          placeholder="选择型号"
          style="width: 208px"
          :options="dpList"
          :filter-option="filterOption"
          :fieldNames="{label:'dp_name',value:'dp_id'}"
          @change="handleChange"
      ></a-select>
      </div>
      <div class="viewer_right_item">
        <span class="viewer_right_tit">设备：</span>
        <a-select
          v-model:value="editData.device_ids"
          show-search
          placeholder="选择设备"
          style="width: 208px"
          :options="deviceList"
          mode="multiple"
          :fieldNames="{label:'device_name',value:'device_id'}"
          :filter-option="filterDev"
      ></a-select>
      </div>
      <div class="viewer_right_btnx m_t_2">
        <div class="viewer_btn_def" @click="save">保存</div>
        <div class="viewer_btn_cancel m_l_1" @click="clear">清除</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as turf from "@turf/turf";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import customMaterial from "@/until/customerMaterial";
dayjs.locale("zh-cn");

var Cesium = require("cesium/Cesium");
var viewer = null;
var handler = null;
var temporaryPolgon = null;
var resultPolgon = [];
var model = null;
var tiles = null;

export default {
  name: "drawPipeline",
  data() {
    return {
      crumbs: ["地图管理", "画管线"],
      color: "rgba(235, 29, 78,1)",
      isShow: false,
      cornerTypes: [Cesium.CornerType.ROUNDED, Cesium.CornerType.MITERED, Cesium.CornerType.BEVELED],
      active: '',
      myData: [],
      editData:{
        "line_name": "",
        "line_sort": 0,
        "line_data": "",
        "line_len": "",
        "device_ids": [],
        "line_id": "",
      },
      line_data: {
        "lineType":"管线",
        color: "rgba(235, 29, 78,1)",
        radius: 2,//半径
        shape: "1",//切面形状
        cornerType: "0",//拐角样式
        extrudedHeight: 4,//
        angle: 0,
        outline: false,//是否显示边框
        outlineWidth: 2,//边框宽度
        outlineColor: 'rgba(255,235,60,1)',//边框颜色
        areapos: [],
        width:4,//宽度
        dashLength:40,//虚线间隔
        intervalRatio:0.25,//虚线间隔
        dp_id:""
      },
      ip:"",
      dpList:[],
      deviceList:[]
    }
  },
  beforeUnmount(){
    if(viewer){
      console.log("destory");
      var canvas = viewer.scene.canvas;
      var gl = canvas.getContext("webgl");
      gl.getExtension('WEBGL_lose_context').loseContext();
      gl = null;
      viewer.destroy();
    }
  },
  mounted() {
    this.ip = this.$axios.defaults.baseURL;
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.initMap();
    this.getDPList();
  },
  methods: {
    initMap() {
      viewer = new Cesium.Viewer("viewer-container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });
      //viewer.scene.screenSpaceCameraController.minimumZoomDistance = 500;
      viewer.scene.fxaa = false;
      viewer.scene.postProcessStages.fxaa.enabled = false;

      this.$loadGaoDe(viewer);
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      })
      viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider();
      this.getData();
      this.clickEvent();
      this.$drawImageEntity(viewer)
      //this.drawArea();
    },
    clickEvent() {
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      //点击
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
          if (this.active === '') {
            return
          }
          if (resultPolgon[this.active]) {
           return
          }
          if (temporaryPolgon) {
            this.line_data.areapos.pop();
          }
          this.line_data.areapos.push([lon, lat,0])
          if (temporaryPolgon) {
            this.line_data.areapos.push([lon, lat,0]);
          }
          if (!temporaryPolgon && this.line_data.areapos.length >= 2) {
            this.line_data.areapos.push([lon, lat,0]);
            this.drawArea();
          }
          this.$forceUpdate();
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      //鼠标滑动
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.endPosition, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
          if (this.active === '') {
            return
          }
          if(resultPolgon[this.active]){
            return
          }

          if (temporaryPolgon) {
            this.line_data.areapos[this.line_data.areapos.length - 1] = [lon, lat,0]
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      //右键
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          if (this.active === '') {
            return
          }
          if (temporaryPolgon) {
            viewer.entities.remove(temporaryPolgon);
            temporaryPolgon = null;
            this.line_data.areapos.pop();
            this.drawAreaResult();
          }
        }
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
    drawArea() {
      if(this.active===''){
        return
      }
      if(this.line_data.areapos.length<=0){
        return
      }
      temporaryPolgon = viewer.entities.add({
        id: "area1",
        description: this.editData.map_line_name,
        position: Cesium.Cartesian3.fromDegrees(this.line_data.areapos[0][0], this.line_data.areapos[0][1], this.line_data.areapos[0][2]),
        label: {
          text: this.editData.map_line_name,
          font: '20px sans-serif',
          pixelOffset: new Cesium.Cartesian2(-10, -10),
          fillColor: Cesium.Color.fromCssColorString('#1890ff'),
        },
        polylineVolume: this.line_data.lineType=="管线"?{
          positions: new Cesium.CallbackProperty(() => {
            // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
            return Cesium.Cartesian3.fromDegreesArrayHeights(this.line_data.areapos.flat());
          }, false),
          cornerType: this.cornerTypes[this.line_data.cornerType],
          material: Cesium.Color.fromCssColorString(this.line_data.color)/*new Cesium.ImageMaterialProperty({
            image:require("../assets/images/test.png")
          })*/,//Cesium.Color.fromCssColorString(this.color),
          shape: this.computeCircle(this.line_data.angle, this.line_data.shape, this.line_data.radius),
          outline: this.line_data.outline,
          outlineWidth: this.line_data.outlineWidth,
          outlineColor: Cesium.Color.fromCssColorString(this.line_data.outlineColor)
        }:{},
        polyline: this.line_data.lineType!="管线"?{
          positions: new Cesium.CallbackProperty(() => {
            // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
            return Cesium.Cartesian3.fromDegreesArrayHeights(this.line_data.areapos.flat());
          }, false),
          width: this.line_data.width,
          material:this.changeMaterial(this.line_data)
        }:{}
      });
      if(resultPolgon[this.active]){
        resultPolgon[this.active].show = false;
      }
    },
    drawAreaResult() {
      if(resultPolgon[this.active]){
        //viewer.entities.remove(resultPolgon[this.active])
        return
      }
      if(this.line_data.areapos.length<=0){
        return
      }
      resultPolgon[this.active] = viewer.entities.add({
        id: "area" + this.active,
        description: this.editData.map_line_name,
        position: Cesium.Cartesian3.fromDegrees(this.line_data.areapos[0][0], this.line_data.areapos[0][1], this.line_data.areapos[0][2]),
        label: {
          text: this.editData.map_line_name,
          font: '20px sans-serif',
          pixelOffset: new Cesium.Cartesian2(-10, -10),
          fillColor: Cesium.Color.fromCssColorString('#1890ff'),
        },
        polylineVolume: this.line_data.lineType=="管线"?{
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(this.line_data.areapos.flat()),
          cornerType: this.cornerTypes[this.line_data.cornerType],
          material: Cesium.Color.fromCssColorString(this.line_data.color)/*new Cesium.ImageMaterialProperty({
            image:require("../assets/images/test.png")
          })*/,//Cesium.Color.fromCssColorString(this.color),
          shape: this.computeCircle(this.line_data.angle, this.line_data.shape, this.line_data.radius),
          outline: this.line_data.outline,
          outlineWidth: this.line_data.outlineWidth,
          outlineColor: Cesium.Color.fromCssColorString(this.line_data.outlineColor)
        }:{},
        polyline: this.line_data.lineType!="管线"?{
          positions: new Cesium.CallbackProperty(() => {
            // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
            return Cesium.Cartesian3.fromDegreesArrayHeights(this.line_data.areapos.flat());
          }, false),
          width: this.line_data.width,
          material: this.changeMaterial(this.line_data),
        }:{}
      });
      //console.log(JSON.stringify(this.editData.areapos))
    },
    chooseSelect(){
      if(this.active!==''){
        if(temporaryPolgon){
          temporaryPolgon.polylineVolume.material = Cesium.Color.fromCssColorString(this.line_data.color)
        }
        if(resultPolgon[this.active]){
          resultPolgon[this.active].polylineVolume.material = Cesium.Color.fromCssColorString(this.line_data.color)
        }
        //this.drawArea();
      }
    },
    setColor(e) {
      this.line_data.color = e;
      this.chooseSelect();
    },
    setColor2(e) {
      this.line_data.outlineColor = e;
      this.chooseSelect();
    },
    computeCircle(angle, shape, radius) {
      var positions = [];
      for (var i = parseInt(angle); i < (360 + parseInt(angle)); i = i + parseInt(shape)) {
        var radian = Cesium.Math.toRadians(i);
        var x = radius * Math.cos(radian);
        var y = radius * Math.sin(radian);
        positions.push(new Cesium.Cartesian2(x, y));
      }
      return positions;
    },
    editFun(index) {
      this.active = this.myData[index].line_id;
      this.editData = JSON.parse(JSON.stringify(this.myData[index]));
      this.line_data = this.editData.line_data;
      if(this.line_data.dp_id){
        this.getDeviceList()
      }
      if(temporaryPolgon){
        viewer.entities.remove(temporaryPolgon);
        temporaryPolgon = null;
      }
      if(this.line_data.areapos.length>0){
        var center = this.$getCenter(this.line_data.areapos)
        viewer.camera.setView({
          destination:Cesium.Cartesian3.fromDegrees(center.lon,center.lat,1000)
        })
      }
      this.drawAllResult();
      this.drawArea();
    },
    save() {
      if(this.active!==''){
        var url = "/heating/line/modify";
        this.getLen();
        var data = JSON.parse(JSON.stringify(this.editData));
        data.line_data = JSON.stringify(this.line_data);
        data.line_update_user_id = this.$cookie.get("user_id");
        this.$axios.put(url,data).then((res)=>{
          if(res.status ==200){
            if(temporaryPolgon){
              viewer.entities.remove(temporaryPolgon);
              temporaryPolgon = null;
            }
            this.$showSuccess("保存成功！");
            this.getData();
          }
        }).catch((res)=>{
          console.log(res);
        })

      }
    },
    clear(){
      if(this.active!==''){
        this.line_data.areapos = [];
        if(temporaryPolgon){
          viewer.entities.remove(temporaryPolgon);
          temporaryPolgon = null;
        }
        if(resultPolgon[this.active]){
          viewer.entities.remove(resultPolgon[this.active]);
          resultPolgon[this.active] = null;
        }
      }
    },
    drawAllResult(fun) {
      var _this = this;
      var pos = [];
      this.myData.forEach((item,index)=>{
        pos = pos.concat(item.line_data.areapos);
        if(resultPolgon[item.line_id]){
          viewer.entities.remove(resultPolgon[item.line_id]);
          resultPolgon[item.line_id] = null;
        }
      });
      resultPolgon = [];
      this.myData.forEach((item, index) => {
        (function(obj,i){
          setTimeout(()=>{
            var resultArea = '';
            if (obj.line_data.areapos.length > 0) {
              resultArea = viewer.entities.add({
                id: "area"+obj.line_id,
                description: obj.map_line_name,
                position: Cesium.Cartesian3.fromDegrees(obj.line_data.areapos[0][0], obj.line_data.areapos[0][1], obj.line_data.areapos[0][2]),
                label: {
                  text: obj.map_line_name,
                  font: '20px sans-serif',
                  pixelOffset: new Cesium.Cartesian2(-10, -10),
                  fillColor: Cesium.Color.fromCssColorString('#1890ff'),
                  scaleByDistance:new Cesium.NearFarScalar(10, 1, 100000, 0.2)
                },
                polylineVolume:obj.line_data.lineType=="管线"? {
                  positions: Cesium.Cartesian3.fromDegreesArrayHeights(obj.line_data.areapos.flat()),
                  cornerType: _this.cornerTypes[obj.line_data.cornerType],
                  material: Cesium.Color.fromCssColorString(obj.line_data.color)/*new Cesium.ImageMaterialProperty({
            image:require("../assets/images/test.png")
          })*/,//Cesium.Color.fromCssColorString(this.color),
                  shape: _this.computeCircle(obj.line_data.angle, obj.line_data.shape, obj.line_data.radius),
                  outline: obj.line_data.outline,
                  outlineWidth: obj.line_data.outlineWidth,
                  outlineColor: Cesium.Color.fromCssColorString(obj.line_data.outlineColor)
                }:{},
                polyline: obj.line_data.lineType!="管线"?{
                  positions: new Cesium.CallbackProperty(() => {
                    // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
                    return Cesium.Cartesian3.fromDegreesArrayHeights(obj.line_data.areapos.flat());
                  }, false),
                  width: obj.line_data.width,
                  material:_this.changeMaterial(obj.line_data)
                }:{}
              });
            }
            resultPolgon[obj.line_id]=(resultArea);
          })
        })(item,index)
      });
      if(fun){
        fun(pos)
      }
    },
    getData(){
      var url = "/heating/line/info";
      //var url = "/heating/line/info/page?pageNo=1&pageSize=10";
      this.$axios.get(url).then((res)=>{
        this.myData = res.data;
        this.myData.forEach((item)=>{
          item.line_data = item.line_data?JSON.parse(item.line_data):{
            "lineType":"管线",
            color: "rgba(235, 29, 78,1)",
            radius: 2,//半径
            shape: "1",//切面形状
            cornerType: "0",//拐角样式
            extrudedHeight: 4,//
            angle: 0,
            outline: false,//是否显示边框
            outlineWidth: 2,//边框宽度
            outlineColor: 'rgba(255,235,60,1)',//边框颜色
            areapos: [],
            width:4,//宽度
            dashLength:40,//虚线间隔
            intervalRatio:0.25,//虚线间隔
            dp_id:""
          }
        });
        this.drawAllResult();
      }).catch(res=>{
        console.log(res);
      })
    },
    changeMaterial(line_data){
      var material = null;
      if(line_data.lineType=="实线"){
        material = this.solidLine(line_data);
      }
      if(line_data.lineType=="虚线"){
        material = this.dashedLine(line_data);
      }
      if(line_data.lineType=="双实线"){
        material = this.doubleLine(line_data);
      }
      if(line_data.lineType=="实虚线"){
        material = this.doubleDashLine(line_data);
      }
      if(line_data.lineType=="发光线"){
        material = this.glowLine(line_data);
      }
      return material
    },
    //实线
    solidLine(line_data){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        gapColor:Cesium.Color.fromCssColorString(line_data.color),
        // dashLength:40,
        //dashPattern: 255
      })
    },
    //虚线
    dashedLine(line_data){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        gapColor:Cesium.Color.TRANSPARENT,
        dashLength:line_data.dashLength,
        //dashPattern: 255
      })
    },
    //双实线
    doubleLine(line_data){
      return customMaterial.DoubleSolidLine({
        color:Cesium.Color.fromCssColorString(line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    //实虚线
    doubleDashLine(line_data){
      return customMaterial.DashedSolidLine({
        color:Cesium.Color.fromCssColorString(line_data.color),
        dashLength:parseFloat(line_data.dashLength),//虚线间隔长度
        dashPattern:255,
        intervalRatio:line_data.intervalRatio,//两条线之间的空隙  占宽度的百分比
        v_polylineAngle:90,
        maskLength:10,
        sideWise:false,
      })
    },
    //发光线
    glowLine(line_data){
      return new Cesium.PolylineGlowMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    //获取型号列表
    getDPList(){

      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          var Data = res.data;
          var url2 = "/heating/device-model/easy-info";
          this.$axios.get(url2).then((res)=>{
            if(res.status == 200){
              this.dpList = [];
              res.data.forEach((item)=>{
                if(item.device_model_enable){
                  Data.some((val)=>{
                    if(val.dp_id == item.device_model_id){
                      item = Object.assign(item,val);
                      return true
                    }
                  })
                  this.dpList.push(item);
                }
              })
            }
          }).catch((res)=>{
            console.log(res);
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterOption(e,option){
      return option.dp_name.includes(e);
    },
    handleChange(){
      this.editData.device_ids = [];
      this.getDeviceList()
    },
    //设备列表
    getDeviceList(){
      var url = "/heating/zeiot/device/protocol/list?dp_id="+this.line_data.dp_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.deviceList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    getLen(){
      if(this.line_data.areapos.length){
        var len = this.$get_len(this.line_data.areapos)
        this.editData.line_len = len?len.toFixed(2):"0";
      }else{
        this.editData.line_len = "0";
      }
    },
    filterDev(e,option){
      return option.device_name.includes(e);
    }


  }
}
</script>

<style scoped lang="less">
@import "../../assets/css/point.less";
.icon-bianji{
  color: @hight_color;
  padding-left: 10px;
}
.viewer_right_from{
  top: 60px;
}
.icon-duigouxiao{
  padding: 0 10px;
}
</style>