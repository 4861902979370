<template>
  <div class="main_page">
    <main-header :title="title"></main-header>
    <div class="header_time" style="right: 3rem">
      <i class="iconfont icon-rili"></i>
      <span>{{time}}</span>
    </div>
    <homeBtn :show_back="true" ></homeBtn>
    <div class="main_container">
      <div class="map_container" id="map_container" v-if="!heat_source"></div>
      <iframe style="width: 100%;height: 100%;" frameborder="none" scrolling="none" :src="ip2+'/template/project/monitoring-list/simulated_scene.html?salt='+user_salt+'&monitor_id='+screen_data.reyuan" v-else></iframe>
      <div class="left_btns">
        <div class="def_btn_1" @click="hideSide"><left-outlined /><right-outlined />
          {{ hide_side?"展开两侧":"收起两侧" }}</div>
        <homeMenu ></homeMenu><!--@reloadPage="load_page"-->
<!--        <div class="def_btn_1"><swap-outlined />3D模式</div>-->
      </div>

      <div :class='{"contCard":true,"hideLeft":(hide_side&&(item.align=="left"||item.align=="bottom"||item.align=="")),"hideRight":(hide_side&&item.align=="right")}' v-for="(item,index) in layout" :key="index" :style='{"width":(item.w)+"px","height":(item.h)+"px","left":(item.align=="left"||item.align=="bottom"||item.align==""?(item.x+"px"):"unset"),"right":(item.align=="right"?((item.x2)+"px"):"unset"),"top":(item.align!="bottom"?(item.y+"px"):"unset"),"bottom":(item.align == "bottom"?(item.y2+"px"):"unset")}'>

        <div class="def_btn_1" style=" position: absolute; right: 0; color: #ffffff; display: flex;cursor:pointer " v-if="item.type=='报警'" @click="changeAlarmType(index)"><swap-outlined style="margin-right:4px" />{{alarm_type=="普通"?"报警模式":"普通模式"}}</div>
        <div class="contCard_tit"
             :style='{"color":item.color,"font-size":item.size+"px","font-style":item.style,"font-family":item.family}'>
          {{ item.tit }}
        </div>
        <!-- 组态-->
        <div class="contCard_cont" v-if="item.type=='组态'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
                      <iframe :src="ip2+item.itemData.monitor_src" style="width: 100%;height: 100%" frameborder="no"
                              scrolling="no"></iframe>
        </div>
        <!--  设备 卡片 -->
        <div class="contCard_cont" v-if="item.type=='天气'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
          <div class="tianqiCard">
            <div>{{ item.itemData.pp_data?item.itemData.pp_data["温度"].value:0 }}<small>{{ item.itemData.pp_data?item.itemData.pp_data["温度"].unit:"" }}</small></div>
            <div>
              <i class="iconfont icon-shidu"></i><span>湿度</span><span>{{ item.itemData.pp_data?item.itemData.pp_data["湿度"].value:0 }}{{item.itemData.pp_data?item.itemData.pp_data["湿度"].unit:""}}</span>
            </div>
          </div>

        </div>
        <!--  视频         -->
        <div class="contCard_cont" v-if="item.type=='视频'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat","padding":"10px 10px 15px"}'>
          <div class="video_card video_card1" v-if="item.itemData.video_type==1">
            <div class="video_c" style="width: 100%;height: 100%;">
              <video_s id="video_1" :src="item.itemData.videoList[0]"></video_s>
            </div>
          </div>
          <div class="video_card video_card2" v-if="item.itemData.video_type==2">
            <div class="video_c"><video_s id="video_1" :src="item.itemData.videoList[0]"></video_s></div>
            <div class="video_c"><video_s id="video_2" :src="item.itemData.videoList[1]"></video_s></div>
          </div>
          <div class="video_card video_card3" v-if="item.itemData.video_type==3">
            <div class="video_c"><video_s id="video_1" :src="item.itemData.videoList[0]"></video_s></div>
            <div class="video_c2">
              <div class="video_c"><video_s id="video_2" :src="item.itemData.videoList[1]"></video_s></div>
              <div class="video_c"><video_s id="video_3" :src="item.itemData.videoList[2]"></video_s></div>
            </div>
          </div>
          <div class="video_card video_card4" v-if="item.itemData.video_type==4">
            <div class="video_c"><video_s id="video_1" :src="item.itemData.videoList[0]"></video_s></div>
            <div class="video_c"><video_s id="video_2" :src="item.itemData.videoList[1]"></video_s></div>
            <div class="video_c"><video_s id="video_3" :src="item.itemData.videoList[2]"></video_s></div>
            <div class="video_c"><video_s id="video_4" :src="item.itemData.videoList[3]"></video_s></div>
          </div>
        </div>
        <!--  报警   -->
        <div class="contCard_cont" v-if="item.type=='报警'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
          <div class="alarm_card" >
            <div class="alarm_table_head">
              <div>采集值</div>
              <div>发生时间</div>
            </div>
            <div class="alarm_table_body">
              <div class="alarm_table_tr" v-for="(val,key) in item.itemData.alarmList" :key="key">
                <div :title="val.al_content" :style='{"color":(val.al_is_stop?"#ffffff":"red")}' >{{ val.al_content }}</div>
                <div>{{ val.al_start_time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import customMaterial from "@/until/customerMaterial";
import video_s from "../components/video"

var Cesium = require("cesium/Cesium");
var viewer = null;
var resultLine = [];
var BillboardCollection = null;
var PointCollection = null;
var LabelCollection = null;
var BillboardCollectionObj = {};
var PointCollectionObj = {};
var LabelCollectionObj = {};
var handler = null;
export default {
  name: "heatingMonitoring",
  components:{
    video_s
  },
  data(){
    return {
      time:"",
      title:"智慧供热监控大屏",
      hide_side:false,
      menu_id:"",
      lineData:[],
      screen_data:{},
      deviceList:[],
      line_dp_ids:[],
      layout:[],
      cornerTypes: [Cesium.CornerType.ROUNDED, Cesium.CornerType.MITERED, Cesium.CornerType.BEVELED],
      ip2:"",
      heat_source:false,
      user_salt:"",
      pointList:[],
      timer_alarm:null,
      timer_tq:null,
      alarm_type:"普通",
      alarmDevList:[]
    }
  },
  beforeUnmount(){
    clearInterval(this.timer_alarm);
    clearInterval(this.timer_tq);
    if(viewer){
      console.log("destory");
      var canvas = viewer.scene.canvas;
      var gl = canvas.getContext("webgl");
      gl.getExtension('WEBGL_lose_context').loseContext();
      gl = null;
      viewer.destroy();
    }
  },
  mounted(){
    clearInterval(this.timer_alarm);
    clearInterval(this.timer_tq);
    this.heat_source = this.$route.query.heat_source;
    this.ip2 = this.$axios_ip.defaults.baseURL;
    this.time = this.$getTime(new Date()).t3;
    setInterval(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.menu_id = this.$route.query.key;
    this.user_salt = this.$cookie.get("user_salt")
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.menu_id;

    if(this.heat_source){
      this.$drawImageEntity(viewer,(data)=>{
        this.screen_data = data;
        this.layout = data.layout?data.layout:[];
        this.layout.forEach((item,index)=>{
          if(item.type=="视频"){
            item.itemData.videoList.forEach((val)=>{
              if(val){
                if(val.includes("live.mp4")){
                  //http://192.168.5.199:8080/rtp/44010200491117240816_34020000001340000001.live.mp4

                  this.getVideoSrc(val,()=>{

                  })
                }
              }
            })
          }
          if(item.type=="报警"){
            this.getAlarmList(item.itemData.alarm_id,index)
            this.timer_alarm = setInterval(()=>{
              this.getAlarmList(item.itemData.alarm_id,index)
            },30000)
          }
          if(item.type=="天气"){
            this.getDevReal(item.itemData.dev_dev,(realData)=>{
              var pp_data = {
                湿度:{
                  value:0,
                  unit:""
                },
                温度:{
                  value:0,
                  unit:""
                }
              };
              realData.forEach((item)=>{
                if(item.pp_name == "温度"){
                  pp_data["温度"].value = item.pp_value;
                  pp_data["温度"].unit = item.pp_unit;
                }
                if(item.pp_name == "湿度"){
                  pp_data["湿度"].value = item.pp_value;
                  pp_data["湿度"].unit = item.pp_unit;
                }
              })
              item.itemData.pp_data = pp_data;
            })
            this.timer_tq = setInterval(()=>{
              this.getDevReal(item.itemData.dev_dev,(realData)=>{
                var pp_data = {
                  湿度:{
                    value:0,
                    unit:""
                  },
                  温度:{
                    value:0,
                    unit:""
                  }
                };
                realData.forEach((item)=>{
                  if(item.pp_name == "温度"){
                    pp_data["温度"].value = item.pp_value;
                    pp_data["温度"].unit = item.pp_unit;
                  }
                  if(item.pp_name == "湿度"){
                    pp_data["湿度"].value = item.pp_value;
                    pp_data["湿度"].unit = item.pp_unit;
                  }
                })
                item.itemData.pp_data = pp_data;
              })
            },30000)
          }
        })
      },true)
    }else{
      this.initMap(()=>{
        this.getLineData();
        this.$drawImageEntity(viewer,(data)=>{
          this.screen_data = data;
          this.layout = data.layout?data.layout:[];
          this.layout.forEach((item,index)=>{
            if(item.type=="视频"){
              item.itemData.videoList.forEach((val)=>{
                if(val){
                  if(val.includes("live.mp4")){
                    //http://192.168.5.199:8080/rtp/44010200491117240816_34020000001340000001.live.mp4

                    this.getVideoSrc(val,()=>{

                    })
                  }
                }
              })
            }
            if(item.type=="报警"){
              this.getAlarmList(item.itemData.alarm_id,index)
              this.timer_alarm = setInterval(()=>{
                this.getAlarmList(item.itemData.alarm_id,index)
              },30000)
            }
            if(item.type=="天气"){
              this.getDevReal(item.itemData.dev_dev,(realData)=>{
                var pp_data = {
                  湿度:{
                    value:0,
                    unit:""
                  },
                  温度:{
                    value:0,
                    unit:""
                  }
                };
                realData.forEach((item)=>{
                  if(item.pp_name == "温度"){
                    pp_data["温度"].value = item.pp_value;
                    pp_data["温度"].unit = item.pp_unit;
                  }
                  if(item.pp_name == "湿度"){
                    pp_data["湿度"].value = item.pp_value;
                    pp_data["湿度"].unit = item.pp_unit;
                  }
                })
                item.itemData.pp_data = pp_data;
              })
              this.timer_tq = setInterval(()=>{
                this.getDevReal(item.itemData.dev_dev,(realData)=>{
                  var pp_data = {
                    湿度:{
                      value:0,
                      unit:""
                    },
                    温度:{
                      value:0,
                      unit:""
                    }
                  };
                  realData.forEach((item)=>{
                    if(item.pp_name == "温度"){
                      pp_data["温度"].value = item.pp_value;
                      pp_data["温度"].unit = item.pp_unit;
                    }
                    if(item.pp_name == "湿度"){
                      pp_data["湿度"].value = item.pp_value;
                      pp_data["湿度"].unit = item.pp_unit;
                    }
                  })
                  item.itemData.pp_data = pp_data;
                })
              },30000)
            }
          })
        })
        this.drawPoint();
        this.getDPList();
      })
    }
    this.getAlarmDev();
  },
  methods:{
    load_page(a){
      //this.heat_source = a;
    },
    //初始化地图
    initMap(fun) {
      viewer = new Cesium.Viewer("map_container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        shouldAnimate: true,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });

      viewer.scene.fxaa = false;
      viewer.scene.postProcessStages.fxaa.enabled = false;
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      });
      this.$loadGaoDe(viewer);
      this.clickEvent()
      if(fun){
        fun()
      }
    },
    //点击事件
    clickEvent(){
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction((event)=>{
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if(cartesian){
          let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
          let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值
        }

        let pick = viewer.scene.pick(event.position); // 获取 pick 拾取对象
        this.jumpPage(pick);
      },Cesium.ScreenSpaceEventType.LEFT_CLICK)
    },
    jumpPage(pick){
      if(pick&&pick.id&&pick.primitive&&pick.primitive instanceof Cesium.Billboard){
        if(pick.id.includes("bill_")){
          var id = pick.id.split("bill_")[1];
          var obj = {};
          this.pointList.some((item)=>{
            if(item.device_id == id){
              obj = item;
              var url = "";
              if(obj.device_model_type == 1){
                this.getDeviceInfo(id)
              }
              if(obj.device_model_type == 2){
                url = this.$router.resolve({name:"smartHeatingMonitoringSystem",
                  query:{
                    key:this.$route.query.key,
                    name:obj.device_name,
                    id:obj.device_id,
                    dp_id:obj.device_model_id
                  }})
                window.open(url.href,"_blank");
              }
              return true
            }
          })
        }
      }
    },
    hideSide(){
      this.hide_side = !this.hide_side;
    },
    /*
    * ======================= 管线 =========================
    * */
    getLineData(){
      var url = "/heating/line/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.lineData = res.data;
          this.lineData.forEach((item)=>{
            item.line_data = item.line_data?JSON.parse(item.line_data):{
              "lineType":"管线",
              color: "rgba(235, 29, 78,1)",
              radius: 2,//半径
              shape: "1",//切面形状
              cornerType: "0",//拐角样式
              extrudedHeight: 4,//
              angle: 0,
              outline: false,//是否显示边框
              outlineWidth: 2,//边框宽度
              outlineColor: 'rgba(255,235,60,1)',//边框颜色
              areapos: [],
              width:4,//宽度
              dashLength:40,//虚线间隔
              intervalRatio:0.25,//虚线间隔
              dp_id:""
            }
          });
          this.drawAllResult()
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    drawAllResult(fun) {
      var _this = this;
      var pos = [];
      this.lineData.forEach((item,index)=>{
        pos = pos.concat(item.line_data.areapos);
        if(resultLine[item.line_id]){
          viewer.entities.remove(resultLine[item.line_id]);
          resultLine[item.line_id] = null;
        }
      });
      resultLine = [];
      this.lineData.forEach((item, index) => {
        (function(obj,i){
          setTimeout(()=>{
            var resultArea = '';
            if (obj.line_data.areapos.length > 0) {
              resultArea = viewer.entities.add({
                id: "area"+obj.line_id,
                description: obj.map_line_name,
                position: Cesium.Cartesian3.fromDegrees(obj.line_data.areapos[0][0], obj.line_data.areapos[0][1], obj.line_data.areapos[0][2]),
                label: {
                  text: obj.map_line_name,
                  font: '20px sans-serif',
                  pixelOffset: new Cesium.Cartesian2(-10, -10),
                  fillColor: Cesium.Color.fromCssColorString('#1890ff'),
                  scaleByDistance:new Cesium.NearFarScalar(10, 1, 100000, 0.2)
                },
                polylineVolume:obj.line_data.lineType=="管线"? {
                  positions: Cesium.Cartesian3.fromDegreesArrayHeights(obj.line_data.areapos.flat()),
                  cornerType: _this.cornerTypes[obj.line_data.cornerType],
                  material: Cesium.Color.fromCssColorString(obj.line_data.color)/*new Cesium.ImageMaterialProperty({
            image:require("../assets/images/test.png")
          })*/,//Cesium.Color.fromCssColorString(this.color),
                  shape: _this.computeCircle(obj.line_data.angle, obj.line_data.shape, obj.line_data.radius),
                  outline: obj.line_data.outline,
                  outlineWidth: obj.line_data.outlineWidth,
                  outlineColor: Cesium.Color.fromCssColorString(obj.line_data.outlineColor)
                }:{},
                polyline: obj.line_data.lineType!="管线"?{
                  positions: new Cesium.CallbackProperty(() => {
                    // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
                    return Cesium.Cartesian3.fromDegreesArrayHeights(obj.line_data.areapos.flat());
                  }, false),
                  width: obj.line_data.width,
                  material:_this.changeMaterial(obj.line_data)
                }:{}
              });
            }
            resultLine[obj.line_id]=(resultArea);
          })
        })(item,index)
      });
      if(fun){
        fun(pos)
      }
    },
    changeMaterial(line_data){
      var material = null;
      if(line_data.lineType=="实线"){
        material = this.solidLine(line_data);
      }
      if(line_data.lineType=="虚线"){
        material = this.dashedLine(line_data);
      }
      if(line_data.lineType=="双实线"){
        material = this.doubleLine(line_data);
      }
      if(line_data.lineType=="实虚线"){
        material = this.doubleDashLine(line_data);
      }
      if(line_data.lineType=="发光线"){
        material = this.glowLine(line_data);
      }
      return material
    },
    //实线
    solidLine(line_data){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        gapColor:Cesium.Color.fromCssColorString(line_data.color),
        // dashLength:40,
        //dashPattern: 255
      })
    },
    //虚线
    dashedLine(line_data){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        gapColor:Cesium.Color.TRANSPARENT,
        dashLength:line_data.dashLength,
        //dashPattern: 255
      })
    },
    //双实线
    doubleLine(line_data){
      return customMaterial.DoubleSolidLine({
        color:Cesium.Color.fromCssColorString(line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    //实虚线
    doubleDashLine(line_data){
      return customMaterial.DashedSolidLine({
        color:Cesium.Color.fromCssColorString(line_data.color),
        dashLength:parseFloat(line_data.dashLength),//虚线间隔长度
        dashPattern:255,
        intervalRatio:line_data.intervalRatio,//两条线之间的空隙  占宽度的百分比
        v_polylineAngle:90,
        maskLength:10,
        sideWise:false,
      })
    },
    //发光线
    glowLine(line_data){
      return new Cesium.PolylineGlowMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    computeCircle(angle, shape, radius) {
      var positions = [];
      for (var i = parseInt(angle); i < (360 + parseInt(angle)); i = i + parseInt(shape)) {
        var radian = Cesium.Math.toRadians(i);
        var x = radius * Math.cos(radian);
        var y = radius * Math.sin(radian);
        positions.push(new Cesium.Cartesian2(x, y));
      }
      return positions;
    },
    /*
    * ===================== 设备标点 =============================
    * */
    drawPoint() {
      BillboardCollection = new Cesium.BillboardCollection();
      viewer.scene.primitives.add(BillboardCollection);
      LabelCollection = new Cesium.LabelCollection();
      viewer.scene.primitives.add(LabelCollection);
      PointCollection = new Cesium.PointPrimitiveCollection();
      viewer.scene.primitives.add(PointCollection);
    },
    //获取型号列表
    getDPList(){
      var url = "/heating/device-model/easy-info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.deviceList = [];
          res.data.forEach((item)=>{
            if(item.device_model_enable){
              this.getDeviceList(item.device_model_id,item)
            }
          })
        }
      }).catch((res)=>{

      })
    },
    //获取型号设备列表
    getDeviceList(dp_id,obj){
      var url = "/heating/zeiot/device/protocol/list?dp_id="+dp_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          var data = res.data;
          data.forEach((item)=>{
            item = Object.assign(item,obj)
          })
          this.deviceList = this.deviceList.concat(data)
          this.getDevicePoint(dp_id)
        }
      }).catch((res)=>{
        console.log(res)
      })
    },
    //获取设备标点列表
    getDevicePoint(dp_id){
      var url = "/heating/custom/mark/info/all?mark_type=0&mark_source_id="+dp_id;
      this.$axios.post(url).then((res)=>{
        var DATA = res.data;
        DATA.forEach((item)=>{
          this.deviceList.some((val)=>{
            if(val.device_id == item.mark_id){
              item = Object.assign(item,val);
              item.mark_lon = item.mark_lon?parseFloat(item.mark_lon):"";
              item.mark_lat = item.mark_lat?parseFloat(item.mark_lat):"";
              item.mark_height = item.mark_height?parseFloat(item.mark_height):0;
              if (item.mark_lon && item.mark_lat) {
                var obj = this.setBillLabel(item);
                BillboardCollectionObj[item.mark_id] = BillboardCollection.add(obj.bill);
                LabelCollectionObj[item.mark_id] = LabelCollection.add(obj.label)
                PointCollectionObj[item.mark_id] = PointCollection.add(obj.point)
              }
            }
          })
        })
        this.pointList = this.pointList.concat(DATA);
      }).catch((res)=>{

      })
    },
    setBillLabel(obj) {
      var bill = null;
      var label = null;
      var point = null;
      bill = {
        show: true,
        id: "bill_" + obj.mark_id,
        width: 11,
        height: 11,
        position: Cesium.Cartesian3.fromDegrees(obj.mark_lon, obj.mark_lat, obj.mark_height ? obj.mark_height : 0),
        image: obj.device_model_data ? obj.device_model_data : require("../assets/images/point.png"),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        color: Cesium.Color.fromCssColorString("rgba(82,255,255,0.7)")
      }
      if (obj.device_model_data) {
        delete bill.color
      }
      label = {
        show: obj.device_model_display_name?true:false,
        id: "label_" + obj.mark_id,
        text: obj.device_name,
        font: '14px sans-serif',
        position: Cesium.Cartesian3.fromDegrees(obj.mark_lon, obj.mark_lat, obj.mark_height ? obj.mark_height : 0),
        color: Cesium.Color.fromCssColorString("#ffffff"),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        pixelOffset: new Cesium.Cartesian2(0, -16)
      }

      point = {
        show:false,
        id:"point_"+obj.mark_id,
        position : Cesium.Cartesian3.fromDegrees(obj.mark_lon, obj.mark_lat, obj.mark_height ? obj.mark_height : 0),
        color : Cesium.Color.GRAY,
        pixelSize:11
      }

      return {bill, label,point}
    },
    /*
    * ===============  换热站=================
    * */

    getVideoSrc(src,fun){
      var s1 = src.split("/");
      var s2 = (s1[s1.length-1].split(".")[0]).split("_");
      var device_id = s2[0];
      var channel = s2[1];
      var url = "/api/gb/start/play?deviceid="+device_id+'&channel='+channel;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(fun){
          fun()
        }
      })
    },
    /*
    * ================== 报警列表 =============================
    * */
    //获取报警列表
    getAlarmList(alarm_id,INDEX){
      var start = this.$getTime((new Date()).getTime()-1000*60*60*24*10).t1;
      var end = this.$getTime(new Date()).t1;
      var url = "/heating/zeiot/project/alarms-EquipmentMaintenance/device?alarm_id="+alarm_id+'&PageSize=99'+'&PageNo=1'+'&al_is_handle=0'+'&begin='+start+"&end="+end;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.layout[INDEX].itemData.alarmList = res.data.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //切换模式
    changeAlarmType(index){
      var obj = this.layout[index];
      this.alarm_type = this.alarm_type=="普通"?"报警":"普通";
      if(this.alarm_type == "报警"){
        for(var key in BillboardCollectionObj){
          BillboardCollectionObj[key].show = false;
        }
        for(var key in PointCollectionObj){
          console.log(key)
          PointCollectionObj[key].show = true;
          this.alarmDevList.some((item)=>{
            if(item.device_id == key){
              if(item.device_is_offline){//离线
                PointCollectionObj[key].color = Cesium.Color.fromCssColorString("#ff6464");
              }else{
                if(item.is_alarm){//报警
                  PointCollectionObj[key].color = Cesium.Color.fromCssColorString("#ffc664");
                }else{
                  PointCollectionObj[key].color = Cesium.Color.fromCssColorString("#64ff9b");
                }
              }
              return true
            }
          })
        }
      }else{
        for(var key in BillboardCollectionObj){
          BillboardCollectionObj[key].show = true;
        }
        for(var key in PointCollectionObj){
          PointCollectionObj[key].show = false;
        }
      }
    },
    //获取报警设备列表、
    getAlarmDev(){
      var url = "/api/project/org/EquipmentMaintenance-deivce-type-lis"+'?org_id='+this.$cookie.get("user_org_id");
      this.$getZeiotApi(url,"GET","",(res)=>{
        if(res.status == 200){
          this.alarmDevList = res.data;
        }
      })
    },
    /*
    * ================   设备实时数据 ============================
    * */
    getDevReal(device_id,fun){
      var url = "/heating/zeiot/device/read-time?id="+device_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          if(fun){
            fun(res.data);
          }
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取设备信息
    getDeviceInfo(device_id){
      var url = "/api/user/devices/"+device_id;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          if(res.data.device_monitor_id){
            var ip = this.$axios_ip.defaults.baseURL;
            window.open(ip+'/template/project/monitoring-list/simulated_scene.html?monitor_id=' + res.data.device_monitor_id + '&salt=' + this.$cookie.get("user_salt") ,"_blank")
          }else{

          }
        }
      })
    }
  }
}
</script>
<style>
</style>

<style>
</style>
<style scoped>



</style>