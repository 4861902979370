<template>
<div class="mian_header">
  <div class="main_header_tit"><span>{{ title }}</span></div>

</div>
</template>

<script>
export default {
  name: "mainHeader",
  props:{
    title:{
      type:String,
      default:"智慧供热综合服务平台"
    }
  }
}
</script>

<style scoped lang="less">
@import "@/assets/css/main.less";

</style>