<template>
  <div style="width: 100%;height: 100%">
    <video :id="id" class="video-js vjs-default-skin" autoplay muted="muted" controls v-if="src.includes('mp4')">
      <source :src="src" :type="src.includes('mp4')?'video/mp4':''"  />
    </video>
    <video style="width: 100%;height: 100%" :id="id" class="video-js vjs-default-skin" autoplay muted="muted" controls :src="src" v-else>
    </video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'videojs-contrib-hls'
export default {
  name: "video_",
  props:{
    src:{
      type:String
    },
    id:{
      type:String
    },
    time:{
      type:Number
    }
  },
  data(){
    return {
      videoPalyer:null,
    }
  },
  unmounted(){
    console.log(this.videoPalyer)
    if(this.videoPalyer){
      this.videoPalyer.dispose();
      this.videoPalyer = null;
    }
  },
  mounted(){
    console.log(this.src,this.id)
    if(!this.src.includes("mp4")){
      this.initVideo()
    }
  },
  methods:{
    initVideo(){
      this.videoPalyer = videojs(this.id,{
        controls:true,
        autoplay: 'muted',
        muted: true,
        sources:[
            {src:this.src}
        ]
      },function(){
        console.log("play")
        this.play();
      });
      /* this.videoPalyer.src(this.src);
       this.videoPalyer.on("error",()=>{
         console.log(this.videoPalyer.error())
       })*/
    },
    optionsFun(url){
      return {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        controlBar: true,
        autoplay: 'muted',
        muted: true,
      }
    },

  }
}
</script>

<style scoped>
.video-js{
  width: 100%;
  height: 100%;
}

</style>