<template>

  <div class="screenPage">
    <div class="screenCont">
      <div class="screenCenter" id="screenCenter" style="position: relative;height: 100%">
        <main-header :title="'智慧供热监控大屏'"></main-header>
        <div class="header_time" style="right: 3rem">
          <i class="iconfont icon-rili"></i>
          <span>{{time}}</span>
        </div>
        <div class="home_btns">
          <div class="def_btn_2" ><setting-outlined /> 系统管理</div>
          <div class="def_btn_2">返回</div>
        </div>

        <div class="left_btns">
          <div class="def_btn_1" ><left-outlined /><right-outlined />收起两侧</div>
          <div class="def_btn_1">
            <a-dropdown class="dark_">
              <div class="def_btn_1">
                <down-outlined />全部
              </div>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a href="javascript:;">全部</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div class="def_btn_1"><swap-outlined />3D模式</div>
        </div>
        <div class="viewer_full_cont" id="viewer-container" style="width: 100%;height: 100%"></div>
        <div :class='{"contCard":true}' v-for="(item,index) in layout" :key="index" :style='{"width":(item.w)+"px","height":(item.h)+"px","left":(item.align=="left"||item.align=="bottom"||item.align==""?(item.x+"px"):"unset"),"right":(item.align=="right"?((item.x2)+"px"):"unset"),"top":(item.align!="bottom"?(item.y+"px"):"unset"),"bottom":(item.align == "bottom"?(item.y2+"px"):"unset")}'>
          <div class="contCard_tit"
               :style='{"color":item.color,"font-size":item.size+"px","font-style":item.style,"font-family":item.family}'>
            {{ item.tit }}
          </div>
          <!-- 组态-->
          <div class="contCard_cont" v-if="item.type=='组态'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
            <iframe :src="ip2+item.itemData.monitor_src" style="width: 100%;height: 100%" frameborder="no"
                    scrolling="no"></iframe>
          </div>
          <!--  设备 卡片 -->
          <div class="contCard_cont" v-if="item.type=='天气'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
            <div class="tianqiCard">
              <div>2<small>℃</small></div>
              <div>
                <i class="iconfont icon-shidu"></i><span>湿度</span><span>2%</span>
              </div>
            </div>
          </div>
          <!--  视频         -->
          <div class="contCard_cont" v-if="item.type=='视频'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
            <div class="video_card video_card1" v-if="item.itemData.video_type==1">
              <div class="video_c" style="width: 100%;height: 100%;">1</div>
            </div>
            <div class="video_card video_card2" v-if="item.itemData.video_type==2">
              <div class="video_c">1</div>
              <div class="video_c">2</div>
            </div>
            <div class="video_card video_card3" v-if="item.itemData.video_type==3">
              <div class="video_c">1</div>
              <div class="video_c2">
                <div class="video_c">2</div>
                <div class="video_c">3</div>
              </div>
            </div>
            <div class="video_card video_card4" v-if="item.itemData.video_type==4">
              <div class="video_c">1</div>
              <div class="video_c">2</div>
              <div class="video_c">3</div>
              <div class="video_c">4</div>
            </div>
            <div class="video_card video_card5" v-if="item.itemData.video_type==5">
              <div class="video_c2">
                <div class="video_c">1</div>
                <div class="video_c4">
                  <div class="video_c">2</div>
                  <div class="video_c">3</div>
                </div>
              </div>
              <div class="video_c3">
                <div class="video_c">4</div>
                <div class="video_c">5</div>
              </div>
            </div>
            <div class="video_card video_card6" v-if="item.itemData.video_type==6">
              <div class="video_c2">
                <div class="video_c">1</div>
                <div class="video_c4">
                  <div class="video_c">2</div>
                  <div class="video_c">3</div>
                </div>
              </div>
              <div class="video_c3">
                <div class="video_c">4</div>
                <div class="video_c">5</div>
                <div class="video_c">5</div>
              </div>
            </div>
          </div>
          <!--  报警   -->
          <div class="contCard_cont" v-if="item.type=='报警'" :style='{"background-image":"url(./images/card_bg.png)","background-position": "center","background-size": "100% 100%","background-repeat": "no-repeat"}'>
            <div class="alarm_card" >
              <div class="alarm_table_head">
                <div>采集值</div>
                <div>发生时间</div>
              </div>
              <div class="alarm_table_body">
                <div class="alarm_table_tr">
                  <div>****</div>
                  <div>**</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {ref, unref, getCurrentInstance} from 'vue'
import $ from "jquery"
import SkyBoxOnGround from "@/until/SkyBoxOnGround";

var Cesium = require("cesium/Cesium");
var viewer = null;
var handler = null;
let mouseXY = {"x": null, "y": null};
let DragPos = {"x": null, "y": null, "w": 1, "h": 1, "i": null};
var imgEntity = null;
var area_pos = [];
export default {
  name: "preview",
  data() {
    return {
      layout: [],
      layout_temp:[],
      now_index: 0,
      editData: {
        type: "组态",
        x: "",
        x2: "",
        y: "",
        y2: "",
        w: "",
        h: "",
        tit: "",
        align:"left",
        family: "微软雅黑",
        color: "#11EEE1",
        size: "12",
        style: "normal",
        bgcolor: "rgba(31,48,53,0.8)",
        bg_img:true,
        opacity: 100,
        itemData: {
          monitor_id: "",
          monitor_src: "",
          dev_type: "card",
          dev_dev:{},
          video_type:1,
          videoList:[{src:""},{src:""},{src:""},{src:""},{src:""},{src:""}],
          article_src:"",
          article_type:"0",
          article_id:"",
          entity_id:"",
          entity_cols:[],
          entity_cols_d:[],
          tjList:[],
          alarm_id:""
        }
      },
      col_num: 1920,
      col_y:1080,
      myData: [],
      type: "",
      ip2: "",
      ip: "",
      id: "",
      monitorList: [],//监控点列表
      loading: false,
      cols:[],
      tjList:[],
      hideLeft:false,
      hideRight:false,
      alarmType:[],//报警类型列表
      screen_data:{},
      time:""

    }
  },
  beforeUnmount(){
    if(viewer){
      this.$clearWebgl(viewer);
    }
  },
  mounted() {
    this.time = this.$getTime(new Date()).t3;
    setTimeout(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.ip = this.$axios.defaults.baseURL;
    this.ip2 = this.$axios_ip.defaults.baseURL;
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.col_num = $("#screenCenter").width();
    this.col_y = $("#screenCenter").height();
    this.id = this.$route.query.key;
    document.addEventListener("dragover", function (e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);
    this.initMap();
    this.loadConfig();
    var _this = this;
    window.onresize=function(){
      _this.col_num = $("#screenCenter").width();
      _this.col_y = $("#screenCenter").height();
    }

  },
  methods: {
    initMap(fun) {
      viewer = new Cesium.Viewer("viewer-container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        shouldAnimate: true,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });
      viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
        url: " http://webst01.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}",
        minimumLevel: 1,
        maximumLevel: 18
      }));
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      });
      this.$loadGaoDe(viewer);
    },
    /*
    * ===================== 大屏信息 =========================
    * */
    //基础配置
    loadConfig(){
      var url = "/heating/screen-sttting/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          if(JSON.stringify(res.data) == "[]"){
            this.screen_data = res.data.screen_data?JSON.parse(res.data.screen_data):{
              img_src:"",
              area_pos:[],
              baseColor:"rgba(0,0,0,1)",
              angle:"",
              layout:[]
            };
          }else{
            this.screen_data = res.data.screen_data?JSON.parse(res.data.screen_data):{
              img_src:"",
              area_pos:[],
              baseColor:"rgba(0,0,0,1)",
              angle:"",
              layout:[]
            };
          }
          this.layout = this.screen_data.layout?this.screen_data.layout:[];
          this.layout.forEach((item,index)=>{
            item.i = index
          })
          console.log(this.layout)
          if(this.now_index!==""){
            this.editData = this.layout[this.now_index];
          }
          area_pos = this.screen_data.area_pos
          this.drawImgEntity();
        }
      }).catch((res)=>{
        console.log(res)
      })
    },
    //图片地图
    drawImgEntity(){
      if(area_pos.length>2){
        //var center = this.$getCenter(area_pos);
        imgEntity = new Cesium.Primitive({
          geometryInstances:new Cesium.GeometryInstance({
            geometry:new Cesium.PolygonGeometry({
              polygonHierarchy:new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(area_pos.flat()))
            })
          }),
          appearance:new Cesium.MaterialAppearance({
            material:this.screen_data.img_src?new Cesium.Material.fromType("Image",{
              "image":this.screen_data.img_src
            }):new Cesium.Material.fromType("Color",{
              "color":Cesium.Color.fromCssColorString("rgba(0,255,0,0.6)")
            })
          })
        });
        viewer.scene.primitives.add(imgEntity)
        this.toCenter()
        this.hideGlobe();
      }
    },
    toCenter(){
      for(var key in this.screen_data.angle){
        this.screen_data.angle[key] = parseFloat(this.screen_data.angle[key])
      }
      viewer.camera.setView({
        destination:Cesium.Cartesian3.fromDegrees(this.screen_data.angle.lon,this.screen_data.angle.lat,this.screen_data.angle.h),
        orientation: {
          heading: this.screen_data.angle.head===""?0:this.screen_data.angle.head,
          pitch: this.screen_data.angle.pitch===""?0:this.screen_data.angle.pitch,
          roll: this.screen_data.angle.roll===""?0:this.screen_data.angle.roll
        }
      })
    },
    hideGlobe(){
      viewer.imageryLayers.removeAll();
      viewer.scene.globe.baseColor =Cesium.Color.fromCssColorString(this.screen_data.baseColor);
      viewer.scene.globe.showGroundAtmosphere = false;
      viewer.scene.globe.translucency.enabled = true;
      viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0);
      viewer.scene.globe.undergroundColor = undefined;
      viewer.scene.skyAtmosphere.show = true; //是否隐藏大气圈
    },

    /*
    * ============================== 报警================================
    * */
    //获取报警类型列表
    getAlarmType(){
      var url = "/api/user/alarms-options/cascade";
      this.$getZeiotApi(url,"get","",(res)=>{
        this.alarmType = res.data;
      })
    },
  }
}
</script>
<style>
.screenRight .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(41, 42, 46, 1) !important;
  color: #ffffff !important;
  border-color: rgba(34, 34, 35, 1) !important;
  height: 26px !important;
}

.screenRight .ant-select-arrow {
  color: #ffffff !important;
}

.screenRight .ant-select-single .ant-select-selector .ant-select-selection-item, .screenRight .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 24px !important;
}

.screenRight .ant-input,.screenRight .ant-input-number,.screenRight .ant-input-number-input {
  background-color: rgba(41, 42, 46, 1) !important;
  color: #ffffff !important;
  border-color: rgba(34, 34, 35, 1) !important;
  height: 26px !important;
}

.screenRight .ant-upload.ant-upload-select-picture-card, .screenRight .ant-upload-picture-card-wrapper {
  width: 32px;
  height: 32px;
}

.screenRight .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
}
.screenRight .ant-select-multiple .ant-select-selection-item{
  background-color: rgba(0,0,0,0.4);
  border-color: transparent;
}
.screenRight .ant-select-multiple .ant-select-selection-item-remove{
  color: #ffffff;
}
</style>

<style scoped lang="less">
@import "../../assets/css/screen.less";
.screenCont{
  height: 100%;
}

</style>