<template>
  <div class="page">
    <breadcrumb :crumbs="crumbs"></breadcrumb>
    <div class="pageCont">
      <div class="searchCont">
        <div class="searchItem">
          <span class="searchTit"></span>
          <a-input v-model:value="keyword" autofocus placeholder="输入关键字"/>
        </div>
        <div class="searchItem">
          <span class="searchTit">区域：</span>
          <a-select
              v-model:value="area"
              show-search
              placeholder="选择"
              style="width: 200px"
              :options="areaList"
              :field-names='{"label":"zone_name","value":"zone_id"}'
              :filter-option="filterArea"></a-select>
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="search">查询</a-button>
          <a-popconfirm title="是否确定批量解绑?" ok-text="是"
                        cancel-text="否" @confirm="batchJie">
            <a-button type="danger" class="searchBtn m_l_1" >批量解绑</a-button>
          </a-popconfirm>

        </div>
      </div>
      <a-table class="m_t_2" :columns="columns" :data-source="select_xq_list" :pagination="false"
               :row-selection="{  selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
               :scroll="{ y: scroll_y }">
        <template #bodyCell="{ column, record,index }">
          <template v-if="column.dataIndex === 'caozuo'">
            <a-button type="link" @click="bindFun(index)">绑定</a-button>
            <a-popconfirm title="是否确定解绑?" ok-text="是"
                          cancel-text="否" @confirm="unbindFun(record)">
              <a-button type="link" danger>解绑</a-button>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <!--    <a-pagination class="pagination pages_box" v-model:current="pageIndex" :pageSize="pageSize"  :total="xqListNum" show-less-items size="small" show-size-changer @change="onShowSizeChange" />-->
      <a-modal v-model:visible="edit_modal" :title="tantitle" :width="400" :maskClosable="false" :footer="null">
        <div class="modalForm">
          <a-form ref="formM" :model="editData" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
            <a-form-item label="型号" name="dp_id">
              <a-select
                  v-model:value="editData.dp_id"
                  show-search
                  placeholder="选择型号"
                  style="width: 200px"
                  :options="dpList"
                  :field-names='{"label":"dp_name","value":"dp_id"}'
                  :filter-option="filterOption"
                  @change="changeDP"></a-select>
            </a-form-item>
            <a-form-item label="换热站" name="cbd_device_id" :rules="[{ required: true, message: '请选择!' }]">
              <a-select
                  v-model:value="editData.cbd_device_id"
                  show-search
                  placeholder="选择换热站"
                  style="width: 200px"
                  :options="deviceList"
                  :field-names='{"label":"device_name","value":"device_id"}'
                  :filter-option="filterOption2"></a-select>
            </a-form-item>
          </a-form>
        </div>
        <div class="modelFooter">
          <div class="modal_btns">
            <a-button type="primary" class="modal_btn" size="middle" @click="save">保存</a-button>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "bindHeatStations",
  data() {
    return {
      crumbs: ["换热站绑定"],
      keyword: "",
      columns: [
        {title: '所属区域', dataIndex: 'community_zone_name', key: 'community_zone_name', align: "center"},
        {title: '小区名称', dataIndex: 'community_name', key: 'community_name', align: "center"},
        {title: '换热站编号', dataIndex: 'device_code', key: 'device_code', align: "center"},
        {title: '换热站名称', dataIndex: 'device_name', key: 'device_name', align: "center"},
        {title: '操作', dataIndex: 'caozuo', key: 'caozuo', align: "center"},
      ],
      myData: [],
      edit_modal: false,
      tantitle: "",
      editData: {
        dp_id: "",
        "cbd_device_id": "",
        "cbd_community_id": ""
      },
      dpList: [],
      deviceList: [],
      pageIndex: 1,
      pageSize: 10,
      xqList: [],
      xqListNum: 0,
      deviceObj: {},
      areaList: [],
      area: "",
      selectedRowKeys: [],
      successNum: 0,
      del_index: 0,
      scroll_y: 300,
      select_xq_list: [],
      dev_index: 0
    }
  },
  mounted() {
    this.scroll_y = document.querySelectorAll(".page")[0].offsetHeight - 180;
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.$axios1.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios1.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.getAreaList();
    this.getDPList();

  },
  methods: {
    //获取小区列表
    getXQList() {
      this.select_xq_list = [];
      var url = "/heating/community/info?zone_id=" + this.area;
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.xqList = res.data;
          this.xqListNum = res.data.length;
          this.xqList.forEach((item) => {
            item.key = item.community_id;
            this.areaList.some((val) => {
              if (item.community_zone_id == val.zone_id) {
                item.community_zone_name = val.zone_name;
                return true
              }
            })
          })
          this.getBindList();
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    onShowSizeChange(page, pageSize) {
      this.pageIndex = page;
      this.pageSize = pageSize;
      this.xqList = [];
      this.xqListNum = 0;
      this.getXQList();
    },
    search() {
      this.getXQList()

    },
    bindFun(index) {
      this.edit_modal = true;
      this.tantitle = this.xqList[index].community_name;
      this.editData.cbd_community_id = this.xqList[index].community_id;
      this.editData.dp_id = "";
      this.editData.cbd_device_id = "";
    },
    save() {
      this.$refs.formM.validateFields().then(() => {
        var url = "/heating/community/bind/device";
        this.$axios.post(url, this.editData).then((res) => {
          if (res.status == 200) {
            this.$showSuccess("绑定成功！");
            this.edit_modal = false;
            this.getXQList()
          }
        })
      }, (err) => {
        console.log(err)
      })
    },
    //获取物联网型号列表
    getModelList() {
      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.dpList.forEach((item) => {
            res.data.some((val) => {
              if (val.dp_id == item.device_model_id) {
                item = Object.assign(item, val);
                return true
              }
            })
          })
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //获取型号列表
    getDPList() {
      var url = "/heating/device-model/easy-info";
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.dpList = res.data;
          this.getModelList();
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //筛选型号
    filterOption(e, option) {
      return option.dp_name.includes(e)
    },
    //选择型号
    changeDP() {
      this.editData.cbd_device_id = "";
      this.deviceList = [];
      this.getDeviceList();
    },
    //获取设备列表
    getDeviceList() {
      var url = "/heating/zeiot/device/protocol/list?dp_id=" + this.editData.dp_id;
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.deviceList = res.data;
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    filterOption2(e, option) {
      return option.device_name.includes(e);
    },
    //获取换热站绑定列表
    getBindList() {
      var url = "/heating/community/bind/device/info";
      this.$axios.post(url, {
            "zone_id": "",
            "community_id": ""
          }
      ).then((res) => {
        if (res.status == 200) {
          this.dev_index = 0;
          this.xqList.forEach((item, index) => {
            res.data.some((val) => {
              if (item.community_id == val.community_id) {
                item = Object.assign(item, val);
                this.dev_index = this.dev_index + 1;
                this.getDeviceInfo(val.cbd_device_id, index)
                return true
              }
            })
          })
          var timer = setInterval(() => {
            if (this.dev_index == 0) {
              clearInterval(timer);
              if (this.keyword) {
                this.xqList.forEach((item) => {
                  if (item.community_name.includes(this.keyword) || (item.device_code&&item.device_code.includes(this.keyword) )|| (item.device_name&&item.device_name.includes(this.keyword))) {
                    this.select_xq_list.push(item);
                  }
                })
              } else {
                this.select_xq_list = this.xqList;
              }
            }
          }, 100)
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //获取设备信息
    getDeviceInfo(id, INDEX) {
      if (!this.deviceObj.hasOwnProperty(id)) {
        var url = '/api/user/devices/' + id;
        this.$getZeiotApi(url, "get", "", (res) => {
          this.deviceObj[id] = res.data;
          this.dev_index = this.dev_index - 1;
          if (res.status == 200) {
            this.xqList[INDEX] = Object.assign(this.xqList[INDEX], this.deviceObj[id]);
          }
        })
      } else {
        this.xqList[INDEX] = Object.assign(this.xqList[INDEX], this.deviceObj[id]);
        this.dev_index = this.dev_index - 1;
      }
    },
    //获取区域列表
    getAreaList() {
      var url = "/heating/zone/info";
      /*var url = "/heating/community/info/page";*/
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.areaList = res.data;
          this.areaList.unshift({
            zone_id: "",
            zone_name: "全部"
          })
        }
        this.getXQList();
      }).catch((res) => {
        console.log(res);
      })
    },
    filterArea(e, option) {
      return option.zone_name.includes(e);
    },
    //解绑
    unbindFun(record) {
      var url = "/heating/dump/community/bind/device?id=" + record.community_id;
      this.$axios.delete(url).then((res) => {
        if (res.status == 200) {
          this.$showSuccess("解绑成功！");
          this.getXQList();
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //解绑
    jieFun(community_id, fun) {
      var url = "/heating/dump/community/bind/device?id=" + community_id;
      this.$axios1.delete(url).then((res) => {
        this.del_index = this.del_index + 1;
        if (res.status == 200) {
          this.successNum = this.successNum + 1;
          if (fun) {
            fun()
          }
        }
      }).catch((res) => {
        console.log(res);
        this.del_index = this.del_index + 1;
        if (fun) {
          fun()
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //批量解绑
    batchJie() {
      this.successNum = 0;
      this.del_index = 0;
      if (this.selectedRowKeys.length <= 0) {
        return this.$showWarmTip("至少选择一项解绑！");
      }
      this.selectedRowKeys.forEach((item) => {
        this.jieFun(item, () => {
          if (this.del_index == this.selectedRowKeys.length) {
            this.$showSuccess("共" + this.selectedRowKeys.length + "条，解绑" + this.successNum + "条！");
            this.getXQList();
          }
        });
      })
    }
  }
}
</script>

<style scoped>

</style>