<template>
<div class="main_page">
  <mainHeader :title="title"></mainHeader>
  <div class="header_time">
    <i class="iconfont icon-rili"></i>
    <span>{{time}}</span>
  </div>
  <div class="main_card_box">
    <div class="main_card" v-for="(item,index) in menuList" :key="index" @click="toPage(item)">
      <template v-if="item.menu_name=='供热监控'">
        <img src="../assets/images/main/gongre.png" style="width:1.6rem">
        <span>供热监控</span>
      </template>
      <template v-if="item.menu_name=='供热管理'">
        <img src="../assets/images/main/guanli.png" style="width:1.5rem">
        <span>供热管理</span>
      </template>
      <template v-if="item.menu_name=='供热服务'">
        <img src="../assets/images/main/fuwu.png" style="width:1.2rem">
        <span>供热服务</span>
      </template>
      <template v-if="item.menu_name=='综合监管'">
        <img src="../assets/images/main/zonghe.png" style="width:1.46rem">
        <span>综合监管</span>
      </template>
    </div>

  </div>

</div>
</template>

<script>
export default {
  name: "main_1",
  data(){
    return{
      time:"",
      title:"智慧供热综合服务平台",
      menuList:[]
    }
  },
  mounted(){
    this.time = this.$getTime(new Date()).t3;
    setTimeout(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.getGN();
  },
  methods:{
    //获取功能
    getGN(){
      var url = "/maps/menu";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.menuList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    toPage(obj){
      if(obj.menu_name == "供热监控"){
       /* this.$router.push({
          name:"heatingMonitoring"
        })*/
        this.judgeFun(obj,()=>{
          this.$router.push({
            name:"heatingMonitoring",
            query:{
              key:obj.menu_id
            }
          })
        })
      }
      if(obj.menu_name == "供热服务"){
        this.judgeFun(obj,()=>{
          window.open("heatingServices.html#/?key="+obj.menu_id,"_blank")
          /*window.open("http://localhost:8080/heatingServices.html#/?key="+obj.menu_id,"_blank")*/
        })
      }
    },
    judgeFun(obj,fun){
      var url = "/heating/menu/validate?id="+obj.menu_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          if(res.data){
            if(fun){
              fun()
            }
          }else{
            this.$showError("尚未开通此功能的权限，请联系管理员！")
          }
        }
      }).catch((res)=>{
        console.log(res);
      })
    }

  }
}
</script>

<style scoped>
@import "@/assets/css/iconfont.less";

</style>