<template>
  <div class="home_btns">
    <div class="def_btn_2" @click="toHome"><setting-outlined /> 系统管理</div>
    <div class="def_btn_2" v-if="show_back" @click="backFun">返回</div>
  </div>
</template>

<script>
export default {
  name: "homeBtn",
  props:{
    show_back:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {

    }
  },
  mounted(){

  },
  methods:{
    toHome(){
      var url = this.$router.resolve({
        name:"home",
        query:{
          key:this.$route.query.key
        }
      })
      window.open(url.href,"_blank")
    },
    backFun(){
      this.$router.back();

    }
  }
}
</script>

<style scoped>

</style>