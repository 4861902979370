<template>
  <div class="page">
    <breadcrumb :crumbs="crumbs"></breadcrumb>
    <div class="pageCont">
      <div class="searchCont">
        <div class="searchItem">
          <span class="searchTit"></span>
          <a-input v-model:value="keyword" autofocus placeholder="输入关键字" />
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="search">查询</a-button>
        </div>
      </div>
      <a-table class="m_t_2" :columns="columns" :data-source="myData" :pagination="false" :scroll="{ y: scroll_y }" >
        <template #bodyCell="{ column, record,index }">
          <template v-if="column.dataIndex === 'icon'&&record.device_model_data">
            <a-image :width="50" :src="ip+record.device_model_data"
            />
          </template>
          <template v-if="column.dataIndex === 'device_model_enable'">
            {{record.device_model_enable?"是":"否"}}
<!--            <a-checkbox v-model:checked="record.device_model_enable"  @change="chooseEnable(index,$event)"></a-checkbox>-->
          </template>
          <template v-if="column.dataIndex === 'device_model_type'">
            <a-button type="link" @click="showPartition(index)">设置</a-button>
          </template>
          <template v-if="column.dataIndex === 'device_model_display_name'">
            {{record.device_model_display_name?"是":"否"}}
<!--            <a-checkbox v-model:checked="record.device_model_display_name"  @change="chooseEnableName(index,$event)"></a-checkbox>-->
          </template>
          <template v-if="column.dataIndex === 'dtbz'">
            <a-button type="link" @click="showPoint(index)">设置</a-button>
          </template>
          <template v-if="column.dataIndex === 'caozuo'">
            <a-button type="link" @click="showEdit(index)">编辑</a-button>
          </template>
        </template>
      </a-table>
      <a-pagination v-model:current="pageIndex" :total="myDataNum" class="pages_box" v-model:pageSize="pageSize"  show-size-changer @change="onShowSizeChange" size="small" />
    </div>

    <a-modal v-model:visible="editModal" :title="editData.dp_name" :width="540" :maskClosable="false" :footer="null">
      <div class="modalForm">
        <a-form ref="formM" :label-col="{ span: 6 }"  :wrapper-col="{ span: 18 }" autocomplete="off">
          <a-form-item label="是否启用" name="device_model_enable" >
            <a-checkbox v-model:checked="editData.device_model_enable"></a-checkbox>
          </a-form-item>
          <a-form-item label="是否显示名称" name="device_model_display_name" >
            <a-checkbox v-model:checked="editData.device_model_display_name"></a-checkbox>
          </a-form-item>
          <a-form-item label="图标" name="device_model_data" >
            <a-upload name="avatar" list-type="picture-card" class="avatar-uploader"  :show-upload-list="false" :customRequest="uploadIcon" >
              <img class="echo_image" v-if="editData.device_model_data" :src="ip+editData.device_model_data" alt="avatar" style="width: 100%"/>
              <div v-else>
                <plus-outlined></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="跳转至" name="device_model_type">
            <a-select ref="select" v-model:value="editData.device_model_type" >
              <a-select-option value="1">组态</a-select-option>
              <a-select-option value="2">智慧供热大屏</a-select-option>
            </a-select>
          </a-form-item>
<!--          <a-form-item label="供暖面积"  >
            <a-input type="text" v-model:value="device_model_context.nrmj" placeholder=""></a-input>
          </a-form-item>
          <a-form-item label="采暖方式" >
            <a-input type="text" v-model:value="device_model_context.cnfs" placeholder=""></a-input>
          </a-form-item>-->
        </a-form>
      </div>
      <div class="modelFooter">
        <div class="modal_btns">
          <a-button type="primary" class="modal_btn" size="middle" @click="save">保存</a-button>
        </div>
      </div>
    </a-modal>

      <!-- 分区   -->
    <a-modal v-model:visible="partitionModal" :title="editData.dp_name" :width="540" :maskClosable="false" :footer="null">
      <div class="modalForm" style="min-height: 200px">
        <a-button type="primary" class="modal_btn" size="middle" @click="addPartition">添加</a-button>
        <table class="report_table">
          <thead>
          <tr>
            <th>序号</th>
            <th>名称</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in partitionList" :key="index">
            <td>{{item.device_model_partition_sort}}</td>
            <td>{{item.device_model_partition_name}}</td>
            <td>
              <form-outlined style="color: green" @click="edit(index)" />

<!--              <a-popconfirm title="是否确定删除此项?" ok-text="是"
                            cancel-text="否" @confirm="del(index)">
                <delete-outlined style="color: red" class="m_l_1" />
              </a-popconfirm>-->
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-modal>
    <a-modal v-model:visible="partition" :title="partition_tit" :width="340" :maskClosable="false" :footer="null">
      <div class="modalForm">
        <a-form ref="partitionForm" :model="partitionData"  :label-col="{ span: 6 }"  :wrapper-col="{ span: 18 }" autocomplete="off">
          <a-form-item label="序号" name="device_model_partition_sort" >
            <a-input type="text" v-model:value="partitionData.device_model_partition_sort" placeholder=""></a-input>
          </a-form-item>
          <a-form-item label="名称" name="device_model_partition_name" >
            <a-input type="text" v-model:value="partitionData.device_model_partition_name" placeholder=""></a-input>
          </a-form-item>
        </a-form>
      </div>
      <div class="modelFooter">
        <div class="modal_btns">
          <a-button type="primary" class="modal_btn" size="middle" @click="savePartition">保存</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "ioTDevices",
  data(){
    return{
      crumbs:["物联网设备"],
      keyword:'',
      columns:[
          { title: '设备型号', dataIndex: 'dp_name', key: 'dp_name',align:"center" },
        {  title: '启用', dataIndex: 'device_model_enable', key: 'device_model_enable',align:"center" },
        { title: '显示设备名称', dataIndex: 'device_model_display_name', key: 'device_model_display_name',align:"center" },
        { title: '图标', dataIndex: 'icon', key: 'icon',align:"center" },
        { title: '分区', dataIndex: 'device_model_type', key: 'device_model_type',align:"center" },
        { title: '备注', dataIndex: 'dp_memo', key: 'dp_memo',align:"center" },
        { title: '地图标注', dataIndex: 'dtbz', key: 'dtbz',align:"center" },
        { title: '操作', dataIndex: 'caozuo', key: 'caozuo',align:"center" },
      ],
      myData:[],
      myDataNum:0,
      pageIndex:1,
      pageSize:10,
      editModal:false,
      tantitle:"名称",
      editData:{
        "device_model_id": "",
        "device_model_enable": false,
        "device_model_display_name": false,
        "device_model_data": "",
        "device_model_context": "",
        "device_model_type": ""
      },
      modelList:[],
      loading:false,
      ip:"",
      max_height:600,
      device_model_context:{
        nrmj:"",
        cnfs:"",
      },
      partitionModal:false,
      partition:false,
      partitionData:{
        "device_model_partition_name": "",
        "device_model_partition_sort": 0,
        "device_model_id": ""
      },
      partition_tit:"添加",
      partitionList:[],
      scroll_y:300
    }
  },
  mounted(){
    this.scroll_y = document.querySelectorAll(".page")[0].offsetHeight- 220;
    this.max_height = $(".pageCont").height() - 150;
    this.ip = this.$axios.defaults.baseURL;
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.getData();

  },
  methods:{
    save(){
      var url = "/heating/device/model";
      var data = JSON.parse(JSON.stringify(this.editData));
      data.device_model_enable = data.device_model_enable?1:0;
      data.device_model_display_name = data.device_model_display_name?1:0;
      data.device_model_context = JSON.stringify(this.device_model_context);
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("保存成功！");
          this.getData();
          this.editModal = false;
        }
      }).catch((res)=>{

      })
    },
    showEdit(index){
      this.editModal = true;
      this.editData = Object.assign({},this.myData[index])
      this.device_model_context = this.editData.device_model_context?JSON.parse(this.editData.device_model_context):{
        nrmj:"",
        cnfs:"",
      }
    },
    //获取设备型号列表
    getData(){
      var url = "/heating/zeiot/device-protocols-options/page?pageNo="+this.pageIndex+'&pageSize='+this.pageSize+'&keyword='+this.keyword;
      this.$axios.get(url).then((res)=>{
        this.myData = res.data.data;
        this.myDataNum = res.data.totalItems;
        this.getModelList();
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取物联网设备列表 新信息
    getModelList(){
      var url = "/heating/device-model/easy-info";
      this.$axios.get(url).then((res)=>{
        this.modelList = res.data;
        this.myData.forEach((item,index)=>{
          item = Object.assign(item,{
            "device_model_id": item.dp_id,
            "device_model_enable": false,
            "device_model_display_name": false,
            "device_model_data": "",
            "device_model_context": "",
            "device_model_type": ""
          })
          this.modelList.some((val,key)=>{
            if(val.device_model_id == item.dp_id){
              item = Object.assign(item,val)
              item.device_model_enable = item.device_model_enable?true:false;
              item.device_model_display_name = item.device_model_display_name?true:false;
              return true
            }
          })
        })
      }).catch((res)=>{
        console.log(res);
      })
    },
    //查询
    search(){
      this.pageIndex = 1;
      this.myData = [];
      this.getData();
    },
    //分页
    onShowSizeChange(page,pageSize) {
      this.pageIndex = page;
      this.pageSize = pageSize;
      this.myData = [];
      this.myDataNum = 0;
      this.getData();
    },
    //是否启用
    chooseEnable(index,e){
      this.editData = Object.assign({},this.myData[index]);
      if(e.target.checked){
        this.editData.device_model_enable = true;
      }else{
        this.editData.device_model_enable = false;
      }
      this.save();
    },
    //是否显示名称
    chooseEnableName(index,e){
      this.editData = Object.assign({},this.myData[index]);
      if(e.target.checked){
        this.editData.device_model_display_name = true;
      }else{
        this.editData.device_model_display_name = false;
      }
      this.save();
    },
    //去到标点页
    showPoint(index){
      var url = this.$router.resolve({
        path:"/ioTDevicesPoint",
        query:{
          key:this.$route.query.key,
          id:this.myData[index].dp_id,
          name:this.myData[index].dp_name,
        }
      });
      window.open(url.href,"_blank")
    },
    //上传图标
    uploadIcon(e){
      this.$uploadPng(e.file,(src)=>{
        this.editData.device_model_data= src;
      })
    },
    /*
    * ====================  分区 ===================
    * */
    //获取分区列表
    getPartitionList(){
      var url = "/heating/device/part?device_model_id="+this.editData.device_model_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.partitionList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //显示分区
    showPartition(index){
      this.editData = this.myData[index];
      this.partitionModal = true;
      this.partitionData.device_model_id = this.editData.device_model_id;
      this.getPartitionList();
    },
    addPartition(){
      this.partition = true;
      this.partition_tit = "添加";
    },
    edit(index){
      this.partition = true;
      this.partition_tit = "修改";
      this.partitionData = Object.assign({},this.partitionList[index]);
    },
    del(index){
      var url = "/heating/device/part/rev?id="+this.partitionList[index].device_model_partition_id;
      this.$axios.delete(url).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("删除成功！");
          this.getPartitionList();
        }
      }).catch((res)=> {
        console.log(res);
      })
    },
    //保存分区
    savePartition(){
      this.$refs.partitionForm.validateFields().then(() => {
        var url = "";
        if(this.partition_tit=="添加"){
          url = "/heating/device/part";
          var data = JSON.parse(JSON.stringify(this.partitionData))
          this.$axios.post(url,data).then((res)=>{
            if(res.status == 200){
              this.$showSuccess("保存成功！");
              this.getPartitionList();
              this.partition = false;
            }
          }).catch((res)=>{
            console.log(res)
          })
        }else{
          url = "/heating/device/part/renew";
          var data = JSON.parse(JSON.stringify(this.partitionData))
          this.$axios.put(url,data).then((res)=>{
            if(res.status == 200){
              this.$showSuccess("保存成功！");
              this.getPartitionList();
              this.partition = false;
            }
          }).catch((res)=>{
            console.log(res)
          })
        }
      }, (err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.avatar-uploader{
  width: 80px;
  height: 80px;
}
:deep(.ant-upload.ant-upload-select-picture-card){
  width: 100%;
  height: 100%;
}

</style>