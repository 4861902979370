<template>
  <div class="colorBox">
    <color-picker
        :pureColor="color"
        :disableHistory="false"
        format="rgb"
        :zIndex="1"
        @pureColorChange="handle_pureColorChange"
    />
  </div>
</template>

<script>
export default {
  name: "colorpicker",
  props:{
    color:{
      type:String
    },
    index:{
      type:Number
    }
  },
  data(){
    return {
      pickerColor:''
    }
  },
  mounted(){
    //this.pickerColor = this.color;
  },
  methods:{
    handle_pureColorChange(e,a){
      this.pickerColor = e;
      this.$emit("getPickerColor",this.pickerColor,this.index);
    },
  }
}
</script>
<style>
.vc-color-wrap{
  width: 20px !important;
  height: 20px !important;
}
</style>

<style scoped>
.colorBox{
  width: 100px;
  height: auto;
}
.colorBtnBox{
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>